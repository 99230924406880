import { EResult } from "../common"
/**
 * proxy api code to call backend api 
 * @param callback
 */
export function getAllUserInfo(proxy, callback)
{
    proxy.postFetch("/timsapi/getalluserinfo", {id:"all"})
    .then(async (response)=>{
        if(response.ok)
        {
            const body = await response.text()
            let resData = JSON.parse(body)
            callback(resData)
        }
        else{
            callback({result:EResult.RESULT_ERROR, reason:"api fetch failed - getalluserinfo", data:null});
        }
    });
}
