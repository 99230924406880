import { EResult } from "../common"

/**
 * proxy api code to call backend api
 * @param param[string] folder id
 * @param callback
 */
export function getUserPermissions(proxy, param, callback)
{
    const paramToGetPermission = {uid:param}
    proxy.postFetch("/timsapi/getuserpermissions", paramToGetPermission)
    .then(async (response)=>{
        if(response.ok)
        {
            const body = await response.text()
            let resData = JSON.parse(body)
            callback(resData)
        }
        else{
            callback({result:EResult.RESULT_ERROR, reason:"api fetch failed - getpermission", data:null});
        }
    });
}
