import React, { useState, useEffect, SyntheticEvent} from 'react';
import './App.css';
import {Proxy} from './api/proxy'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, Theme } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';


const useStyles = makeStyles({
    root: {

    },
    container : {
        height:'90vh',
        overflowX: 'auto'
    },
    permitLabelContainer : {
        marginLeft : '12em'
    },
    permitCheckLabel : {
       marginLeft : '1em' 
    },
    currentUserGroupLabel : {
        fontStyle : 'italic',
        fontWeight: 'bold',
    },
    groupLabel : {
        width : "150px"
    }
  });

export default function PermissionGroupTree(props)
{
    const classes = useStyles()
    const { groupINFO,
            userINFO,
            readPermitGroups,
            writePermitGroups,
            readDesc,
            writeDesc,
            handleNodeClick,
            currentUser,
            handleCheckClick,
        } = props
    const [ rootNode, setRootNode ] = useState()
    const [ allGroupIds, setAllGroupIds ] = useState([])
    useEffect(()=>{
        if(groupINFO){
            const root = groupINFO.find(g=>g.parent === null);
            if(root)
                setRootNode(root)
            const allGroup = groupINFO.map(g=>g.id)
            if(allGroup.length > 0)
                setAllGroupIds(allGroup)
        }
    },[groupINFO])

    const allGroup = groupINFO.map(g=>g.id)

    return (
        <div className={classes.container}>
            <div className={classes.permitLabelContainer}>
                <label className={classes.permitCheckLabel} key="readLabel">
                    read
                </label>
                <label className={classes.permitCheckLabel} key="writeLabel">
                    write
                </label>
            </div>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect
                defaultExpanded={allGroup}
            >
                <PermissionGroupTreeItem
                    currentNode={rootNode}
                    groupINFO={groupINFO}
                    userINFO={userINFO}
                    readPermitGroups={readPermitGroups}
                    writePermitGroups={writePermitGroups}
                    readDesc={readDesc}
                    writeDesc={writeDesc}
                    handleCheckClick={handleCheckClick}
                    currentUsersGroup={currentUser?.gid}
                />
            </TreeView>
        </div>
      );
}
  
function PermissionGroupTreeItem(props)
{
    const classes = useStyles();
    const {
        currentNode,
        groupINFO,
        userINFO,
        readPermitGroups,
        writePermitGroups,
        readDesc,
        writeDesc,
        currentUsersGroup,
        handleCheckClick,
    } = props

    const [children, setChildren] = useState([])  // group children
    
    useEffect(()=>{
        if(currentNode && groupINFO && userINFO) {
            const chGroups = []
            for(const group of groupINFO){
                if(group.parent === currentNode.id)
                    chGroups.push(group)
            }
            setChildren(chGroups)
        }
    }, [currentNode, groupINFO, userINFO])


    const handleIconClick = (evt, id)=>{
        //evt.preventDefault()
    }

    const isCurrentUserGroup = currentUsersGroup ? currentNode.id === currentUsersGroup : false;
    const hasReadPermission = readPermitGroups?.length ? readPermitGroups.includes(currentNode.id) : false ;
    const hasWritePermission = writePermitGroups?.length ? writePermitGroups.includes(currentNode.id)  : false;
    return (
        (currentNode)?        
        <TreeItem 
            nodeId={currentNode.id} 
            label={
                <FormControlLabel
                    control={
                        <React.Fragment>
                            {/* WRITE CHECKBOX */}
                            <Checkbox 
                                size="small"
                                checked={ hasWritePermission|| isCurrentUserGroup}
                                disabled={isCurrentUserGroup}
                                color="primary"
                                onChange={() => handleCheckClick(currentNode, "write")}
                                onClick={e => e.stopPropagation()}
                            />
                            {/* READ CHECKBOX */}
                            <Checkbox 
                                size="small"
                                checked={hasReadPermission || isCurrentUserGroup}
                                disabled={isCurrentUserGroup}
                                onChange={() => handleCheckClick(currentNode, "read")}
                                onClick={e => e.stopPropagation()}
                            />
                        </React.Fragment>
                    }
                    labelPlacement={'start'}
                    label={
                        <Typography
                            variant = "body2"
                            color={( hasReadPermission || hasWritePermission  ? "error" : "initial")}
                            className = {`${classes.groupLabel} ${isCurrentUserGroup ? classes.currentUserGroupLabel : ""}`}
                        >
                            {(currentNode.title)?`${currentNode.title}`:`(${currentNode.name})`}
                        </Typography>
                    }
                />
                
            }
            onIconClick={(evt)=>handleIconClick(evt, currentNode.id)}
        >
            {
                children.map(c=>(
                    <PermissionGroupTreeItem
                        key={c.id}
                        currentNode={c}
                        groupINFO={groupINFO}
                        userINFO={userINFO}
                        readPermitGroups={readPermitGroups}
                        writePermitGroups={writePermitGroups}
                        readDesc={readDesc}
                        writeDesc={writeDesc}
                        handleCheckClick={handleCheckClick}
                        currentUsersGroup={currentUsersGroup}
                    />
                ))
            }
        </TreeItem>
        :null
    )
}

