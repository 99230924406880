import { EResult } from "../common"

/**
 * proxy api code to call backend api 
 * @param param[IUserData] folder id
 * @param callback
 */
export function modifyUserInfo(proxy, param, callback)
{
    proxy.postFetch("/timsapi/modifyuserinfo", param)
    .then(async (response)=>{
        if(response.ok)
        {
            const body = await response.text()
            let resData = JSON.parse(body)        
            callback(resData)
        }
        else{
            callback({result:EResult.RESULT_ERROR, reason:"api fetch failed - modifyuserinfo", data:null});
        }
    });
}

