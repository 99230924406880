import React, { useEffect, useState, createRef, useRef } from 'react';
import { ListItemText, makeStyles, withStyles } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import TablePagination from '@material-ui/core/TablePagination';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { MenuList, MenuItem } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import MenuIcon from '@material-ui/icons/Menu';
import GridOnIcon from '@material-ui/icons/GridOn';
import GroupIcon from '@material-ui/icons/Group';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Popper from '@material-ui/core/Popper';
import Switch from '@material-ui/core/Switch';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Divider from '@material-ui/core/Divider';

import GroupTreeView from "./GroupTree2"
import clsx from 'clsx';

import {  BrowserRouter as Router, Route,
          useRouteMatch, Link, useHistory, useLocation}  from 'react-router-dom'

const useStyles = makeStyles((theme)=>({    
    appBar: {
        height:"inherit",
        //backgroundColor: "grey",
        color:"primary",
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        position:'sticky'
    },
    appBarHidable:{
        position:'absolute'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display:"none"
    },
    groupButton: {
        color:"white",
        margin: 3,
        maxWidth: 350,
        width:350
    }, 
    componentInAppBar:{
    },
    supervisorMode:{
        backgroundColor: "gray"
    },
    docSelect:{
        marginLeft:4,
        marginRight:16,
        maxWidth:300,
        minWidth:200,
        "&:hover":{
            cursor: "pointer"
        }
    }
}))

const ViewModeSwitch = withStyles({
    switchBase: {
        color: red[300],
        '&$checked': {
            color: red[500],
        },
        '&$checked + $track': {
            backgroundColor: red[500],
        },
    },
    checked: {},
    track: {},
})(Switch);

const makeTimeString = (time)=>{    
    const date = new Date(time)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const dateStr = `${date.getFullYear()}/${monthNames[date.getMonth()]}/${date.getDate()}`
    return dateStr
}

export default function AppBarDocumentView(props){
    const classes = useStyles()
    const {
        proxy,
        userINFO,
        groupINFO,
        currOBJECT,
        myUserINFO,
        currGROUP,
        permissionINFO,
        refreshINFO,
        position,
        drawerOpen,
        title,
        groupTreeSelectUser,    // user selected
        groupTreeSelectGroup,   // group selected
        currUserOBJECT,
        gotoFolderButton, //deprecated
        showObjTitle,
        currView,
        usersInFolder, 
        searchFILTER,
        handleSignOut,
        supervisorMode,
        usersInAllFolderINFO,
        appbarInTrigger,
        setCurrObjectByID,
        currAUTHOR,
        currPAGE,
        setCurrPAGE,
        currROWPERPAGE,
        userObjectCounts,
        userObjInCurrFolderINFO,
        onAppBarMenu
    } = props

    const groupTreeData = groupINFO
    const usersInfo = userINFO
    const author = userINFO.find(u=>u.id === currOBJECT?.author)


    const anchorUserMenuRef = useRef(null)
    const anchorUserSelectMenuRef = useRef(null)
    const anchorDocSelectMenuRef = useRef(null)

    const MENU_NONE =0
    const MENU_USER =1
    const MENU_SELECTUSER=2
    const MENU_SELECTDOC=3

    const [activatedMenu, setActivatedMenu] = useState(MENU_NONE)

    //const [userMenuOpen, setUserMenuOpen] = useState(false)
    //const [_userSelectMenuOpen, setUserSelectMenuOpen] = useState(false)
    //const [_docSelectMenuOpen, setDocSelectMenuOpen] = useState(false)

    const [objectDate, setObjectDate] = useState("")
    const [defaultExpandGroup, setDefaultExpandGroup] = useState(null )

    
    const handleCloseMenu = ()=>{
        handleMenuState(MENU_NONE)
    }

    const handleCloseUserMenu = ()=>{
        handleMenuState(MENU_NONE)
        //setUserMenuOpen(false);
    }
    const handleCloseUserSelectMenu = ()=>{
        handleMenuState(MENU_NONE)
        //setUserSelectMenuOpen(false)
    }
    const handleCloseDocSelectMenu = ()=> {
        handleMenuState(MENU_NONE)
        //setDocSelectMenuOpen(false)
    }

    const handleMenuState = (menu)=>{
        let _menu = menu
        if(activatedMenu === menu || menu === MENU_NONE)
            //setActivatedMenu(MENU_NONE)
            _menu = MENU_NONE
        else
            //setActivatedMenu(menu)
            _menu = menu

        setActivatedMenu(_menu)
        onAppBarMenu(_menu === MENU_NONE? false : true)
    }

    useEffect(()=>{
        if(!appbarInTrigger)
            handleCloseMenu()
    },[appbarInTrigger])
    
    useEffect(()=>{
        if(currOBJECT){
            /*
            const date = new Date(currOBJECT.time)
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            const dateStr = `${date.getFullYear()}/${monthNames[date.getMonth()]}/${date.getDate()}`
            */
            setObjectDate(makeTimeString(currOBJECT.time))
        }
    }, [currOBJECT])

    useEffect(()=>{
        if(currAUTHOR && userObjInCurrFolderINFO && currOBJECT) {
            //const userObjs = userObjInCurrFolderINFO.find(objs=> objs.uid === currAUTHOR.id)
            //const maxObjects = userObjectCounts.find(uoc=>uoc.uid === currAUTHOR.id)?.count
            //const currPosition = currPAGE * currROWPERPAGE + userObjs.findIndex(uo=>uo.id === currOBJECT.id)
            
        }
    }, [currAUTHOR, currOBJECT, userObjInCurrFolderINFO])

    useEffect(()=>{

    }, [userObjInCurrFolderINFO])

    useEffect(() => {
        setDefaultExpandGroup(getCurrentGroupPath())
    },[props.currGROUP])

    const getCurrentGroupPath = () => {
        if(!currGROUP?.id) {
            return null;
        }
        
        let path = [currGROUP.id];
        let parent = currGROUP.parent;
        while(parent) {
            let item = groupINFO.find(g => g.id === parent)
            path.push(item.id);
            parent = item.parent;
        }
        return path;
    }

    const onMenuClick = (target)=>{
        handleCloseMenu()
        if(target)
            target.current.click()
    }
    
    const handleSearchFilterChange = (searchFilter)=>{
    }
    const handleClickSearchRefresh = ()=>{
    }

    const onTreeItemClick = (uid, gid)=>{
        if(gid && uid){
            if(gid){
                groupTreeSelectGroup(gid, uid ? uid : null, uid? true : false)
                handleCloseMenu()
            }

        }
    }
    const onUserSelectMenuClick = (user)=>{
        handleCloseMenu()
        groupTreeSelectUser(user)
    }
    const onUserObjectSelect = (object)=>{
        const oid = object.id

        setCurrObjectByID(oid)
        handleCloseMenu()
    }


    const handleNodeToggle = (groupIds) => {
        setDefaultExpandGroup(groupIds)
    }
    return (        
        <AppBar color="primary" 
            className={ clsx(classes.appBar, 
                {[classes.appBarShift]: drawerOpen,[classes.appBarHidable]: (position==="absolute"), [classes.supervisorMode]: (supervisorMode)})} >
            <Toolbar color="primary" className={ clsx(classes.appBar)} >
                <IconButton
                    color="inherit"
                    aria-label="goto folder"
                    component={Link} to="/folder"
                    edge="start"
                    className={clsx(classes.menuButton)}
                >
                    <GridOnIcon />
                </IconButton>

                <Tooltip title={<Typography variant="h6">{title + ` > ${author?.name}`}</Typography>}placement="bottom">  
                    <Button 
                        ref={anchorUserSelectMenuRef}
                        variant="outlined"
                        color="inherit"
                        onClick={()=>handleMenuState(MENU_SELECTUSER)} //handleUserSelectMenuToggle}
                        style={{border:1, maxWidth:500, backgroundColor:"gray"}}
                    >
                        <GroupIcon  color="inherit"/> 
                        <Typography noWrap={true} color="inherit" variant="body1">{title + ` > ${author?.name}`}</Typography>
                    </Button>
                </Tooltip>

                <Popper
                    open={activatedMenu === MENU_SELECTUSER} //userSelectMenuOpen}
                    anchorEl={anchorUserSelectMenuRef.current}      
                    anchororigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}                                
                    transformorigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    role={undefined}
                    transition
                    disablePortal
                >
                {
                ({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom' }}
                    >
                        <Paper style={{zIndex:100, backgroundColor:"#eeeeee", width:anchorUserSelectMenuRef.current?.clientWidth, minWidth:300}}>
                            <GroupTreeView
                                proxy={proxy}
                                userNodeEnable={true}
                                collapseDefault={false}
                                groupINFO={groupINFO}
                                userINFO={ userINFO }
                                maxDepth={5}
                                handleNodeClick={onTreeItemClick}
                                selected={currGROUP}
                                isLimitPermit={true}
                                permitGROUP={permissionINFO}
                                refreshINFO={refreshINFO}
                                usersInAllFolderINFO={usersInAllFolderINFO}
                                defaultExpandGroup={defaultExpandGroup}
                                handleNodeToggle={handleNodeToggle}
                            />
                        </Paper>
                    </Grow>
                )}
                </Popper>
                {
                    <Typography
                        ref={anchorDocSelectMenuRef}
                        className={classes.docSelect}
                        onClick={()=>handleMenuState(MENU_SELECTDOC)} //handleDocSelectMenuToggle}
                        variant="subtitle1" 
                        noWrap={true}
                        style={{marginLeft:4, marginRight:16, maxWidth:300, minWidth:200}}>
                        {`> ${currOBJECT?.title}(${objectDate})`}
                    </Typography>
                }                
                <Popper
                    open={activatedMenu === MENU_SELECTDOC}// docSelectMenuOpen}
                    anchorEl={anchorDocSelectMenuRef.current}      
                    anchororigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}                                
                    transformorigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    role={undefined}
                    transition
                    disablePortal
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom' }}
                    >
                        <Paper style={{zIndex:100, backgroundColor:"#eeeeee", maxWidth:500, minWidth:300}}>
                            {
                                <MenuList autoFocusItem={activatedMenu === MENU_SELECTDOC} 
                                        style={{maxHeight:500, maxWidth:500,  width:400, overflow:"auto"}} id="menu-list-grow">
                                {
                                    (currUserOBJECT).map((o)=>(
                                        <MenuItem key={`${o.id}`} onClick={()=>{onUserObjectSelect(o)}}>
                                            {o.title + `(${makeTimeString(o.time)})`}
                                        </MenuItem>
                                    ))
                                }
                                </MenuList>   
                            }
                        </Paper>
                    </Grow>
                )}
                </Popper>

                {!searchFILTER ? null :
                    <FormControl>
                        <Input
                            style={{ color: "white", marginLeft: 25 }}
                            onChange={(e) => handleSearchFilterChange(e.target.value)}
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            value={searchFILTER}
                            disabled={true}
                            readOnly={true}
                            endAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                        disabled={true}
                                        aria-label="toggle search enable"
                                        onClick={(e) => handleClickSearchRefresh()}
                                        onMouseDown={(e) => e.preventDefault()}
                                    >
                                        <AutorenewIcon color={"disabled"} />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                }
                
                <Button
                    ref={anchorUserMenuRef}
                    style={{position:"absolute", right:50, backgroundColor:"gray", color:"white"}}
                    aria-controls={(activatedMenu === MENU_USER) ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={()=>handleMenuState(MENU_USER)}
                    endIcon={<AccountCircleIcon></AccountCircleIcon>}
                >
                    {myUserINFO?.name}
                </Button>
                <Popper
                    open={activatedMenu === MENU_USER} //userMenuOpen}
                    anchorEl={anchorUserMenuRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper style={{zIndex:100}}>
                                
                                    <MenuList autoFocusItem={activatedMenu === MENU_USER} id="menu-list-grow">
                                        <MenuItem component={Link} to= "/folder">
                                            Documents Home
                                        </MenuItem>
                                        <MenuItem component={Link} to= "/myprofile">
                                            My Profile
                                        </MenuItem>
                                        <MenuItem component={Link} to= "/mydocuments">
                                            <ListItemText primary="My Documents" secondary="(문서업로드)" />
                                        </MenuItem>
                                        <MenuItem onClick={()=>handleSignOut()}>Logout</MenuItem>
                                        {
                                            ( myUserINFO?.user_level === "admin") ?
                                            (
                                                <div>
                                                    <Divider />
                                                    <MenuItem component={Link} to= "/groupadmin">
                                                        Admin Group
                                                    </MenuItem>
                                                    <MenuItem component={Link} to= "/userinfoadmin">
                                                        Admin User
                                                    </MenuItem>
                                                    {/*<MenuItem onClick={()=>onMenuClick(gotoPermissionAdminRef)}>Admin Permission</MenuItem>*/}
                                                    {/*<MenuItem onClick={()=>onMenuClick(gotoDocumentsAdminRef)}>Admin Documents</MenuItem>*/}
                                                </div>
                                            )
                                            :null
                                        }                                        
                                    </MenuList>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Toolbar>
        </AppBar>
    )
}



/*
<Popover
open={userSelectMenuOpen}
anchorEl={anchorUserSelectMenuRef.current}
role={undefined}
transition
disablePortal
>                
{({ TransitionProps, placement }) => (
    <Grow
    {...TransitionProps}
        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
    >
        <MenuList autoFocusItem={true} id="menu-list-grow">
            
            <MenuItem onClick={()=>onMenuClick(gotoMyProfileRef)}>My Profile</MenuItem>
            <MenuItem onClick={()=>onMenuClick(gotoMyDocumentsRef)}>My Documents</MenuItem>                                
            <MenuItem onClick={()=>onMenuClick(gotoFolderRef)}>Folder View</MenuItem>
            <MenuItem onClick={()=>handleSignOut()}>Logout</MenuItem>
    
        {
            (usersInFolder).map(u=>(
                <MenuItem onClick={()=>onUserSelectMenuClick(u)}>
                    {u.name}
                </MenuItem>
            ))
            
        }
        </MenuList>
    </Grow>)
}
</Popover>
*/