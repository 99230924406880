import React, { useEffect, useState, SyntheticEvent } from 'react';
import './App.css';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme, Theme } from '@material-ui/core/styles';

import {
        Button,
        Popover,
        Tooltip,
        FormControlLabel,
        Checkbox, TablePagination, 
        TextField,
        lighten,
        TableSortLabel,
        TableContainer,
        Typography,
    } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import GroupTreeView from './GroupTree2';
import { GroupTreeView as GroupTreeViewOld } from './GroupTree';
import { getUserInfo } from './api/proxyGetUserInfo';
import { modifyUserInfo } from "./api/proxyModifyUserInfo"
import { addNewUser } from "./api/proxyAddNewUser"


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop:80,
    },
    ERROR : {
        color: 'red'
    },
    OK : {
        color: 'green'
    }
}))


export function MyProfile(props){
    const classes = useStyles()

    const {
        userINFO,
        groupINFO,
        myUserINFO,
        refreshINFO,
        proxy,
    } = props

    const [userLoginId, setUserLoginId] = useState('')
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userLevel, setUserLevel] = useState("normal");
    const [myGroup, setMyGroup] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [passwordConfirm, setPasswordConfirm] = useState(undefined)
    const [passFieldError, setPassFieldError] = useState(undefined);
    const [ saveResult, setSaveResult] = useState(undefined);
    useEffect(()=>{
        if(myUserINFO && groupINFO) {
            setUserLoginId(myUserINFO.login_id)
            setUserName(myUserINFO.name)
            setUserEmail(myUserINFO.email)
            setUserLevel(myUserINFO.userLevel)
            setPassword("")
            setPasswordConfirm("")

            const gid = myUserINFO.gid
            const group = groupINFO.find(g => g.id === gid)
            if(group)
                setMyGroup(group)
        }
    },[myUserINFO, groupINFO])

    const handleCancelChange =()=>{
        
        if(myUserINFO && groupINFO) {
            setUserLoginId(myUserINFO.login_id)
            setUserName(myUserINFO.name)
            setUserEmail(myUserINFO.email)
            setUserLevel(myUserINFO.userLevel)
            setPassword("")
            setPasswordConfirm("")
        }
    }
    const handleSave = ()=>{
        myUserINFO.name = userName
        myUserINFO.password = password
        myUserINFO.email = userEmail
        modifyUserInfo(proxy, myUserINFO, (result)=>{
            if(result.result === "WARN") {
                   setPassFieldError(result.data.npw);
            } else {
                setPassFieldError(null);
                setSaveResult(result.result)
                refreshINFO(["user"])
            }
        })
    }
    const handleChangePassword = (pwd)=>setPassword(pwd)
    const handleChangePasswordConfirm = (pwd)=>setPasswordConfirm(pwd)
    const handleChangeLevel = (level)=>setUserLevel(level)
    const handleChangeName = (name)=>setUserName(name)
    const handleChangeEmail = (email)=> setUserEmail(email)
    const handleChangeId = (id)=>setUserLoginId(id);
    const handleRemoveReadOnly = e => e.target.removeAttribute('readonly')

    const messages = {
        required : '필수 입력 정보입니다.',
        length: '패스워드는 5글자 이상 입력하십시오.',
        //unmatched: '입력한 패스워드와 일치하지 않습니다.',
        unchanged: '현재 사용 중인 패스워드와 다른 패스워드를 입력하십시오.',
        includeId: '패스워드에는 사용자 아이디가 포함될 수 없습니다.',
        OK: '사용자 정보를 수정하였습니다.',
        ERROR: '사용자 정보를 수정하는데 실패했습니다.'
    }
    return (
        <div className={classes.root}>
            <Grid container >
                <Grid item xs ={2}></Grid>
                <Grid item xs ={8} style={{marginBottom:10}}>
                    <TextField
                        disabled
                        id="id"
                        label="Login Id"
                        placeholder="ID"
                        type="text"
                        fullWidth
                        variant="standard"
                        value = {userLoginId}
                        onChange={(e)=>handleChangeId(e.target.value)}
                    />
                </Grid>
                <Grid item xs ={2} />
                
                <Grid item xs ={2} />
                <Grid item xs={4}>
                    <input key="prevent-autocomplete-pw" type="password" style={{display:'none'}} />
                    <TextField
                        style={{padding:10}}
                        id="password"
                        placeholder="password"
                        fullWidth
                        variant="standard"
                        inputProps={{
                            readOnly: true
                        }}
                        onFocus={handleRemoveReadOnly}
                        label={ <Typography color="primary"
                                variant="subtitle1">Password</Typography> }

                        value = {(password)? password : ""}
                        type="password"
                        onChange={(e)=>handleChangePassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="passwordconfirm"                        
                        style={{padding:10}}
                        label={ <Typography color={(password != passwordConfirm) ? "error":"primary"}
                                variant="subtitle1">Password Confirm</Typography> }

                        color={(password === passwordConfirm)? "primary": "secondary"}
                        placeholder="password confirm"
                        fullWidth
                        variant="standard"
                        type="password"
                        inputProps={{
                            readOnly: true
                        }}
                        onFocus={handleRemoveReadOnly}
                        value = {passwordConfirm? passwordConfirm: ""}
                        onChange={(e)=>handleChangePasswordConfirm(e.target.value)}
                    />
                </Grid>                
                <Grid item xs ={2} />
                {/* password-field error message */}
                {
                    passFieldError ?
                        <React.Fragment>
                            <Grid item xs={2} />
                            <Grid item xs={8} >
                                {passFieldError.map(error => <p className={classes.ERROR}>{messages[error]}</p>)}
                            </Grid>
                            <Grid item xs={2} />
                        </React.Fragment>
                        : null
                }

                <Grid item xs ={2} />
                <Grid item xs={8}>
                    <TextField
                        id="name"
                        name="name"
                        label="User Name"
                        placeholder="User Name"
                        margin="normal"
                        fullWidth
                        variant="standard"
                        value = {userName}
                        onChange={(e)=>handleChangeName(e.target.value)}
                    />
                    <TextField
                        id="level"
                        name="level"
                        disabled
                        label="User Level"
                        placeholder="User Name"
                        margin="normal"
                        fullWidth
                        variant="standard"
                        value = {userLevel}
                        onChange={(e)=>handleChangeName(e.target.value)}
                    />
                </Grid>
                <Grid item xs ={2} />
                <Grid item xs ={2} />
                <Grid item xs={8}>
                    <TextField
                        id="group"
                        disabled
                        name="group"
                        label="Group (click to change)"
                        placeholder="Group (click to change)"
                        margin="normal"
                        fullWidth
                        variant="standard"
                        type="email"
                        value = {myGroup?.name+"("+myGroup?.title+")"}
                        //onClick= {handleGroupTreeOpen}
                        //onChange={(e)=>handleChangeEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs ={2} />
                <Grid item xs ={2} />
                <Grid item xs={8}>
                    <TextField
                        id="email"
                        name="email"
                        label="User Email"
                        placeholder="User Email"
                        margin="normal"
                        fullWidth
                        variant="standard"
                        type="email"
                        value = {userEmail}
                        onChange={(e)=>handleChangeEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs ={2} />
                

                <Grid item xs ={2} />
                <Grid item xs ={4} >
                    <Button style={{padding:10}}variant="contained" fullWidth onClick={handleCancelChange} color="primary">
                        Cancel Change
                    </Button>
                </Grid>
                <Grid item xs ={4} >
                    <Button style={{padding:10}} variant="contained" fullWidth onClick={handleSave} color="primary" 
                        disabled={ 
                            ((password === '' || password === undefined)
                             && (userName === myUserINFO?.name)
                             && (userEmail === myUserINFO?.email)
                             )|| (password !== passwordConfirm)
                        }>
                        Save
                    </Button>
                </Grid>
                <Grid item xs ={2} />
                {
                    saveResult ?
                        <React.Fragment>
                            <Grid item xs={2} />
                            <Grid item xs={8} >
                                <h2 className={classes[saveResult]}>
                                    {saveResult === 'OK' ? <span>✔</span> : <span>❗</span>}
                                    {messages[saveResult]}
                                </h2>
                            </Grid>
                            <Grid item xs={2} />
                        </React.Fragment>
                        : null
                }
            </Grid>
        </div>
    )
}

