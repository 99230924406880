
export const API_FETCHSERVER = "https://tims.tescom.org:5000"
export const EResult = {
    RESULT_OK:"OK",
    RESULT_FAILED:"FAILED",
    RESULT_ERROR:"ERROR"
}

export const ESessionMessage = {
    SESSMSG_UPDATEDATA:0,
    SESSMSG_GENERIC:1,
    SESSMSG_USERUPDATE:2,
    SESSMSG_FILEUPLOAD:3,
    SESSMSG_FILEINPROGRESS:4,
    SESSMSG_FILEUPLOADCOMPLETE:5,
    SESSMSG_MEDIAHANDLING:6,
    SESSMSG_OBJUPDATE:7,
    SESSMSG_GROUPUPDATE:8,
    SESSMSG_OBJREGISTER:9,
    SESSMSG_FOLDERUPDATE:10,
    SESSMSG_SESSTIMEOUT_ALERT:11,
    SESSMSG_SESSTIMEOUT:12,
    SESSMSG_ERROR:13,
    SESSMSG_MESSAGE:14,
    SESSMSG_FILEUPLOADERROR:15,
}
