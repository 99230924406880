exports.changePassword = (proxy, param) => {
    return new Promise ((resolve, reject) => {
        proxy.postFetch("/timsapi/changePassword", param)
        .then(response => response.text())
        .then(body => {
            const result = JSON.parse(body);
            resolve(result);
        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })
}