import { EResult } from "../common"

export function getLatestDocSharedGroups(proxy, param)
{
    try {
        return proxy.postFetch("/timsapi/getlatestdocsharedgroups", {uid:param}).then(res => {
            return res.text()
        }).then(result => {
            const res = JSON.parse(result)
            if(res.result === "OK" && res.data){
                  return res.data
            }
            return [];
        }).catch(e => {
            console.error(e)
            return e;
        })
    } catch(e) {
        console.error(e)
    }
}