import React, { useEffect, useState, useRef } from 'react';
import {  BrowserRouter as Router, Route,
          useRouteMatch, Link, useHistory, useLocation}  from 'react-router-dom'

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { useTheme} from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import ShareIcon from '@material-ui/icons/Share';
import ArchiveIcon from '@material-ui/icons/Archive';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import AddIcon from '@material-ui/icons/Add';
import { red } from '@material-ui/core/colors';
import GroupTreeView from './GroupTree2'
import { addNewObjectLink } from "./api/proxyAddNewObjectLink"
import { deleteObject as proxyDeleteObject } from './api/proxyDeleteObject';
import { deleteObjectLink } from './api/proxyDeleteObjectLink'
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import { uploadFile } from './api/proxyFileUpload';
import { getDocSharedGroups } from './api/proxyGetDocSharedGroups'
import { getLatestDocSharedGroups } from './api/proxyGetLatestDocSharedGroups';
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Fab from '@material-ui/core/Fab';
import {  Typography,
    IconButton,
    Card,
    CardContent,
    CardActions,
    Button,
    CardActionArea,
    Popover,
    Tooltip,
    Switch,
    // fade, 
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Grid,
    DialogActions} from '@material-ui/core';

import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    root:{
        display:"flex",
        flexWrap:"wrap",
    },
    content: {
        //flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    docCardContainer:{
        display:"flex",
        backgroundColor: "gray",
        margin:0,
        padding:0,
        //width:`calc(100vw - ${250}px)`,
        //height:`calc(100vh - ${180}px)`,
        overflow:"auto",
        //scrollbarWidth: "none"
    },
    docCardHeightWithMedia:{
        minHeight:120,
        height:120
    },  
    docCardHeightWithoutMedia:{
        minHeight:120,
        height:120
    },
    docCard:{
        width:250,
        minWidth:250,
        margin:2,
        //height:200
    },
    cardEmpty:{
        height:"100%"
    },
    cardDocActionArea:{
        backgroundColor:"#fff8e1"
    },
    inProcessing:{
        backgroundColor:"#33eb91"
    },
    cardDocContent:{
        padding:8,
    },
}))

const ViewModeSwitch = withStyles({
    switchBase: {
      color: red[300],
      '&$checked': {
        color: red[500],
      },
      '&$checked + $track': {
        backgroundColor: red[500],
      },
    },
    checked: {},
    track: {},
})(Switch);

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }))(Tooltip);


export function MyDocuments(props){
    const {
        proxy,
        userObjInCurrFolderINFO,
        groupINFO,
        userINFO,
        myUserINFO,
        maxUserObjectCount,
        writePermissionGROUP,
        refreshINFO,
        onDocumentSelect,
        deleteDoc,
        myDocInProgress,
        currFOLDER,
        setInitMyDocuments
    } = props
    
    const classes = useStyles()
    const [openAddNewDocumentDlg, setOpenAddNewDocumentDlg]= useState(false)
    const [viewListMode, setViewListMode] = useState(true)
    const [myObjects, setMyObjects] = useState([])
    const [currObject, setCurrObject] = useState()
    const [documentViewOpen, setDocumentOpen] = useState(false)
    const [openGroupSelectDlg, setOpenGroupSelectDlg]=useState(false)
    const [docSharedGroups, setDocSharedGroups]=useState([])
    const [defaultUploadTitle, setDefaultUploadTitle] = useState("")

    const [docToShare, setDocToShare] = useState()
    const [groupToShare, setGroupToShare] = useState("")
    useEffect(()=>{
        console.log("entering...")
    },[userObjInCurrFolderINFO]);

    useEffect(() => {
        if(myUserINFO) {
            setInitMyDocuments()
        }
    }, [myUserINFO])

    useEffect(()=>{
        if(myObjects && myObjects.length > 0){
            const defaultTitleToUploadNew = myObjects[0].title
            setDefaultUploadTitle(defaultTitleToUploadNew)
        } else {
            setDefaultUploadTitle("")
        }
    },[myObjects]);

    useEffect(()=>{
        if(!myUserINFO || !currFOLDER || !userObjInCurrFolderINFO) {
            return;
        }
        
        if(userObjInCurrFolderINFO.length && currFOLDER.id === myUserINFO.fid){
            setMyObjects(userObjInCurrFolderINFO[0].objects)
        }
        else {
            setMyObjects([])
        }
        console.log("updating...")
    },[userObjInCurrFolderINFO])

    //deleteDocument={deleteDocument}
    // processing document card click event
    const onDocumentClick = (obj)=>{
        setCurrObject(obj)
        onDocumentSelect(obj, false)
        //setDocumentOpen(true)
    }
    const handleEmptyCardClick = ()=>{
        if(myObjects && myObjects.length > 0 ){
            updateDocShareGroup(myObjects[0].id)
            .then((result)=>{
                const defaultShareGroup = [];
              //DON'T SET AS docSharedGroups if user has no permissions 
              //FIXME: Permission-Related works has to be dealt preferably in server-side 
                result.forEach(group => {
                    if(writePermissionGROUP.includes(group.id)) {
                        defaultShareGroup.push(group);
                    }
                })
                setDocSharedGroups(result);
                setOpenAddNewDocumentDlg(true)
            })
        }
        else
        {
            setOpenAddNewDocumentDlg(true) 
        }
    }
    const handleCloseDialog = ()=>{
        setOpenAddNewDocumentDlg(false)
    }
    const updateDocShareGroup = (id)=>{
        return new Promise((resolve, reject)=>{
            getDocSharedGroups(proxy, id, (res)=>{
                if(res.result === "OK"){
                    resolve(res.data)
                } else {
                    reject(`Error: failed to fetch getDocSharedGroups ${id}`)
                }
            })
        })
    }

    const handleOpenDocShareDialog = (doc)=>{
        updateDocShareGroup(doc.id)
        .then((result)=>{
            // how to deal with doc shared group ids
            setDocSharedGroups(result)
            setDocToShare(doc)
            setOpenGroupSelectDlg(true)    
        })
    }
    const showDocument = (doc)=>{
    }
    const deleteDocument = (doc)=>{   
        proxyDeleteObject(proxy, {id:doc.id}, (result)=>{
            if(result.result === "OK"){
                refreshINFO(["object"])
            }
        })
    }
    
    const handleGroupSelect = (uid, gid)=>{        
        console.log("group select", uid, gid)
        //setGroupToShare(gid)
        handleDocToGroupShare(docToShare?.id, gid)
    }

    // it would be dimissed
    // "OK" selected from document share dialog
    const handleOKGroupSelect =    ()=>setOpenGroupSelectDlg(false)
    const handleCloseGroupChoice = ()=>setOpenGroupSelectDlg(false)

    const handleDocToGroupShare = (oid, gid)=>{
        const fid = groupINFO.find(g=>g.id === gid)?.fid
        const alreadyShared = docSharedGroups?.find(d=> d.id === gid)? true : false
        const hasPermission = writePermissionGROUP?.find(p=> p === gid)? true : false
        if(fid && hasPermission){
            if(alreadyShared){
                deleteObjectLink(proxy, {oid, gid}, (res)=>{
                    if(res.result === "OK"){
                        refreshINFO(["object"])
                        updateDocShareGroup(oid)
                        .then((result)=>{
                            setDocSharedGroups(result)
                        })
                    }
                })
            }
            else{
                addNewObjectLink(proxy, {oid, uid:myUserINFO.id, fid}, (res)=>{
                    if(res.result ==="OK"){
                        refreshINFO(["object"])
                        updateDocShareGroup(oid)
                        .then((result)=>{
                            setDocSharedGroups(result)
                        })
                    }
                })
            }
        }
    }

    const onUploadNewDocument= (title, gid, fileToUpload)=>{
        console.log("onUploadNewDocument")
        //if(e)
        {
            const data = new FormData()
            data.append("file", fileToUpload)            
            data.append("field",
                JSON.stringify({
                    'author':myUserINFO.id,
                    'pfid':myUserINFO.fid,
                    'vfid':gid, //groupINFO.find(g=>g.id === gid)?.fid,
                    'title':title,
                })
            );
            uploadFile(proxy, {data, onProgress:(event, param)=>{
                console.log("onProgress:",event)
                //if(event === "loaded"){
                //    props.alertMessage("File uploaded & in the progress", "default")
                //}
            }}, (result)=>{
                console.log("success")
                //if(result)
                //    props.alertMessage("Document uploading complete", "success")
            })
        }
    }
    return(
        <div className={clsx(classes.root)}>           
            {
                (openAddNewDocumentDlg)?
                    <AddNewDocumentDialog
                        proxy={proxy}
                        open={openAddNewDocumentDlg}
                        userData={userINFO}
                        groupTreeData={groupINFO}
                        myUserINFO={myUserINFO}
                        onCloseUserDlg={handleCloseDialog}
                        onUploadNewDocument={onUploadNewDocument}
                        permitGroups={writePermissionGROUP}
                        refreshINFO={refreshINFO}
                        defaultUploadTitle={defaultUploadTitle}
                        docSharedGroupsLatest={docSharedGroups}
                        totalDocs={maxUserObjectCount}
                    />
                :null
            }
            {
                (openGroupSelectDlg)? // handle sharing document to groups
                    <Dialog
                        open={openGroupSelectDlg}
                        onClose={handleCloseGroupChoice}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{`Groups to share :(${docToShare?.title})`}</DialogTitle>
                        <DialogContent style={{width:350, height:300}}>
                            <GroupTreeView
                                proxy={proxy}
                                userNodeEnabled={false}
                                collapseDefault={false}
                                groupINFO={groupINFO}
                                isLimitPermit={true}
                                userINFO={ userINFO }
                                maxDepth={ 5 }
                                selectedGroup={groupINFO?.find(g=>g.id === myUserINFO.gid)}                                    
                                permitGROUP={writePermissionGROUP}
                                refreshINFO={refreshINFO}
                                handleNodeClick={handleGroupSelect}
                                docSharedGroups={docSharedGroups}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" fullwidth onClick={handleOKGroupSelect} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                :null
            }
            {
                // empty card slot to upload new document
                <Card key={"empty"} className={clsx(classes.docCard, classes.docCardHeightWithoutMedia, 
                    {[classes.docCardHeightWithMedia]:false})}>
                    <CardActionArea className={clsx(classes.cardDocActionArea, classes.cardEmpty)} onClick={(e)=>handleEmptyCardClick()}>
                        <CardContent style={{textAlign:"center"}}className={classes.cardDocContent}>
                            <AddIcon fontSize="large" />
                            <Typography variant="h6">New Document</Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            }
            {
                myDocInProgress.map((c, index)=>(
                     // empty card slot to upload new document
                    <Card key={"inprogress"+index} className={clsx(classes.docCard, classes.docCardHeightWithoutMedia, 
                        {[classes.docCardHeightWithMedia]:false})}>
                        <CardActionArea className={clsx(classes.inProcessing, classes.cardEmpty)} >
                            <CardContent style={{textAlign:"center"}}className={classes.cardDocContent}>
                                <CircularProgress color="inherit" />
                                <Typography variant="h6">{c} in Processing</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))
            }
            {
                myObjects.map((c) => (
                    <DocumentCard
                        key={c.id}
                        docInfo={c} 
                        handleCardClick={onDocumentClick}
                        handleDocShare={handleOpenDocShareDialog}
                        showDocument={showDocument}
                        deleteDocument={deleteDocument}
                        mediaEnabled={false}
                    />
                ))
            }
        </div>
    )
}

/*
type DocumentCardProps={
    docInfo:IObjectData
    mediaEnabled:boolean
    handleCardClick:(obj:IObjectData)=>void
}*/
function DocumentCard(props){
    const {
        docInfo,
        mediaEnabled,
        handleCardClick,
        deleteDocument,
        showDocument,
        handleDocShare
    } = props

    const [groupChoiceOpen, setGroupChoiceOpen] = useState(false)

    const doc = docInfo // document information to draw on the screen 
    const docTime = new Date(doc.time)
    const classes = useStyles()
    const handleShareDocButton = ()=>{
        handleDocShare(doc)
    }
    
    const handleShowDocButton = (evt, doc)=>{
        showDocument(doc)   
    }    
    const handleDeleteDocButton = (evt, doc)=>{
        deleteDocument(doc)
    }
    return(
        <div>            
            

            <Card key={doc.id} className={clsx(classes.docCard, classes.docCardHeightWithoutMedia, 
                {[classes.docCardHeightWithMedia]:mediaEnabled})}>
                <CardActionArea className={classes.cardDocActionArea} onClick={(e)=>handleCardClick(doc)}>
                    <CardContent className={classes.cardDocContent}>
                        <Typography variant="subtitle1" style={{padding:0}} noWrap={true} gutterBottom>
                            {(doc.title)? doc.title : "No Title"}
                        </Typography>
                        <Typography variant="subtitle1" style={{padding:0}} component="p">
                            {`${docTime.getFullYear()}/${docTime.getMonth()+1}/${docTime.getDate()}`}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardDocActionArea}>
                    {
                    (((Date.now()) - (new Date(doc.time)).getTime())/(1000 * 3600 * 24) < 7)?
                        <IconButton size="small" color="primary" onClick={(evt)=>handleDeleteDocButton(evt, doc)}>
                            <DeleteIcon fontSize="small"/>
                        </IconButton>
                    : null
                    }
                    <IconButton size="small" color="primary" onClick={(evt)=>handleShareDocButton()}>
                        <ShareIcon fontSize="small"/>
                    </IconButton>
                    {/*
                    <IconButton size="small" color="primary">
                        <ArchiveIcon  fontSize="small"/>
                    </IconButton>
                    */}
                    {
                    /*
                    <IconButton size="small" color="primary" onClick={(evt)=>handleShowDocButton(evt, doc)}>
                        <FullscreenIcon  fontSize="small"/>
                    </IconButton>
                    */
                    }
                </CardActions>
            </Card>
        </div>
    );
}

/*
    open={openAddNewDocumentDlg}
    groupTreeData={groupINFO}
    myGroup={myGroupINFO}
    myUserINFO={myUserINFO}
    onCloseUserDlg={handleCloseDialog}
    onUploadNewDocument={onUploadNewDocument}
    permitGroups={writePermitGroup}
*/
function AddNewDocumentDialog(props){
    const { proxy,
            open,
            userData,
            groupTreeData,
            myGroup,
            myUserINFO,
            onCloseUserDlg,
            onUploadNewDocument,
            permitGroups,
            refreshINFO,
            defaultUploadTitle,
            docSharedGroupsLatest,
            totalDocs
        } = props

    const [groupPopoverElement, setGroupPopoverElement] = useState(null)
    const [title, setTitle] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [groupName, setGroupName] = useState(null)
    const [currGroup, setCurrGroup] = useState(undefined)
    const [fileDataToUpload, setFileDataToUpload] = useState(null)
    const [docSharedGroups, setDocSharedGroups] = useState([])

    const classes = useStyles();
    useEffect(()=>{
        // set currGroup
        // setFileName("")
        // setGroupName("")
        // setTitle("")
    })

    useEffect(()=>{
        setTitle(defaultUploadTitle)
    },[defaultUploadTitle])

    useEffect(()=>{ 
        const uid = myUserINFO.id
        const gid = userData.find(u => u.id === uid)?.gid

        if(gid){
            const gdata = groupTreeData.find(g=>g.id === gid)
            setCurrGroup(gdata)
        }
    }, [])

    useEffect(()=>{
        //[OCT-16-2020] SETTING DEFAULT SHARING GROUP(S) 
        //if none of the documents is uploaded, set up the default sharing groups settings referring to user's permission 
        let groupsToShare = [];
        if(!totalDocs) {
             // [OCT-02-2020] my group and just parent group if you were permitted
            const mygr = myUserINFO.gid;
            if(mygr && mygr !== ""){
                // groupsToShare.push(mygr)
                const myGrInfo = groupTreeData.find(g=> g.id === mygr)                
                if(myGrInfo){
                    if(permitGroups.find(g=> g === myGrInfo.id))
                        groupsToShare.push({id:myGrInfo.id})
                    const parentGrInfo = groupTreeData.find(g=> g.id === myGrInfo.parent)
                    if(parentGrInfo && permitGroups.find(g=> g === parentGrInfo.id) && permitGroups.indexOf(parentGrInfo.id) > 0)
                        groupsToShare.push({id:parentGrInfo.id})
                }
            }  
            setDocSharedGroups(Array.from(groupsToShare))
        
        } else {
        //set it up with latest group sharing information
            getLatestDocSharedGroups(proxy, myUserINFO.id)
            .then(gid => {
                //set as docsharedgroups if user has a write permission
                gid.forEach(gidObj => {
                    if(permitGroups.indexOf(gidObj.id) >= 0) {
                        groupsToShare.push(gidObj);
                    } 
                })
                setDocSharedGroups(groupsToShare);
            }).catch(err => {
                console.log(err)
            })
        }

    },[permitGroups, docSharedGroupsLatest])
    
    useEffect(()=>{
       setCurrGroup(myGroup)
    },[myGroup, userData, groupTreeData])

    // save edited information and close dialog
    const handleVerifyUserId = (newid)=>
    {
        // verify userid
        userData.forEach(u => {
            if(u.login_id === newid){
                return false
            }
        })
        return true
    }
    const handleCloseGroupPopover = ()=>{
        setGroupPopoverElement(null)
    }
    const handleGroupTreeOpen = (event)=>{
        setGroupPopoverElement(!groupPopoverElement? event.currentTarget : null)
    }
    const handleGroupSelect = (uid, gid)=>{
        //TODO: Permission-Related works has to be dealt preferably in server-side 
        if(!permitGroups.includes(gid)){
            return;
        }

        if(docSharedGroups){
            const add = docSharedGroups.find(d=>d.id === gid)
            if(!add){
                docSharedGroups.push({id:gid})
                setDocSharedGroups(Array.from(docSharedGroups))
            } else {
                const index = docSharedGroups.findIndex((d)=> d.id === gid)
                if(index !== -1)
                    docSharedGroups.splice(index, 1)
                setDocSharedGroups(Array.from(docSharedGroups))
            }
        }
    }

    const handleCloseDialog = ()=>{
        onCloseUserDlg()
    }
    const handleUploadNewDocument = ()=>{
        //const data = new FormData();
        //data.append("file", fileNameToUpload)
        const fids = docSharedGroups.map(g=> groupTreeData.find(gt=>gt.id ===g.id)?.fid)

        if(fileDataToUpload)
            onUploadNewDocument(title, fids.toString(), fileDataToUpload)
            //onUploadNewDocument(title, currGroup? currGroup.id:null, fileDataToUpload)

        handleCloseDialog()

    }
    const handleChangeGroupName = (groupName)=>{
        // do nothing
    }
    const handleChangeTitle = (title)=>{
        setTitle(title)
    }
    const handleChangeFileName = (fileName)=>{
        setFileName(fileName)
    }
    const handleChangeFileToUpload = (e)=>{
        console.log("handleChangeFileToUpload", e.currentTarget.value)
        const name = e.currentTarget.value;
        setFileName(name.substr(name.lastIndexOf("\\")+1));
        const el = e.currentTarget.files
        if(el && el.length > 0)
            setFileDataToUpload(el[0]);
    }
    const fileSelectButtonRef = useRef()

    return(
        <div>
          <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Document Uploading</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs = {6}>
                        <Grid container>                        
                            <Grid item xs = {12}>
                                <TextField
                                    autoFocus
                                    id="documentTitle"
                                    label="Document Title"
                                    placeholder="Title"
                                    type="text"
                                    fullWidth
                                    variant="outlined"                    
                                    value = {title}
                                    onChange={(e)=>handleChangeTitle(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs = {12}>
                                <input
                                    accept=".ppt,.pptx"
                                    style={{display:'none'}}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleChangeFileToUpload}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button style={{display:'none'}} ref={fileSelectButtonRef} className={classes.uploadFileButton} variant="contained" color="primary" component="span">
                                        File
                                    </Button>
                                </label>
                                <TextField
                                    id="fileToUpload"
                                    name="file"
                                    label={ "" }
                                    disabled
                                    placeholder="Document"
                                    margin="normal"
                                    fullWidth
                                    variant="outlined"
                                    value = {fileName}
                                    //onClick={(e)=>{fileSelectButtonRef.current.click()}}
                                    onChange={(e)=>{}} //handleChangeFileName(e.target.value)}
                                />
                                <Fab 
                                    variant="extended"
                                    onClick={(e)=>{fileSelectButtonRef.current.click()}}
                                    size="small" color="primary" aria-label="add" >
                                    <CloudUploadIcon /> Select File
                                </Fab>
                            </Grid> 

                        </Grid>
                    </Grid>
                        <Grid item xs={6}>
                            <div  style={{marginLeft:10}}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography style={{textAlign:"left"}} variant="subTitle1">Groups to share</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <GroupTreeView
                                                proxy={proxy}
                                                userNodeEnabled={false}
                                                collapseDefault={false}
                                                groupINFO={groupTreeData}
                                                isLimitPermit={true}
                                                userINFO={userData }
                                                maxDepth={5}
                                                handleNodeClick={handleGroupSelect}
                                                selectedGroup={currGroup}                                    
                                                permitGROUP={permitGroups}
                                                refreshINFO={refreshINFO}
                                                docSharedGroups={docSharedGroups}
                                            />
                                        </Grid>
                                    </Grid>
                            </div>
                        </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleCloseDialog} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleUploadNewDocument} color="primary" 
                    disabled={(fileName === null)}>
                    Upload
                </Button>
            </DialogActions>
          </Dialog>
        </div>
    );
}

