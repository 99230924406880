import React, { useEffect, useState, useRef, Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import clsx from 'clsx';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import MovieIcon from "@material-ui/icons/Movie";
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import NextDateIcon from "@material-ui/icons/ExpandLess";
import PrevDateIcon from "@material-ui/icons/ExpandMore";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import GamesIcon from '@material-ui/icons/Games';
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import Modal from '@material-ui/core/Modal'

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import YouTube from 'react-youtube';

import {  BrowserRouter as Router, Route,
          useRouteMatch, Switch, Link, useHistory, useLocation}  from 'react-router-dom'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root:{
        margin:0,
        position:"relative",
        height:`inherit`,
    },
    title:{
        color:"white",
    },
    docimageContainer:{  
        position: "relative",
        backgroundColor: "grey",
        left:"0.5%",
        top:"0.5%",
        maxWidth:"99%",
        maxHeight:"99%",
        height:"100%",
        width:"100%",
        flexGrow:1,
        overflow:'hidden',
        //paddingTop:"50%"
        //transform:"translate(0, -50%)",        
    },
    docimage:{
        //position:"absolute",
        //axHeight: "98%",
        //width: "100%",
        //height: "100vh",
        maxWidth:"100%",
        maxHeight:"100%",
        //overflow:'hidden',
        display:'block',
    },
    naviButton:{        
        '&:hover': {
            opacity : 0.9
        },
        opacity:0.6
    },
    panelButton:{
        position: "absolute",
        bottom : 50,
        right : 50,
    },
    pictureButton:{
        position: "absolute",
        left:50,
        top :100,
    },
    mediaButton:{
        position: "absolute",
        left : 50,
        top : 160,
    },
    fullScreenButton:{
        position: "absolute",
        right : 50,
        top: 100
    },
    prevPageButton:{
        position: "absolute",
        left : 50,
        top: `calc(50vh)`
    },
    nextPageButton:{
        position: "absolute",
        right : 50,
        top: `calc(50vh)`
    },
    controlPanel:{
        position:"absolute",
        backgroundColor:"rgba(200,200,200,0.5)",
        opacity:0.5,
        width:150,
        height:250,
        right:75,
        bottom:75,
    },
    hide:{
        display:"none"
    },
    prevDateButton:{
        position:"absolute",
        left: "calc(50%)",
        transform: "translate(-50%, 50%)",
        bottom:"35%",
    },
    nextDateButton:{
        position:"absolute",
        left: "calc(50%)",
        transform: "translate(-50%, -50%)",
        top:"35%",
    },
    prevPersonButton:{
        position:"absolute",
        left: 10,        
        top:"50%",
        transform: "translate(0%, -50%)",
    },
    nextPersonButton:{
        position:"absolute",
        right: 10,
        top:"50%",
        transform: "translate(0%, -50%)",
    },
    latestDateDocButton:{
        position:"absolute",
        left: "50%",
        top:"13%",
        transform: "translate(-50%, -50%)",
    },
    earliestDateDocButton:{
        position:"absolute",
        left: "50%",        
        bottom:"13%",
        transform: "translate(-50%, 50%)",
    },
    currentPage:{
        position: "absolute",
        right : 50,
        top : 50,
    },
    currentObjectInfo:{
        position:"absolute",
        left: 50,
        top:50,
    },
    videoClass: {
        position: 'absolute',
        top: "10%",
        left:"10%",        
        width: "80%",
        height:"80%"
    },
})

export function DocumentView(props) {
    const classes = useStyles()
    const { proxy,
        userINFO,
        currOBJECT,
        currGROUP,
        currFOLDER,
        drawerOpen,
        moveToLatestDoc,
        moveToOldestDoc,
        moveToNextDateDoc,
        moveToPrevDateDoc,
        moveToNextPersonDoc,
        moveToPrevPersonDoc,
        usersInAllFolderINFO,
        groupINFO,
        getCurrObjectById,
        setInitDocumentViewState,
    } = props

    const [panelOpen, setPanelOpen] = useState(false)
    const [currPage, setCurrPage] = useState(0)
    const [pageURLs, setPageURLs] = useState([])
    const [pictureGalleryOpen, setPictureGalleryOpen] = useState(false)
    const [fullScreenOn, setFullScreenOn] = useState(true)
    const [mediaInfo, setMediaInfo] = useState([])
    const [imageInfo, setImageInfo] = useState([])
    const [objectInfoDisplay, setObjectInfoDisplay] = useState(false)
    const [timerInfoDisplay, setTimerInfoDisplay] = useState()
    const [mediaMenuAnchor, setMediaMenuAnchor] = useState(null)
    const [videoIdToPlay, setVideoIdToPlay] = useState("")
    const [openVideoDialog, setOpenVideoDialog] = useState(false)
    const [youTubeVideo, setYouTubeVideo] = useState(false)

    const refRoot = useRef()
    
    useEffect(() => {
        if (refRoot && refRoot.current) {
            refRoot.current.focus()
        }        
    }, [currPage])

    const slideURL=(doc, page)=> `${proxy.getBaseURL()}/timsapi/docimage?docid=${doc}&page=${page}`
    useEffect(()=>{
        const imagRefs = []
        const urls = []
        const di = currOBJECT   // document information 
        let link_info
        let pages = 1
        if(!currOBJECT)
            return 

        try{
            link_info = JSON.parse(di.link_info)
        }
        catch(e){            
            console.log(e)
            console.log("Error to get the number of document :", di?.id)
            link_info = undefined    
        }    
        
        pages = (link_info)? link_info.length : di.pages
        if(di){
            for(let i = 0 ; i < pages ; i++){
                urls.push(slideURL(di.id, i))
                //imagRefs.push(useRef(null))
            }
        }
        setPageURLs(urls)
        setTimeout(()=>updateDimension, 1000)
        if(refRoot)
            refRoot.current.focus()

        window.addEventListener("resize", updateDimension)
        return ()=>window.removeEventListener("resize", updateDimension)
    }, [])
    
    useEffect(()=>{
        setCurrPage(0)
        const urls = []
        
        const di = currOBJECT   // document information 
        if(di){
            for(let i = 0 ; i < di.pages ; i++){
                urls.push(slideURL(di.id, i))
            }
        }
        setPageURLs(urls)
        setPictureGalleryOpen(false)
        
        if(di && di.link_info)
        {
            try{
                //linkInfo : Array of Objects  => [ ... , { links:[ ..., {} ], sldNum: Number } ]
                const linkInfo = di.link_info.replace(/\//g, "\\")
                const mi = JSON.parse(di.link_info);
                const sldImageInfoObj = {};
                const sldMediaInfoObj = {};
                for(let index = 0 ; index < mi.length ; index++){
                    const m = mi[index]
                    // linkInfo's links : Array of Objects
                    // [ ..., { target: string(filepath), targetMode : "image" | "video" | "hyperlink" } ]
                    if(m.links.length > 0 )
                    {
                        const slideIndex = m.sldNum
                        const links = m.links
                        const imageLinks = []
                        const mediaLinks = []
                        links.forEach((l) => {
                            if(l.targetMode === "image")
                                imageLinks.push(l)
                            else 
                                mediaLinks.push(l)
                        })

                        if(imageLinks.length)
                            sldImageInfoObj[slideIndex - 1] = imageLinks;
                        if(mediaLinks.length)
                            sldMediaInfoObj[slideIndex - 1] = mediaLinks;
                     }
                }
                setImageInfo(sldImageInfoObj)
                setMediaInfo(sldMediaInfoObj)
            }
            catch(e){
                console.log(e)
            }
        }
        setObjectInfoDisplay(true)
        if(timerInfoDisplay != undefined)
            clearTimeout(timerInfoDisplay)

        const timer = setTimeout(()=>{setObjectInfoDisplay(false); setTimerInfoDisplay(undefined)}, 3000)
        setTimerInfoDisplay(timer)
        
        if (refRoot)
            refRoot.current.focus() 
                       
    }, [currOBJECT])

    useEffect(() => {
        if (!currOBJECT && userINFO && userINFO.length && groupINFO && groupINFO.length) {
            const search = new URLSearchParams(window.location.search);
            const gid = search.get('gid');

            const oid = search.get('docid');
            getCurrObjectById(oid)
                .then(documentObj => {
                    if (!documentObj) {
                        return;
                    }
                    setInitDocumentViewState(gid, documentObj)
                }).catch(err => console.error(err))
        }
    }, [userINFO, groupINFO])

    const openFullscreen = () => {
        document.documentElement.webkitRequestFullscreen();
        setFullScreenOn(true)
        setTimeout(()=> updateDimension(), 100)   
    }
    const exitFullscreen = ()=>{
        document.webkitExitFullscreen();
        setFullScreenOn(false)
    }
    const onPageChanged = (page, lastestPage)=>{
        setCurrPage(page)
        setPictureGalleryOpen(false)
    }
    
    const updateDimension = ()=>{
        const isFullScreen = ( window.innerHeight == window.screen.height)
        setFullScreenOn(isFullScreen)

        let docPaper = document.querySelector("#docpaper")
        let docImageList = document.querySelectorAll(`[id^='docimage']`)
        let docImgContainer = document.querySelector("#docimageContainer")
        
        //let docImageList = docRefs.map(d=>d.current)
        //let docImgContainer = containerRef.current

        docImageList.forEach((item)=>{
            const H = (docImgContainer)? docImgContainer.clientHeight : 1
            const W = (docImgContainer)? docImgContainer.clientWidth : 1
            let ih = (item)? item.naturalHeight : 1
            let iw = (item)? item.naturalWidth : 1
            if(ih === 0) ih = 1
            if(iw === 0) iw = 1
            if(H != undefined && W != undefined && ih != undefined  && iw != undefined ) {
                if(W/H > iw/ih){
                    item.style.height = `${H}px`// "500px"
                    item.style.width = `${H*iw/ih}px`// "500px"
                    item.style.marginLeft = `${(W - H*iw/ih)/2}px`
                    item.style.marginTop = "0px"
                }
                else {
                    item.style.height = `${W*ih/iw}px` //`auto`
                    item.style.width = `${W}px`                    
                    item.style.marginLeft = `0px` // ${(W - H*iw/ih)/2}px`
                    item.style.marginTop = `${(H-W*ih/iw)/2}px`
                }
            }
        })
    }
    const onDocImageError = ()=>{
    }
    const onDocImageLoad = (e, url)=>{        
        updateDimension()
    }
    const onImageClick = ()=>{
    }
    const onImageRightClick = ()=>{
    }
    
    const refMediaLink = useRef()
    const refPictureLink = useRef()
    const refFullScreenLink = useRef()
    const refPrevPageLink = useRef()
    const refNextPageLink = useRef()
    const refPrevDateLink = useRef()
    const refNextDateLink = useRef()
    const refPrevPersonLink = useRef()
    const refNextPersonLink = useRef()
    const refLatestDateLink = useRef()
    const refEarliestDateLink = useRef()

    const onHandleMediaClick = () => {
        setMediaMenuAnchor(refMediaLink.current)
    }
    const onHandlePictureClick = () => { setPictureGalleryOpen(!pictureGalleryOpen) }
    const onHandleFullScreenClick = () => {
        if (fullScreenOn)
            exitFullscreen()
        else
            openFullscreen()
    }
    const onHandlePrevPageClick=()=>{
        if(currPage > 0)
            setCurrPage(currPage-1)
    }
    const onHandleNextPageClick=()=>{
        if(currPage < currOBJECT.pages - 1)
            setCurrPage(currPage+1)
    }
    const onHandlePrevDateClick=()=>{
        moveToPrevDateDoc()
    }
    const onHandleNextDateClick=()=>{
        moveToNextDateDoc()
    }
    const onHandlePrevPersonClick=()=>{
        moveToPrevPersonDoc()
    }
    const onHandleNextPersonClick=()=>{
        moveToNextPersonDoc()
    }
    const onHandleLatestDateClick=()=>{
        moveToLatestDoc()
    }
    const onHandleEarliestDateClick=()=>{
        moveToOldestDoc()
    }
    const onHandleControlPanelClick=()=>{
        setPanelOpen(!panelOpen)
    }
    
    //On Media MenuList Item Click
    const doMediaLink = (target)=>{
        setMediaMenuAnchor(null)
        if(target.targetMode === "hyperlink"){
            // goto the linked site
            window.open(target.target)
        } else if(target.targetMode === "slide"){
            // goto another slide page
            const slideName = target.target
            const slideNum = parseInt(slideName.substr(5, slideName.indexOf('.')-5))
            setCurrPage(slideNum-1)
        } else if(target.targetMode === "video"){
            const urlParams = target.target.split('/')
            if(urlParams[2] === "www.youtube.com") { // youtube video
                if(urlParams[3] === "embed"){
                    // embed type
                    const videoid = urlParams[4].split("?")[0]
                    setVideoIdToPlay(videoid);
                    setOpenVideoDialog(true);
                    setYouTubeVideo(true);
                }
                else{
                    // watch type
                    const videoid = urlParams[3].split("=")[1]
                    setVideoIdToPlay(videoid);
                    setOpenVideoDialog(true);
                    setYouTubeVideo(true);
                }
            } else { // embedded video
                const videoid = target.target //.split('/')[2]
                //const videolurl = "/timsapi/videostream?=docid="+;
                // open video dialog...
                const videourl = `${proxy.getBaseURL()}/timsapi/docmedia?docid=${props.currOBJECT.id}&media=${videoid}`
                setVideoIdToPlay(videourl);
                setOpenVideoDialog(true);                
                setYouTubeVideo(false);
            }
        }
    }
    //const drawerOpen = false
    const onKeyDown = (evt)=>{
       //[2021-01-12] 동영상 플레이어 및 이미지 갤러리 표시 중에는 onKeyDown 이벤트를 실행하지 않는다
        if( openVideoDialog || pictureGalleryOpen ) {
            return;
        }
        
        const {key, keyCode, shiftKey, ctrlKey} = evt;
        console.log("onKeyDown", key, keyCode, shiftKey, ctrlKey)
        let processFlag = false;
        
        switch(key)
        {
            case "c":
            case "C":
                onHandleControlPanelClick()  
                processFlag = true
                break;
            case "f":
            case "F":
                onHandleFullScreenClick()
                processFlag = true
                break;
            case "p":
            case "P":
                onHandlePictureClick()
                processFlag = true
                break;
            case "m":
            case "M":
                onHandleMediaClick()
                processFlag = true
                break;
            case "PageUp":
            case "Home":
                onHandleLatestDateClick()
                processFlag = true
                break;
            case "End":
            case "PageDown":
                onHandleEarliestDateClick()
                processFlag = true
                break;
            case "ArrowLeft":
                onHandlePrevPersonClick()
                processFlag = true
                break;
            case "ArrowRight":
                onHandleNextPersonClick()
                processFlag = true
                break;
            case "ArrowUp":
                onHandleNextDateClick()
                processFlag = true
                break;
            case "ArrowDown":
                onHandlePrevDateClick()
                processFlag = true
                break;
            case " ":
                if(shiftKey)
                    onHandlePrevPageClick()
                else
                    onHandleNextPageClick()
                    
                processFlag = true
                break;
            case "Backspace":
                onHandlePrevPageClick()
                processFlag = true
                break;
            case "F11":
                onHandleFullScreenClick()
                processFlag = true
                break;
        }
        if(processFlag)
            evt.preventDefault()
  
    }
    const onMouseDown=()=>{
    }

    //const testStatus = getDATA("testStatus")
    let match = useRouteMatch();
    const fid = (match.params.fid)? match.params.fid : "default"
    const date = new Date(currOBJECT?.time)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const dateStr = (date)? (""+date.getFullYear()+'/'+monthNames[date.getMonth()]+'/'+date.getDate()) : ""
    const author = userINFO.find(u => u.id === currOBJECT?.author)

        return (
        <div className={classes.root}
            ref={refRoot}
            tabIndex="0"
            onKeyDown={onKeyDown}
            onMouseDown={onMouseDown}
        >
            {!currOBJECT ? null : <Fragment>
                <div id="docimageContainer" className={classes.docimageContainer} >
                    <SwipeableViews axis='x' id="SwipeableViews" index={currPage} onChangeIndex={onPageChanged} enableMouseEvents>
                        {
                            pageURLs.map((url, index) => (
                                <img id={"docimage" + index}
                                    key={"docimage" + index}
                                    onError={onDocImageError}
                                    onLoad={(e) => onDocImageLoad(e, url)}
                                    className={classes.docimage}
                                    src={url}
                                    onClick={onImageClick}
                                    onContextMenu={onImageRightClick}
                                //style={{"height":`${paperRef.current.clientHeight}px`}}
                                // height = {(!verticalMode)? (paperRef.current)? (paperRef.current.clientHeight):100 : 300}
                                />
                            ))
                        }
                    </SwipeableViews>
                </div>

                <Fab size={(panelOpen) ? "small" : "medium"}
                    onClick={onHandleControlPanelClick}
                    color="default"
                    aria-label={"Media/Links"}
                    color={(panelOpen) ? "default" : "primary"}
                    onKeyDown={onKeyDown}
                    className={clsx(classes.panelButton, classes.naviButton)}>
                    <CustomTooltip title={`Control Panel(C Key)`} placement="top">
                        <GamesIcon />
                    </CustomTooltip>
                </Fab>
                {
                    (mediaInfo[currPage] && mediaInfo[currPage].length) ?
                        <Fab size="medium" ref={refMediaLink} onClick={onHandleMediaClick} color="default" aria-label={"Media/Links"}
                            color="primary"
                            className={clsx(classes.mediaButton, classes.naviButton)}>
                            <CustomTooltip title={"Media/Links(M Key)"} placement="top">
                                <MovieIcon />
                            </CustomTooltip>
                        </Fab>
                        : null
                }
                {
                    ////////////////////////////////////////////////////////
                    // media menu
                    <Menu
                        id="simple-menu"
                        anchorEl={mediaMenuAnchor}
                        open={Boolean(mediaMenuAnchor)}
                        onClose={() => setMediaMenuAnchor(null)}
                    >
                        {
                            (mediaInfo[currPage] && mediaInfo[currPage].length) ?
                                mediaInfo[currPage].map((t) =>
                                    <MenuItem onClick={() => doMediaLink(t)} value={t.target} key={t.target + t.name}>
                                        {(t.name) ? t.name : t.target}
                                    </MenuItem>)
                                : null
                        }
                    </Menu>
                }
                {
                    //Show Image gallery button if current slide has any images
                    (imageInfo[currPage] && imageInfo[currPage].length) ?
                        <Fab size="medium" ref={refPictureLink} onClick={onHandlePictureClick} color="default" aria-label={"Pictures"}
                            color="primary"
                            className={clsx(classes.pictureButton, classes.naviButton)}>
                            <CustomTooltip title={"Pictures(P Key)"} placement="top">
                                <PhotoLibraryIcon />
                            </CustomTooltip>
                        </Fab>
                        : null
                }
                {
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={openVideoDialog}
                        onClose={() => { setOpenVideoDialog(false); setYouTubeVideo(false); }}
                    >
                        {
                            (youTubeVideo) ?
                                <YouTube
                                    className={classes.videoClass}
                                    videoId={`${videoIdToPlay}`}
                                    opts={{ height: "480", width: "640", playerVars: { autoplay: 1 } }}
                                />
                                :
                                <div>
                                    <video className={classes.videoClass} width="90%" height="90%" controls controlsList="nodownload">
                                        <source src={`${videoIdToPlay}`} type={`video/mp4`} />
                                    </video>
                                </div>
                        }
                    </Modal>
                }
                {
                    ///////////////////////////////////////////////////////////////
                    //image gallery
                    (imageInfo[currPage] && imageInfo[currPage].length && pictureGalleryOpen) ?
                        <Dialog onClose={() => setPictureGalleryOpen(!pictureGalleryOpen)}
                            open={imageInfo[currPage] && imageInfo[currPage].length && pictureGalleryOpen}>

                            <ImageGallery
                                items={
                                    imageInfo[currPage].map((i) => {
                                        return {
                                            original: `${proxy.getBaseURL()}/timsapi/docmedia?docid=${currOBJECT.id}&media=${i.target}`,
                                            thumbnail: `${proxy.getBaseURL()}/timsapi/docmedia?docid=${currOBJECT.id}&media=${i.target}`
                                        }
                                    })
                                }
                                showThumbnails={true} showNav={true} showFullscreenButton={true} showPlayButton={false}
                            />
                        </Dialog>
                        : null
                }
                <Fab size="medium" ref={refFullScreenLink} onClick={onHandleFullScreenClick} color="default" aria-label={"FullScreen"}
                    className={clsx(classes.fullScreenButton, classes.naviButton)}>
                    <CustomTooltip title={"FullScreen Toggle(F Key)"} placement="top">
                        {
                            (fullScreenOn) ? <FullscreenExitIcon />
                                : <FullscreenIcon />
                        }
                    </CustomTooltip>
                </Fab>

                <Fab size="medium" ref={refPrevPageLink} onClick={onHandlePrevPageClick} color="default" aria-label={"PrevPage"}
                    className={clsx(classes.prevPageButton, classes.naviButton)}
                    color={(currPage === 0) ? "default" : "primary"}
                >
                    <CustomTooltip title={"Previous Page(Backspace Key)"} placement="top">
                        <ChevronLeftIcon />
                    </CustomTooltip>
                </Fab>

                <Fab size="medium" ref={refNextPageLink} onClick={onHandleNextPageClick} color="default" aria-label={"NextPage"}
                    className={clsx(classes.nextPageButton, classes.naviButton)}
                    color={((currPage + 1) === (currOBJECT?.pages)) ? "default" : "primary"}
                >
                    <CustomTooltip title={"Next Page(Space Key)"} placement="top">
                        <ChevronRightIcon />
                    </CustomTooltip>
                </Fab>

                <div className={clsx(classes.currentPage)}>
                    <CustomTooltip title={"CurrentPage"} placement="top">
                        <Typography variant="h4" color="primary">{"" + (currPage + 1)}/{currOBJECT?.pages}</Typography>
                    </CustomTooltip>
                </div>
                {
                    (objectInfoDisplay && author && author.name) ?
                        <div className={clsx(classes.currentObjectInfo)}>
                            <Typography variant="h4" style={{ color: "#76ff03", backgroundColor: "gray", opacity: 0.8 }}>
                                {(currFOLDER?.is_virtual) ? currGROUP?.path + ">" + author.name + ">" + currOBJECT.title + " (" + dateStr + ")" : author.name + ">" + currOBJECT.title + " (" + dateStr + ")"}
                            </Typography>
                        </div>
                        : null
                }
                <Grow in={panelOpen}>
                    <Paper className={clsx(classes.controlPanel, { [classes.hide]: (!panelOpen) })}>
                        <Fab size="medium" ref={refPrevDateLink} onClick={onHandlePrevDateClick} color="default" aria-label={"PrevDate"}
                            className={clsx(classes.prevDateButton, classes.naviButton)}>
                            <CustomTooltip title={"Prev Date(Down Key)"} placement="top">
                                <PrevDateIcon />
                            </CustomTooltip>
                        </Fab>
                        <Fab size="medium" ref={refNextDateLink} onClick={onHandleNextDateClick} color="default" aria-label={"NextDate"}
                            className={clsx(classes.nextDateButton, classes.naviButton)}>
                            <CustomTooltip title={"Next Date(Up Key)"} placement="top">
                                <NextDateIcon />
                            </CustomTooltip>
                        </Fab>

                        <Fab size="medium" ref={refPrevPersonLink} onClick={onHandlePrevPersonClick} color="default" aria-label={"PrevPerson"}
                            className={clsx(classes.prevPersonButton, classes.naviButton)}>
                            <CustomTooltip title={"Prev Person(Left Key)"} placement="top">
                                <FirstPageIcon />
                            </CustomTooltip>
                        </Fab>
                        <Fab size="medium" ref={refNextPersonLink} onClick={onHandleNextPersonClick} color="default" aria-label={"NextPerson"}
                            className={clsx(classes.nextPersonButton, classes.naviButton)}>
                            <CustomTooltip title={"Next Person(Right Key)"} placement="top">
                                <LastPageIcon />
                            </CustomTooltip>
                        </Fab>
                        <Fab size="medium" ref={refLatestDateLink} onClick={onHandleLatestDateClick} color="default" aria-label={"LatestDate"}
                            className={clsx(classes.latestDateDocButton, classes.naviButton)}>
                            <CustomTooltip title={"Latest Document(Home Key)"} placement="top">
                                <VerticalAlignTopIcon />
                            </CustomTooltip>
                        </Fab>
                        <Fab size="medium" ref={refEarliestDateLink} onClick={onHandleEarliestDateClick} color="default" aria-label={"EarliestDate"}
                            className={clsx(classes.earliestDateDocButton, classes.naviButton)}>
                            <CustomTooltip title={"Earliest Document(End Key)"} placement="top">
                                <VerticalAlignBottomIcon />
                            </CustomTooltip>
                        </Fab>
                    </Paper>
                </Grow>
            </Fragment>}
        </div>
    )
}


const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
 }))(Tooltip);
