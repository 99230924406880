import React, { useEffect, useState } from 'react';
//import ReactDOM from 'react-dom';
import {  BrowserRouter as Router, Route,
          useRouteMatch, Switch, Link, useHistory, useLocation}  from 'react-router-dom'


export function PermissionAdmin(props){
    //const { getDATA } = props

    //const testStatus = getDATA("testStatus")
    let match = useRouteMatch();
    const fid = (match.params.fid)? match.params.fid : "default"
    return <h2>PermissionAdmin </h2>
}