import React, { useEffect, useState, createRef } from 'react';
import { useRouteMatch, Link }  from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import ArchiveIcon from '@material-ui/icons/Archive'
import ShareIcon from '@material-ui/icons/Share'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import {deleteObject as proxyDeleteObject} from './api/proxyDeleteObject';
import GroupTreeView from './GroupTree2'
import {addNewObjectLink} from "./api/proxyAddNewObjectLink"

import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'

import { API_FETCHSERVER } from './common';

import clsx from 'clsx'

        //height:`calc(100vh - ${(marginDefault*2)}px)`
const useStyles = makeStyles((theme)=>({
   root:{
       margin:0,
       width:'flex'
       //position:"relative",
       //height:`calc(100vh - 100px)`,
   },
   title:{
       color: "white",
   },
   content: {
       flexGrow: 1,
       padding: theme.spacing(0),
       transition: theme.transitions.create('margin', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
       }),
       marginLeft: 0,
       marginTop: 0,
   },
   contentShift: {
       transition: theme.transitions.create('margin', {
         easing: theme.transitions.easing.easeOut,
         duration: theme.transitions.duration.enteringScreen,
       }),
       marginLeft: 0,
       marginTop: 0,
   },
   docCardContainer:{
       backgroundColor: "gray",
       margin:0,
       padding:0,
       //width:`calc(100vw - ${250}px)`,
       //height:`calc(100vh - ${180}px)`,
       overflow:"auto",
       //scrollbarWidth: "none"
   },
   docCardContentContainer: {
      display: "flex",
      flexDirection : "row",
   },
   docCardContentColumn:{
       display:"flex",
       padding:1,
       flexDirection: "column",
       '&:not(:first-child)' : {
           margin : 1 
       },
       '&:first-child' : {
           margin : '0px 1px 1px 1px',
       },
       "& div":{
           marginTop:2
       }
   },
   docCardHeaderContainer : {
      display: "flex",
      flexDirection : "row",
      position:"sticky",
      top:0,
      zIndex:100,
   },
   docCardHeaderColumn:{
       display:"flex",
       padding:1,
        flexDirection: "column",
       "& div":{
           marginTop:2
       },
   },
   docCardHeightWithMedia:{
       minHeight:270,
       height:200
   },
   docCardHeightWithoutMedia:{
       minHeight:110,
       height:110
   },
   docCard:{
       width:250,
   },
   docCardTitle:{
       fontSize: 14
   },
   headerCardContent:{
        padding:14,
        margin:0
   },
   headerName:{
    padding:14,
        margin:0
   },
   headerCard:{
       top:4,
       backgroundColor:"#b2ff59",
       height:60,
       minHeight:60,
       maxHeight:120,
       width:250,
       margin : '1px 1px 0px 1px'
    },
   cardMedia: {
     height: 160,
   },
}))

export default function FolderView(props){
    const {
        userINFO,
        myUserINFO,
        userObjInCurrFolderINFO,
        currFOLDER,
        currGROUP,
        currOBJECT,
        currPAGE,
        proxy,
        previewMode,
        handleDocToArchive,
        onDocumentSelect,
        supervisorMode,
        refreshINFO,
        groupINFO,
        writePermissionGROUP,
    } = props

    const { objectOfUsersInFolder } = props
    const classes = useStyles()
    //const testStatus = getDATA("testStatus")
    let match = useRouteMatch();
    //const fid = (match.params.fid)? match.params.fid : "default"
    const openFullscreen = ()=>{ document.documentElement.webkitRequestFullscreen(); }
    const exitFullscreen = ()=>{ document.webkitExitFullscreen(); }
    
    const onDocumentClick = (doc, fullscreen = true)=>{
        onDocumentSelect(doc, fullscreen)
    }
    const handleDocDelete = (doc)=>{
        proxyDeleteObject(proxy, {id:doc.id}, (result)=>{
            if(result.result === "OK"){
                refreshINFO(["object"])
            }
        })
    }
    const handleDocShare = (doc)=>{ }    

    return (
        <div className={clsx(classes.root, classes.docCardContainer, classes.content)}>
            <div key="card-header-area" className={classes.docCardHeaderContainer}>
                {userObjInCurrFolderINFO.map(c => (c.objects.length) ?
                    <div key={`headerContainer_${c.uid}`} 
                        className={classes.docCardHeaderColumn}>
                        <HeaderCard
                            loginid={userINFO.find(u => u.id === c.uid)?.login_id}
                            name={userINFO.find(u => u.id === c.uid)?.name}
                        />
                    </div> :
                    null
                )}
            </div>
            <div key="card-content-area" className={classes.docCardContentContainer}>
                {
                    userObjInCurrFolderINFO.map((c) => (
                        (c.objects.length > 0) ?
                            <div key={c.uid + "container1st"}>
                                <div key={c.uid + "container"} className={classes.docCardContentColumn} >
                                    {
                                        c.objects.map((doc) => (
                                            <DocumentCard
                                                proxy={proxy}
                                                key={doc.id}
                                                docInfo={doc}
                                                handleCardClick={(e) => onDocumentClick(doc, false)}
                                                mediaEnabled={props.previewMode}
                                                sessionInfo={props.sess}
                                                handleDocFullScreen={(e) => onDocumentClick(doc)}
                                                handleDocToArchieve={handleDocToArchive}
                                                handleDocShare={(myUserINFO.id === doc.author || myUserINFO.user_level === "admin") ? handleDocShare : undefined}
                                                handleDocDelete={(myUserINFO.id === doc.author || myUserINFO.user_level === "admin") ? handleDocDelete : undefined}
                                                isMyDoc={myUserINFO.id === doc.author}
                                                isAdmin={myUserINFO.user_level === "admin" && supervisorMode}
                                                isInErasablePeriod={true} // ((Date.now() - (new Date(doc.time)).getTime())/(1000 * 24 * 3600) )< 7}
                                                isAuthor={myUserINFO.id === doc.author}
                                                myUserINFO={myUserINFO}
                                                groupINFO={groupINFO}
                                                userINFO={userINFO}
                                                refreshINFO={refreshINFO}
                                                writePermissionGROUP={writePermissionGROUP}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                            : null
                    ))
                }

            </div>
        </div>
    )
}

/*
type HeaderCardProps={
    loginid:string,
    name:string|null|undefined
}
*/
function HeaderCard(props){
    const classes = useStyles()
    return(
        <Card key={props.loginid} style={{zIndex:20}} className={classes.headerCard}>
                <Typography variant="h6" gutterBottom className={classes.headerName}>
                    {props.name}({props.loginid})
                </Typography>
        </Card>        
    )
}

/*
type DocumentCardProps={
    sessionInfo:ISessionData
    docInfo:IObjectData
    mediaEnabled:boolean
    handleCardClick:(obj:IObjectData)=>void
    handleDocFullScreen:(obj:IObjectData)=>void
    handleDocToArchieve:(obj:IObjectData)=>void
    handleDocShare:(obj:IObjectData)=>void
    handleDocDelete:(obj:IObjectData)=>void
    isMyDoc:boolean
}
*/

function DocumentCard(props){
    const {
        handleDocFullScreen,
        handleDocToArchieve,
        handleDocDelete,
        isAdmin,
        isAuthor,
        proxy,
        isInErasablePeriod,
        myUserINFO,
        groupINFO,
        refreshINFO,
        userINFO,
        writePermissionGROUP
        } = props

    const { sessionInfo } = props
    const doc = props.docInfo // document information to draw on the screen 
    const classes = useStyles()
    const docTime = new Date(doc.time)

    const [openGroupSelectDlg, setOpenGroupSelectDlg]=useState(false)
    const [docToShare, setDocToShare] = useState("")
    const [groupToShare, setGroupToShare] = useState("")

    const fullScreenButtonClick = (evt, doc)=>{
        handleDocFullScreen(doc)
    }

    const handleDocShare = (doc)=>{
        setDocToShare(doc?.id)
        setOpenGroupSelectDlg(true)
    }
    const handleCloseGroupChoice = ()=>{
        setOpenGroupSelectDlg(false)
    }
    const handleGroupSelect = (uid, gid)=>{        
        console.log("group select", uid, gid)
        setGroupToShare(gid)
    }
    // "OK" selected from document share dialog
    const handleOKGroupSelect = ()=>{
        setOpenGroupSelectDlg(false)
        if(docToShare && docToShare != "" && groupToShare != "")
            addNewObjectLink(proxy, {oid:docToShare, uid:myUserINFO.id, fid:groupINFO.find(g=>g.id === groupToShare)?.fid}, (res)=>{
                if(res.result ==="OK"){
                    refreshINFO(["object"])
                }
            })
    }


    //const proxy = new Proxy(API_FETCHSERVER)
    const slideURL=`${proxy.getBaseURL()}/timsapi/docimage?docid=${doc.id}&page=${0}`
    return(
        <Card key={doc.id} className={clsx(classes.docCard,
                {[classes.docCardHeightWithMedia]:props.mediaEnabled}, {[classes.docCardHeightWithoutMedia]:!props.mediaEnabled})}>
                    
            <Tooltip title={<Typography variant="h6">View Document</Typography>} placement="top" >
                <CardActionArea className={classes.cardDocActionArea} onClick={(e)=>props.handleCardClick(doc)}>
                    {
                        (props.mediaEnabled)?
                    <CardMedia
                        className={classes.cardMedia}
                        image={slideURL}
                    />
                    :null
                    }
                    <CardContent style={{padding:0, paddingLeft:8}}className={classes.cardDocContent}>
                        <Typography variant="subtitle1" style={{padding:0}} noWrap={true} gutterBottom>
                            {(doc.title)? doc.title : "No Title"}
                        </Typography>
                        <Typography variant="subtitle1" style={{padding:0}} component="p">
                            {
                                `${docTime.getFullYear()}/${docTime.getMonth()+1}/${docTime.getDate()}`
                            }
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Tooltip>
            <CardActions className={classes.cardDocActionArea}>
                {
                (isAdmin)?
                    <IconButton size="small" color="primary" onClick={(e)=>handleDocShare(doc)}>
                        <Tooltip title={<Typography variant="h6">{"Share to another group"}</Typography>} placement="top" >
                        {
                            <ShareIcon fontSize="small"/>
                        }
                        </Tooltip>
                    </IconButton>
                :null
                }
                {
                    (openGroupSelectDlg)?
                        <Dialog
                            open={openGroupSelectDlg}
                            onClose={handleCloseGroupChoice}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Select group to share document"}</DialogTitle>
                            <DialogContent>
                                <GroupTreeView
                                    proxy={proxy}
                                    userNodeEnabled={false}
                                    collapseDefault={false}
                                    groupINFO={groupINFO}
                                    isLimitPermit={true}
                                    userINFO={ userINFO }
                                    maxDepth={ 5 }
                                    handleNodeClick={handleGroupSelect}
                                    selectedGroup={groupINFO?.find(g=>g.id === myUserINFO.gid)}                                    
                                    permitGROUP={writePermissionGROUP}
                                    refreshINFO={refreshINFO}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleOKGroupSelect} color="primary">
                                    OK
                                </Button>
                                <Button onClick={()=>handleCloseGroupChoice()} color="primary" autoFocus>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    :null
                }
                {
                (isAdmin)?
                <IconButton size="small" color="primary" onClick={(e)=>handleDocToArchieve(doc)}>                
                    <Tooltip title={<Typography variant="h6">{(doc.archive)?"Unarchive":"Archive"}</Typography>} placement="top" >
                    {
                        (doc.archive)?
                            <UnarchiveIcon  color="secondary" fontSize="small"/>
                            :<ArchiveIcon  fontSize="small"/>
                    }
                    </Tooltip>
                </IconButton>
                :null
                }
                <IconButton size="small" color="primary" onClick={(e)=>fullScreenButtonClick(e, doc)}>
                    <Tooltip title={<Typography variant="h6">View Document Fullscreen</Typography>} placement="top" >
                        <FullscreenIcon  fontSize="small" />
                    </Tooltip>
                </IconButton>                
                {
                (isAdmin || (isAuthor && isInErasablePeriod))?
                <IconButton size="small" color="primary" onClick={(e)=>handleDocDelete(doc)}>                    
                    <Tooltip title={<Typography variant="h6">Cancel Sharing</Typography>} placement="top" >
                        <ClearIcon  fontSize="small" />
                    </Tooltip>
                </IconButton>
                :null
                }
            </CardActions>
        </Card>
    );
}
