import { EResult } from "../common"

/**
 * proxy api code to call backend api 
 * @param param[string] object id to delete
 * @param callback
 */
export function deleteObjectLink(proxy, param, callback)
{
    proxy.postFetch("/timsapi/deleteobjectlink", param)
    .then(async (response)=>{
        if(response.ok)
        {
            const body = await response.text()
            let resData = JSON.parse(body)        
            callback(resData)
        } else {
            callback({result:EResult.RESULT_ERROR, reason:"api fetch failed - deleteobjectlink", data:null});
        }
    });
}

