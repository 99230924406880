import React, { 
                useState,
                useEffect,
                ReactNode,
                SyntheticEvent,
                useRef
            } from 'react';

import { makeStyles } from '@material-ui/core/styles';

//import { IUserData, IGroupDataEx, IObjectData} from "./common";

import { Typography, StylesProvider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import { green } from '@material-ui/core/colors';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    root: {
        margin:0,
        overflow:"auto",
        height:"100%"
      },
    groupItem:{
        color: "blue"
    },
    userItem:{
        color: "green"
    },

    groupItemSelected:{

    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
}));

let dragSource = undefined

export default function GroupTreeView(props)
{
    const classes = useStyles();
    const { 
            proxy,
            groupINFO,
            userINFO,
            permitGROUP,
            userNodeEnable,
            handleNodeClick,
            modifyGroupInfo,
            collapseDefault,
            selectedGroup,
            refreshINFO,
            isLimitPermit,
            isDragDropSupport,
            maxHeight,
            docSharedGroups,
            usersInAllFolderINFO,
            selected,
            defaultExpandGroup,
            handleNodeToggle
        } = props
    
    const [rootNode, setRootNode] = useState()
    const [visibleGroup, setVisibleGroup] = useState([])
    const [expand, setExpand] = useState([])
    let allGroupIds=[]
    if(groupINFO)
        allGroupIds = groupINFO.map(g=>(g.id)? g.id : "")
    
    useEffect(()=>{
        const vg = []
        if(permitGROUP){
            for(const group of permitGROUP){
                if(!vg.find(v=> v === group))
                    vg.push(group)
                addAncestorGroups(vg, groupINFO, group)
            }
        }
        if(vg.length > 0)
            setVisibleGroup(vg)
    }, [permitGROUP])

    useEffect(() => {
        if(defaultExpandGroup) {
            setExpand(Array.from(new Set([...defaultExpandGroup, ...expand])))
        } else {
            setExpand([...allGroupIds])
        }
    },[defaultExpandGroup])
    
    const addAncestorGroups = (vgroups, groupINFO, curr)=>{        
        const myNode = groupINFO.find(g=> g.id === curr)
        if(myNode){
            if(!vgroups.find(vg=>vg === myNode.parent) && myNode.parent)
                vgroups.push(myNode.parent)

            if(myNode.parent !== "root")
                addAncestorGroups(vgroups, groupINFO, myNode.parent)
        }
    }

    useEffect(()=>{
        if(groupINFO)
        {
            const roots = groupINFO?.filter((g)=> g.parent === null)
            if(roots && roots.length>0){
                const root = roots[0]
                setRootNode(root)
            }
        }
    },[groupINFO, permitGROUP, isLimitPermit])

    const handleDragEvent = (evt, target, type, position) => {
        const srcinfo = JSON.parse(evt.dataTransfer.getData("text"))
        if(!target)
            return
        console.log("handleDragEvent", srcinfo.title, target.title, type, position)

        // source.parent = target
        const src = groupINFO.find(g=>g.id === srcinfo.id)
        if(src.id !== target.id){
            src.parent = target.id
            modifyGroupInfo(proxy, src, (data)=>{
                if(refreshINFO)
                    refreshINFO(["group"])
            })
        }
    }

    const [dragTarget, setDragTarget] = useState(undefined)
    const [dragState, setDragState] = useState("")

    const onDragStart = (evt, node)=>{
        console.log("onDragStart1", node)
        if(!dragSource) {
            evt.dataTransfer.setData("text/plain", JSON.stringify(node));
            dragSource = node
            console.log("onDragStart2:", node)
        }
    }
    const onDrag = (evt, node)=>{
        console.log("onDrag")
    }
    const onDragEnd = (evt, node)=>{
        //setDragSource(undefined)
        setDragTarget(undefined)
        setDragState("")
        console.log("onDragEnd")
        dragSource = undefined
    }
    const onDragEnter = (evt, node)=>{
        console.log("onDragEnter")
    }
    const onDragOver = (evt, node)=>{
        const gevt = evt
        const height = evt.target.clientHeight
        const interXPos = evt.pageX - evt.currentTarget.getClientRects()[0].x
        const interYPos = evt.pageY - evt.currentTarget.getClientRects()[0].y 

        evt.stopPropagation()
        evt.preventDefault()

        let pos = "mid"
        if(interYPos < (height /3)) {
            pos = "high"
        } else if(interYPos >= (height/3) && interYPos <= (height *2 /3)) {
            pos = "mid"
        } else if(interYPos >= (height*2/3)) {
            pos = "low"
        }
       
        if((node?.id === dragSource?.parent && pos === "mid")
        || ((node).parent === null && pos != "mid" ))
        {
            setDragTarget(null) // for display
            return
        }

        if(node !== dragSource){
            setDragTarget(node) // for display
            setDragState(pos)
            console.log(`drag Target:${node?.name}, state:${pos}`)
        }
        console.log("onDragOver")
    }
    const onDragLeave = (evt, node)=>{
        console.log("onDragLeave")
        dragSource = undefined
    }
    const onDrop = (evt, node)=>{
        console.log("onDrop")

        const height = evt.target.clientHeight       
        const interXPos = evt.pageX - evt.currentTarget.getClientRects()[0].x
        const interYPos = evt.pageY - evt.currentTarget.getClientRects()[0].y 
        let pos="mid"
        if(interYPos < (height /3)) {
            pos = "high"
        } else if(interYPos >= (height/3) && interYPos <= (height *2 /3)) {
            pos = "mid"
        } else if(interYPos >= (height*2/3)) {
            pos = "low"
        }

        const target = node
        if (target.parent === null && pos != "mid")
            return
        
        if(node.id !== dragSource?.id){
            setDragTarget(node) // for display
            setDragState(pos)
        }
        // const ddd:string = evt.dataTransfer?.getData("src")   
        // do nothing...
        if(dragSource?.id !== target.id && dragSource !== null){
            console.log("onDrop:(",interXPos,",",interYPos,"), target:",node, dragState, "src:",dragSource?.title+"("+dragSource?.name+")"
                                                                    , "target:",dragTarget?.title+"("+dragTarget?.name+")")
            handleDragEvent(evt,
                            dragTarget, // : dragTarget as unknown as IUserData,
                            pos,
                            {x:interXPos, y:interYPos});
            //setDragSource(undefined)
            dragSource = undefined
        }
    }
    
    const onNodeToggle = (event, groupId) => {
        setExpand(groupId)

        if(!handleNodeToggle) {
            return;
        }
        handleNodeToggle(groupId)
    } 

    return(
        <TreeView
            style={{minWidth:250, maxHeight:(maxHeight)?maxHeight :400 }}
            className={classes.root}
            expanded={expand}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeToggle={onNodeToggle}
        >
            <GroupTreeItem 
                key={rootNode?.id+"head"}
                currentNode={rootNode}
                groupINFO={groupINFO}
                userINFO={userINFO}
                permitGROUP={permitGROUP}
                userNodeEnable={userNodeEnable}
                collapseDefault={collapseDefault}
                selectedGroup={selectedGroup}
                handleNodeClick={handleNodeClick}
                isRoot={true}
                isLimitPermit={isLimitPermit}
                isDragDropSupport={isDragDropSupport}
                visibleGroup = {visibleGroup}
                usersInAllFolderINFO={usersInAllFolderINFO}

                onDragStart={onDragStart}
                onDrag={onDrag}
                onDragEnd={onDragEnd}
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave} 
                onDrop={onDrop}
                docSharedGroups={docSharedGroups}
            />
        </TreeView>
    );
}

function GroupTreeItem(props)
{
    const classes = useStyles();
    const {
        currentNode,
        groupINFO,
        userINFO,
        permitGROUP,
        userNodeEnable,
        collapseDefault,
        selectedGroup,
        handleNodeClick,
        isRoot,
        isLimitPermit,
        isDragDropSupport,
        visibleGroup,
        docSharedGroups,
        usersInAllFolderINFO,
        onDragStart,
        onDrag,
        onDragEnd,
        onDragEnter,
        onDragOver,
        onDragLeave,
        onDrop,
    } = props

    const [children, setChildren] = useState([])  // group children
    const [userChildren, setUserChildren] = useState([])  // user children

    useEffect(()=>{
        if(currentNode && groupINFO && userINFO) {
            const chGroups = []
            for(const group of groupINFO){
                if(group.parent === currentNode.id)
                    chGroups.push(group)
            }
            setChildren(chGroups)

            if(usersInAllFolderINFO){
                const groupUsers = usersInAllFolderINFO.find(ui => ui.fid === currentNode.fid)?.users
                if(groupUsers){                    
                    if(groupUsers.length > 0){
                        const users = currentNode.users;
                        groupUsers.sort((a, b)=>(
                            users.indexOf(a.id) - users.indexOf(b.id)
                        ))
                    }
                    setUserChildren(groupUsers)
                }
            }

        }
    }, [currentNode, groupINFO, userINFO, usersInAllFolderINFO])

    const handleLabelClick = (evt, gid, uid, checkbox)=>{
        evt.preventDefault()
        if(checkbox) return;
         handleNodeClick(uid, gid)
    }

    const handleIconClick = (evt, id)=>{
        //evt.preventDefault()
    }
   
    const found = ()=> (isLimitPermit)? (permitGROUP?.find(p=> p === currentNode?.id) ? true : false): true
    const shared = ()=> (docSharedGroups)? (docSharedGroups.find(d=> d.id === currentNode?.id) ? true : false): false
    const visible= (p)=> visibleGroup.find(v=> v === p)? true : false

    const color = found()? ( shared()? "secondary":"primary" ):"initial"
    const backgroundColor = found()? ( shared()? "blue":"white" ):"white"
    const isMyGroup= "" // (currentNode?.id === selectedGroup?.id)? "-My Group" : ""
    return (
        (currentNode)?
        <TreeItem 
            className={classes.groupItem, (currentNode.id === selectedGroup?.id)? classes.groupItemSelected: null}
            key={currentNode.id}
            id={currentNode.id} 
            nodeId={currentNode.id} 
            label={
                <div>
                    {
                    (docSharedGroups)?          
                        (found())?
                        <FormControlLabel
                            control={
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={shared()}
                                name={currentNode?.id}
                                onChange={(evt)=>handleLabelClick(evt, currentNode.id, null, true)}
                            />
                            }
                            label={(visible(currentNode.id))? (currentNode.title)?`${currentNode.title + isMyGroup}`:`(${currentNode.name+isMyGroup})` : "group"}
       
                        />
                        :
                        <Typography color={color} style={{backgroundColor:backgroundColor}}>
                            {(visible(currentNode.id))? (currentNode.title)?`${currentNode.title + isMyGroup}`:`(${currentNode.name+isMyGroup})` : "group"}
                        </Typography>
                    :
                        <div className={classes.labelRoot}>
                            <GroupIcon className={classes.labelIcon} 
                                style={{color:(permitGROUP.find(p=>p === currentNode.id))? green[500]:"gray"}}
                            />
                            <Typography className={classes.labelText} variant="subtitle1" 
                                style={{color:(permitGROUP.find(p=>p === currentNode.id))? green[500]:"gray"}}
                            >
                                {(currentNode.title)? `${currentNode.title + isMyGroup}`:`${currentNode.name+isMyGroup}`}
                            </Typography>
                        </div>

                    }
                </div>
            } 
            draggable={(isRoot || !isDragDropSupport)? false : true}
            onLabelClick={(evt)=>handleLabelClick(evt, currentNode.id, null, false)}
            onIconClick={(evt)=>handleIconClick(evt, currentNode.id)}

            onDragStart = {(e)=>onDragStart(e, currentNode)}
            onDrag = {(e)=>onDrag(e, currentNode)}
            onDragEnd = {(e)=>onDragEnd(e, currentNode)}
            onDragEnter = {(e)=>onDragEnter(e, currentNode)}
            onDragOver = {(e)=>onDragOver(e, currentNode)}
            onDragLeave = {(e)=>onDragLeave(e, currentNode)}
            onDrop = {(e)=>onDrop(e, currentNode)}
        >
            {
                children.map(c=>(
                    visibleGroup.find(v=>v === c.id)?
                    <GroupTreeItem
                        key={c.id+"head"}
                        currentNode={c}
                        groupINFO={groupINFO}
                        userINFO={userINFO}
                        isLimitPermit={isLimitPermit}
                        permitGROUP={permitGROUP}
                        userNodeEnable={userNodeEnable}
                        collapseDefault={collapseDefault}
                        selectedGroup={selectedGroup}
                        handleNodeClick={handleNodeClick}
                        isRoot={false}
                        isDragDropSupport={isDragDropSupport}
                        visibleGroup={visibleGroup}
                        usersInAllFolderINFO={usersInAllFolderINFO}

                        onDragStart={onDragStart}
                        onDrag={onDrag}
                        onDragEnd={onDragEnd}
                        onDragEnter={onDragEnter}
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave} 
                        onDrop={onDrop}
                        docSharedGroups={docSharedGroups}
                    />
                    :null
                ))
            }
            {
                (userNodeEnable)?
                userChildren.map(uc=>(
                    <TreeItem
                        draggable={false}
                        className={classes.userItem}
                        key={currentNode.id+uc.id}
                        id={currentNode.id+uc.id}
                        nodeId={currentNode.id+uc.id}
                        label={                            
                            <div className={classes.labelRoot}>
                                <PersonIcon className={classes.labelIcon} color={"inherit"} />
                                <Typography className={classes.labelText} color={"inherit"} variant="subtitle1" >{`${uc.name}(${uc.login_id})`}</Typography>
                            </div>
                        }
                        onLabelClick={(evt)=>handleLabelClick(evt, currentNode.id, uc.id, false)}
                    />
                ))
                :null
            }
        </TreeItem>
        :null
    )
}

