import React, { useEffect, useState, useRef, createRef } from 'react';
//import ReactDOM from 'react-dom';
import { DocumentView} from "./DocumentView"
import FolderView from "./FolderView"
import { MyProfile } from "./MyProfile"
import { MyDocuments } from "./MyDocuments"
import { PermissionAdmin } from "./PermissionAdmin"
// import GroupEditorView from "./GroupEditorView"
import { DocumentAdmin } from "./DocumentAdmin"
import { GroupAdmin } from "./GroupAdmin"

import { Proxy } from "./api/proxy"
import { signOut } from './api/proxySignOut'
import { getUserObjectInFolder } from './api/proxyGetUserObjectInFolder'
import { getGroupTree } from './api/proxyGetGroupTree'
import { getAllUserInfo } from './api/proxyGetAllUserInfo'
import { getAllFolderInfo } from './api/proxyGetAllFolderInfo'
import { getUserPermissions } from './api/proxyGetUserPermissions'
import { getMySessionData } from './api/proxyGetMySessionData'
import { getUsersInFolder } from './api/proxyGetUsersInFolder'
import { getUserObjectCountInFolder2 } from './api/proxyGetUserObjectCountInFolder'
import { getObjectInfoJustAfter } from './api/proxyGetObjectInfoJustAfter'
import { getObjectInfoJustBefore } from './api/proxyGetObjectInfoJustBefore'
import { getObjectInfoCloseTime } from './api/proxyGetObjectInfoCloseTime'
import { getObjectInfoOldest } from './api/proxyGetObjectInfoOldest'
import { getObjectInfoLatest } from './api/proxyGetObjectInfoLatest'
import { getObjectInfo } from "./api/proxyGetObjectInfo"
import { modifyObjectInfo } from './api/proxyModifyObjectInfo'
import { getUserObjectInFolderSimple } from './api/proxyGetUserObjectInFolderSimple'


import { Backdrop, Typography } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress'
import AppBarSimple from "./AppBarSimple"
import AppBarComposite from "./AppBarCompsite"
import AppBarDocumentView from "./AppBarDocumentView"
import AppBarMyDocument from "./AppBarMyDocument"
import Paper from '@material-ui/core/Paper'
import clsx from 'clsx'
import { Redirect } from 'react-router-dom'
import { useCookies, withCookies } from 'react-cookie';
import Snackbar from '@material-ui/core/Snackbar';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import MuiAlert from '@material-ui/lab/Alert';
// import PersonalFolderGrid from '@material-ui/core/PersonalFolderGrid'

import { BrowserRouter as Router,
         Route,
         useRouteMatch,
         Switch,
         Link,
         useHistory,
         useLocation}  from 'react-router-dom'
import { parseUrl } from 'query-string'
import { makeStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ESessionMessage } from './common';
import { UserInfoAdmin } from './UserInfoAdmin';
import { modifyGroupInfo } from './api/proxyModifyGroupInfo';

const APPBAR_TIMEOUT = 3000  // if mouse out of the appbar and any menu is not popped up, appbar wiill be disappeared after this milliseconds
const appbarHeight = 70
const drawerWidth = 200
const marginDefault = 2
let mouseOutOfAppbarTimer = undefined

const useStyles = makeStyles((theme)=>({
    hide:{
        display:"none"
    },
    marginedAppBar:{
        marginTop:80
    },
    appBar:{
        display:"block"
        //flexGrow:1,
        //display:"block",
        //height: `${appbarHeight}px`,
        //minHeight:`${appbarHeight}px`
    },
    withAppBar:{
        //top: appbarHeight + marginDefault ,
        height:`calc(100vh - ${appbarHeight+(marginDefault*2)}px)`        
    },
    withoutAppBar:{
        //top: marginDefault*2,
        height:`calc(100vh - ${marginDefault*2}px)`
    },
    withDrawer:{
        width:`calc(100vw - ${drawerWidth+marginDefault*2}px)`,
    },
    withoutDrawer:{
        width:`calc(100vw - ${marginDefault*2}px)`,
    },
    rootPaper: {
        //width:'flex',
        display:"flex",
        flexDirection:"column",
        backgroundColor:"white",
        margin:marginDefault,
        //height:`calc(100vh -${marginDefault*2}px)`,
        //height:`calc(100vh - ${100}px)`,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#aaa',
    },
}))

///////////////////////////////////////////////////////////////
// params : {uid, fid, offset, count, order, search, archive}
// returns Promise[] 

// get user's object's information of simple but all 
const getUserObjectInFolderSimpleAsync =  (proxy, params) =>{
    return new Promise((resolve, reject)=>{
        getUserObjectInFolderSimple(proxy, 
            {   uid:params.uid,
                fid:params.fid,
                offset:params.offset, //currPage*rowsPerPage,
                count:params.count, //rowsPerPage, 
                sort:"time",
                order:"DESC",
                search: params.search,
                archive:params.archive
            },
            (res)=>{
                if(res.result === "OK")
                {
                    resolve(res.data)
                } else {
                    reject("Error: failed to getUserObjectInFolderSimple")
                }
            }
        );
    })
}

const getUserObjecInFolderManyAsync = (proxy, users, fid, offset, count, order, searchFilter, archive)=>{
    const promiseAll = []
    users.forEach((user)=>{
        const paramToGetAll = {
            uid:user.id,
            fid:fid,
            offset:offset,
            count:count,
            sort:"time",
            order:order,
            search: searchFilter,
            archive: archive
        }
        promiseAll.push(
            getUserObjectInFolderOneAsync(proxy, paramToGetAll)
        )
    })
    return promiseAll
}
const getUserObjectInFolderOneAsync = (proxy, params) =>{
    return new Promise((resolve, reject)=>{
        getUserObjectInFolder(proxy, 
            {   uid:params.uid,
                fid:params.fid,
                offset:params.offset, //currPage*rowsPerPage,
                count:params.count, //rowsPerPage, 
                sort:"time",
                order:"DESC",
                search: params.search,
                archive:params.archive
            },
            (res)=>{
                if(res.result === "OK")
                {
                    const objs = res.data
                    for(let i = 0 ; i < objs.length; i++)
                        objs[i].pages = parseInt(objs[i].pages.toString(), 10)

                    resolve({uid:params.uid, objects:res.data})
                } else {
                    reject("Error: failed to getUserObjectInFolder")
                }
            }
        );
    })
}
const groupDataUpdateAsync = (proxy)=>{
    // 모든 GROUP INFORMATION 을 얻어옴.
    return new Promise((resGroupData, rejGroupData)=>{          
        getGroupTree(proxy, (res)=>{
            if(res.result === "OK") {
                resGroupData(res.data)
            }
            else
                rejGroupData()
        })
    })
}
const userDataUpdateAsync = (proxy)=>{
    // 모든 TIMS USER 의 INFORMATION 을 얻어옴.
    return new Promise((resUserInfo, rejUserInfo)=>{
        getAllUserInfo(proxy, (res)=>{      
            if(res.result === "OK"){
                resUserInfo(res.data)
            }
            else{
                rejUserInfo()
            }
        })
    })
}
const folderDataUpdateAsync = (proxy)=>{
    // 모든 FOLDER 의 INFORMATION 을 얻어옴.
    return new Promise((resFolderData, rejFolderData)=>{          
        getAllFolderInfo(proxy, (res)=>{
            if(res.result === "OK")
                resFolderData(res.data)
            else
                rejFolderData()
        })
    })
}
/**
 * 주어진 uid의 모든 permission 정보를 얻어옴.
 * @param {*} proxy 
 * @param {*} uid 
 */
const myPermissionInfoUpdateAsync = (proxy, uid)=>{        
    return new Promise((resPermissionData, rejPermissionData)=>{
        if(!uid)
            return rejPermissionData()
        getUserPermissions(proxy, uid, (res)=>{
            if(res.result === "OK") {
                resPermissionData(res.data)
            }
            else
                rejPermissionData()
        })
    })
}

const getUsersInFolderAsync = (proxy, fid, filter, filterEnable)=>{
    return new Promise((resolve, reject)=>{
        getUsersInFolder(proxy, {fid, filter: (filterEnable)? filter: ""}, (res)=>{
            if(res.result === "OK")
                resolve(res.data)
            else
                reject(`Error: failed to fetch data getUsersInFolder ${fid}`)
        })
    })
}

const getUsersInFolderAsync2 = (proxy, fid, filter, filterEnable)=>{
    return new Promise((resolve, reject)=>{
        getUsersInFolder(proxy, {fid, filter: (filterEnable)? filter: ""}, (res)=>{
            if(res.result === "OK")
                resolve({fid, users:res.data})
            else
                reject(`Error: failed to fetch data getUsersInFolder ${fid}`)
        })
    })
}
// returns array of promise object
const getUsersForManyFolders=(proxy, filter, filterEnable, groupINFO)=>{
    const promises = []
    groupINFO.forEach(group=>{
        const fid = group.fid
        const gid = group.id
        promises.push(
            getUsersInFolderAsync2(proxy, fid, filter, filterEnable)
        )
    })
    return promises    
}

/*
const allGroupDescendants = (top, tree)=>{
    const descendants = []
    let child = top.first_child    
    while(child){
        descendants.push(child)
        const childNode = tree.find(t=>t.id === child)
        if(childNode) {
            const agd = allGroupDescendants(childNode, tree)
            if(agd)
                agd.forEach(c=>descendants.push(c))
            child = childNode.next_sibling
        }
    }
    return descendants
}*/
const allGroupDescendants = (top, tree, descendents)=>{
    // const descendants = []
    const children = []
    for(const node of tree) {
        if (node.parent === top.id) {
            children.push(node)
            descendents.push(node.id)
        }
    }

    children.forEach(child=>{
        allGroupDescendants(child, tree, descendents)
    })
}

//const updateMyPermission=(uid:string|undefined, gid:string|undefined, usersInfo:IUserData[]|null, groupTree:IGroupDataEx[]|null, permissions:IPermissionData[]|null)=>{
const updateMyPermission=(uid, gid, usersInfo, groupTree, permissions, isAdmin)=>{
        if(uid && groupTree && permissions){
            const readGroups= []
            const myGroup = groupTree.find(g=>g.id === gid)
            const myParentGroup = groupTree.find(g=>g.id === myGroup?.parent)
            const myUserData = usersInfo?.find(u=>u.id === uid)
            //let isAdmin = false
            //if(myUserData)
            //    isAdmin = (myUserData.user_level === "admin")
           
            ///////////////////////////////////
            // giving read permission
            if(isAdmin){
                groupTree.forEach(g=> readGroups.push(g.id))
            }
            else if(myGroup){
                readGroups.push(myGroup.id) // my group itself
    
                // permission by group position
                /*
                if(myGroup.parent) readGroups.push(myGroup.parent)  // my just parent group
                const descendantsId = []
                allGroupDescendants(myParentGroup, groupTree, descendantsId)  // and all descendants
                descendantsId.forEach(d => readGroups.push(d))
    
                /////////////////////////////////////////////////
                // find all groups I stand as a mamanger of the group
                const myManagementGroups = []
                groupTree.forEach(g=> {
                    if(g.manager === uid) {
                        myManagementGroups.push(g.id)
                        if(!readGroups.find(g=> g === g.id))
                            readGroups.push(g.id)
                    }
                })
                if(myManagementGroups.length > 0) {
                    myManagementGroups.forEach( mg =>{
                        const mDescendantsId = []
                        const g = groupTree.find(g=> g.id === mg)
                        allGroupDescendants(g, groupTree, mDescendantsId)
                        mDescendantsId.forEach( d=>{
                            if(readGroups.findIndex(g => g === d) == -1)
                                readGroups.push(d)
                        })
                    })
                }
                */
                ////////////////////////////////////////////////////
                
            }
            //////////////////////////////////
            // giving write permission
            const writeGroups = []
            if(isAdmin){
                groupTree.forEach(g=> writeGroups.push(g.id))
            }
            else if(myGroup){
                  writeGroups.push(myGroup.id) // my group itself
                //if(myGroup.parent) writeGroups.push(myGroup.parent)  // my just parent group
            } 
    
            //////////////////////////////////
            // giving specified permission
            // to the dedicated group-user by permission table
            if(permissions){
                permissions.forEach(p=>{
                    if(p.uid === uid){
                        const theGroup = groupTree.find(g=> g.fid === p.fid)
                        if(theGroup){
                            if(p.permission === "read") readGroups.push(theGroup.id)
                            if(p.permission === "write") writeGroups.push(theGroup.id)
                            if(p.permission === "readWrite") {
                                readGroups.push(theGroup.id)
                                writeGroups.push(theGroup.id)
                            }
                        }
                    }
                })
            }
            /////////////////////////////////////
            return {readGroups, writeGroups}
        }
    }    

/**
 * result data object structure
 * {
 *    id: string;   // session id
 *    time: Date;   // login time
 *    message: ISessionMessage[] | null;
 *    uid: string; // uid
 *    username: string;
 *    loginid: string;
 *    timeoutCount:number;
 * }
 */
const verifySession = (proxy)=>{
    //console.log("Session verifying ... ")
    return new Promise((resolve, reject) =>{
        getMySessionData(proxy, (res)=>{
            return (res.result === "OK")? resolve(res.data) : reject("Error: Invalid session")
        })
    })
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DEFAULT_SESSTION_TIMEOUT = 60 * 30    // 30 minutes
const SESSION_WARNING_COUNT = 60            // 1 minutes
const MYDOCUMENT_DEFAULTRPP = 19
const FOLDERVIEW_DEFAULTRPP = 10

///////////////////////////////////////////////////////////////
// The Root component of TIMS ui
function Root(props){
    const classes = useStyles()
    const proxy = new Proxy()

    let match = useRouteMatch();
    let location = useLocation();
    let parsedURL = location.search? parseUrl(location.search): ""
    let viewName = match.params.viewname
    let SESSION_TIMEOUT = DEFAULT_SESSTION_TIMEOUT

    const [cookies, setCookie] = useCookies(['sess'])
    const [userINFO, setUserINFO] = useState([])
    const [groupINFO, setGroupINFO] = useState([])
    const [folderINFO, setFolderINFO] = useState([])    
    const [userObjInCurrFolderINFO, setUserObjInCurrFolderINFO] = useState([])
    const [usersInFolderINFO, setUsersInFolderINFO] = useState([])
    const [usersInAllFolderINFO, setUsersInAllFolderINFO] = useState()

    const [progressOpen, setProgressOpen] = useState(false)
    const [mySessionINFO, setMySessionINFO] = useState(undefined)
    const [myUserINFO, setMyUserINFO] = useState(undefined)
    const [myGroupINFO, setMyGroupINFO] = useState(undefined)
    
    const [oldFOLDER, setOldFOLDER] = useState(undefined)
    const [currGROUP, setCurrGROUP] = useState(undefined)
    const [currFOLDER, setCurrFOLDER] = useState(undefined)
    const [currOBJECT, setCurrOBJECT] = useState(undefined)
    const [currDOCPAGE, setCurrDOCPAGE] = useState(0)
    const [currAUTHOR, setCurrAUTHOR] = useState(undefined)
    const [currPAGE, setCurrPAGE] = useState(0)
    const [currROWPERPAGE, setCurrROWPERPAGE] = useState(10)
    const [currView,    setCurrView] = useState("")
    const [supervisorMode, setSupervisorMode] = useState(false)
    const [currUserOBJECT, setCurrUserOBJECT] = useState(undefined)

    const [maxUserObjectCount, setMaxUserObjectCount]= useState(0)
    const [userObjectCounts, setUserObjectCounts] = useState([])

    //////////////////////////////////////////////////////
    // personal permission information 
    const [myPermissionINFO, setMyPermissionINFO] = useState([])
    const [permissionINFO, setPermissionINFO] = useState([])

    const [readPermissionGROUP, setReadPermissionGROUP] = useState([])
    const [writePermissionGROUP, setWritePermissionGROUP] = useState([])
    const [myDocInProgress, setMyDocInProgress] = useState([])
    //////////////////////////////////////////////////////
    // all permission information for admin mode
    //const [allPermissionDATA, setAllPermissionDATA] = useState(undefined)


    const [searchFILTER, setSearchFILTER] = useState("")
    const [prevSearchFILTER, setPrevSearchFILTER] = useState("")
    const [filterENABLE, setFilterENABLE] = useState(true)
    const [listMODE, setListMode] = useState(false)

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [appbarOpen, setAppbarOpen] = useState(true)

    ///////////////////////////////////////////////////
    // dirty flags to refresh data from backend server
    const [dirtyData, setDirtyData] = useState(0)
    const [dirtyUserINFO, setDirtyUserINFO] = useState(0)
    const [dirtyFolderINFO, setDirtyFolderINFO] = useState(0)
    const [dirtyGroupINFO, setDirtyGroupINFO] = useState(0)
    const [dirtyMyPermissionINFO, setDirtyMyPermissionINFO] = useState(0)
    const [dirtyPermissionINFO, setDirtyPermissionINFO] = useState(0)
    const [dirtyUsersInFolderINFO, setDirtyUsersInFolderINFO] = useState(0)
    const [dirtyUserObjInCurrentFolderINFO, setDirtyUserObjInCurrentFolderINFO] = useState(0)
    /////////////////////////////////////////////////
    
    const [sessTimer, setSessTimer] = useState()
    const [sessTimeoutCount, setSessTimeoutCount] = useState(0)
    const [sessionExpired, setSessionExpired] = useState(false)
    const timerRef = useRef(sessTimeoutCount)
    const [openSessionTimeoutWarning, setOpenSessionTimeoutWarning] = useState(false)

    const [alertMessage, setAlertMessage] = useState("")
    const [alertVariant, setAlertVariant] = useState("default")
    const [snackBarOpen, setSnackBarOpen] = useState(false)

    const [archiveFlag, setArchiveFlag] = useState(false)
    const [previewMode, setPreviewMode] = useState(false)

    const [appbarInTrigger, setAppbarInTrigger] = useState(false)
    const [appbarMenuOn, setAppbarMenuOn] = useState(false)


    const [redirectState, setRedirectState] = useState(false);

    //const appbarRef = React.createRef()
    useEffect(()=>{
        if(viewName !== currView){
            if(currView === "folder") // from folder to other view, backup current folder
                setOldFOLDER(currFOLDER)

            setCurrView(viewName)
        }
    })
    useEffect(()=>{
        // get current author's all object in the folder
        if(currAUTHOR && currFOLDER) {// && currFOLDER.is_virtual){
            const paramToGet = {
                uid:currAUTHOR.id,
                fid:currFOLDER.id,
                offset:0,
                count:99999,// 
                sort:"time",
                order:"DESC",
                search: (filterENABLE)? searchFILTER:"", archiveFlag,
                archive: archiveFlag
            }
            // 필요정보, : id, title, time, 
            getUserObjectInFolderSimpleAsync(proxy, paramToGet)
            .then((results)=>{
                setCurrUserOBJECT(results)
            })
        }
    }, [currAUTHOR, currFOLDER])
    useEffect(()=>{
        // personal folder

        if(currView !== "document")
            exitFullscreen()

        if(currView === "mydocuments"){            
            setCurrROWPERPAGE(MYDOCUMENT_DEFAULTRPP)
            setInitMyDocuments()            
        }
        else if(currView === "document"){
            
        }
        else if(currView === "folder")
        {
            setCurrROWPERPAGE(FOLDERVIEW_DEFAULTRPP)
            if(!currFOLDER?.is_virtual) {
                let viewFolder = oldFOLDER ? oldFOLDER : folderINFO.find(folder => folder.id === currGROUP.fid);
                setCurrFOLDER(viewFolder)
            }
                
        }
    }, [currView])

    useEffect(()=>{
        const sessTimer = setInterval(()=>updateSessionTimeout(), 1000)
        const sessVerifyTimer = setInterval(()=>periodicVerifySession(), 2000)
        
        setSessTimer(sessTimer)
        verifySession(proxy)
        .then((sess)=>{
            setMySessionINFO(sess)
            /*
            if(sess && userINFO && userINFO.length > 0 && !myUserINFO)
            {
                const myInfo = userINFO.find(d=> d.id === sess.uid)
                if(myInfo) setMyUserINFO(myInfo)
            }
            */
            setDirtyPermissionINFO(dirtyPermissionINFO+1)
            if(userINFO && userINFO.length > 0) {
                // sess.uid
                setDirtyUsersInFolderINFO(dirtyUsersInFolderINFO+1)
            }
            console.log("update mySessionINFO")
        })
        .catch((e)=>{
            console.log(e.toString())
            setSessionExpired(!sessionExpired)
        })
        
        if(viewName !== currView){
            gotoLink(currView)
        }
        return () => { 
            clearInterval(sessTimer);
            clearInterval(sessVerifyTimer);
        }
    }, [])

    useEffect(()=>{
        if(userINFO.length > 0 && mySessionINFO){
            const myInfo = userINFO.find(d=> d.id === mySessionINFO.uid)
            if(myInfo) setMyUserINFO(myInfo)
        }
    }, [userINFO, mySessionINFO])
    // fetch data from backend server in case of below flag
    useEffect(()=>{
        if(userINFO.length>0 && groupINFO.length>0 && folderINFO.length>0 && mySessionINFO && !currFOLDER && !currGROUP){
            const myuid = mySessionINFO.uid
            const myinfo = userINFO.find(u=>u.id === myuid)
            let mygroup, mygroupfolder
            if(myinfo){
                mygroup = groupINFO.find(g=>g.id === myinfo.gid)
                if(mygroup)
                    mygroupfolder = folderINFO.find(f=>f.id === mygroup.fid)
                setCurrFOLDER(mygroupfolder)
                setCurrGROUP(mygroup)
                setDirtyUsersInFolderINFO(dirtyUsersInFolderINFO+1)
            }
        }
    }, [userINFO, groupINFO])
    
    useEffect(()=>{
        console.log("update userINFO")
        let sessInfo = undefined

        setProgressOpen(true);
        verifySession(proxy)
        .then((sess)=>{
            setMySessionINFO(sess)
            sessInfo = sess
            return userDataUpdateAsync(proxy)
        })
        .then((data)=>{
            setUserINFO(data)
            //const ssid = cookies.sess
            if(sessInfo)
            {
                const myInfo = data.find(d=> d.id === sessInfo.uid)
                if(myInfo) setMyUserINFO(myInfo)
            }
        })
        .finally(()=>{
            setProgressOpen(false);
        })
    }, [dirtyUserINFO])
    useEffect(()=>{
        console.log("update folderINFO")        
        setProgressOpen(true);
        folderDataUpdateAsync(proxy)
        .then((data)=>{
            setFolderINFO(data)
        })
        .finally(()=>{
            setProgressOpen(false);
        })
    }, [dirtyFolderINFO])
    useEffect(()=>{
        console.log("update groupINFO")     
        setProgressOpen(true);
        groupDataUpdateAsync(proxy)
        .then((data)=>{
            setGroupINFO(data)
        })
        .finally(()=>{
            setProgressOpen(false);
        })
    }, [dirtyGroupINFO])
    useEffect(()=>{
        console.log("update permissionINFO")
        if(mySessionINFO) {
            myPermissionInfoUpdateAsync(proxy, mySessionINFO.uid)
            .then((data)=>{
                setMyPermissionINFO(data)
            })
        }
    }, [dirtyPermissionINFO])

    useEffect(()=>{
        console.log("curentFOLDER changed ", currFOLDER)
        setCurrPAGE(0)
    }, [currFOLDER])

    const updateUserAndObjectData = ()=>{
        if(!currFOLDER || currFOLDER === ""){
            console.log("invalid curret folder: ", currFOLDER)
            return
        }
        //console.log("dirtyUserObjInCurrentFolderINFO updating...");
        setProgressOpen(true);
        getUsersInFolderAsync(proxy, currFOLDER.id, (filterENABLE && currFOLDER.is_virtual)? searchFILTER: "", filterENABLE)
        .then((data)=>{
            if(currGROUP)
                data.sort((a, b)=> currGROUP.users?.indexOf(a.id) - currGROUP.users?.indexOf(b.id))

            setUsersInFolderINFO(data)
            console.log("update users in folder")
            if(!data || data.length === 0)
                return ;

            const fetchSize = !currFOLDER.is_virtual ? MYDOCUMENT_DEFAULTRPP : currROWPERPAGE;
            const allPromise = 
                getUserObjecInFolderManyAsync(
                    proxy,
                    data,
                    currFOLDER.id,
                    currPAGE * fetchSize,
                    fetchSize,
                    "DESC",
                    (filterENABLE && currFOLDER.is_virtual) ? searchFILTER : "",
                    archiveFlag
                   )

            return Promise.all(allPromise)
        })        
        .then((allFolderData)=>{
            if(allFolderData)
                setUserObjInCurrFolderINFO(allFolderData)
            else
                setUserObjInCurrFolderINFO([])
            console.log("updata user's objects in folder")

            return new Promise((resolve, reject)=>{
                getUserObjectCountInFolder2(
                    proxy, 
                    {
                        fid:currFOLDER.id,
                        foldertitle:null,
                        order:"DESC",
                        search:(filterENABLE)? searchFILTER: "",
                        archive:archiveFlag
                    },
                    (res)=> {
                        if(res.result === "OK")
                            resolve(res.data)
                        else
                            reject()
                    }
                );
            })
        })
        .then((results)=>{
            const userObjCounts = results
            if(userObjCounts && userObjCounts.length >= 0) {
                const uoc = userObjCounts.map(uc=>{ return {uid:uc.author, count:uc.count}})
                setUserObjectCounts(uoc)
                let validIndex = -1
                userObjCounts.forEach( (u, index) => {
                    if(u && u.author !== '' && validIndex === -1){
                        validIndex = index
                    }
                })                
                if(validIndex >= 0)
                {
                    setMaxUserObjectCount(userObjCounts[validIndex].count)
                }
                else{
                    setMaxUserObjectCount(0)
                }
            }
        })
        .catch(()=>{
            console.log("updata data failed... ")
        })
        .finally(()=>{
            setProgressOpen(false);
        })
    }

    //useEffect(()=>{
    //    if(groupINFO)
    //        getUsersForManyFolders(proxy, searchFILTER, filterENABLE, groupINFO)
    //},[searchFILTER, archiveFlag, dirtyUsersInFolderINFO, dirtyUsersInFolderINFO])
    
    useEffect(()=>{
        updateUserAndObjectData()
    }, [dirtyUsersInFolderINFO, dirtyUserObjInCurrentFolderINFO, currPAGE, currFOLDER, currROWPERPAGE, searchFILTER, archiveFlag])

    useEffect(()=>{
        //////////////////////////////////////
        // added in SEP/15/2020
        // get all users who have one or more document in all folders
        if(groupINFO && groupINFO.length > 0)
            Promise.all(getUsersForManyFolders( proxy, searchFILTER, filterENABLE, groupINFO ))
            .then((results)=>{
                setUsersInAllFolderINFO(results)
            })
    }, [groupINFO, dirtyUsersInFolderINFO, dirtyUserObjInCurrentFolderINFO, searchFILTER, archiveFlag])

    useEffect(()=>{        
        if(userINFO.length>0 && groupINFO.length>0 && mySessionINFO){
            const myuid = mySessionINFO.uid
            const myinfo = userINFO.find(u=>u.id === myuid)
            let mygroup, mygroupfolder
            if(myinfo){
                mygroup = groupINFO.find(g=>g.id === myinfo.gid)
                if(mygroup)
                    setMyGroupINFO(mygroup)
            }
        }
    }, [userINFO, groupINFO, mySessionINFO])
    
    useEffect(()=>{
        if( userINFO && groupINFO && myPermissionINFO && myUserINFO && myGroupINFO
            && userINFO.length>0 && groupINFO.length>0)
        {
            // update my permission set
            const permitGroups = updateMyPermission(myUserINFO.id, myGroupINFO.id, userINFO, groupINFO, myPermissionINFO,
                (myUserINFO.user_level === "admin") && supervisorMode)
            setReadPermissionGROUP(permitGroups.readGroups)
            setWritePermissionGROUP(permitGroups.writeGroups)
        }
    }, [userINFO, groupINFO, myPermissionINFO, myUserINFO, myGroupINFO, supervisorMode])
    
    useEffect(()=>{
        //setDirtyUsersInFolderINFO(!dirtyUsersInFolderINFO)
        //setDirtyUserObjInCurrentFolderINFO(!setDirtyUserObjInCurrentFolderINFO)
    }, [currFOLDER])

    useEffect(()=>{
        console.log("update myPermissionINFO")
    }, [dirtyMyPermissionINFO])
    //useEffect(()=>{
    //    console.log("update userObjInCurrentFolderINFO")
    //}, [dirtyUserObjInCurrentFolderINFO])
    useEffect(()=>{
        //console.log("dirtData", dirtyData)
    }, [dirtyData])


    const handleSnackBarClose = () => setSnackBarOpen(false)
    const addSnackbarMessage = (msg, variant)=>{
        //"default"|"error"|"success"|"warning"|"info"
        setAlertVariant(variant)
        setAlertMessage(msg)
        setSnackBarOpen(true)
    }

    const periodicVerifySession = ()=>{
        verifySession(proxy)
        .then((sess)=>{
            const sessMsg = sess.message
            if(sessMsg && sessMsg.length > 0) {
                sessMsg.forEach((msg)=>{
                    const id = msg.id
                    const param = msg.param

                    const handleMyDocInProgress = {
                        addDocToMyDocInProgress (docId){
                            const idx = myDocInProgress.findIndex(p=>p === docId);
                            if(idx === -1) {
                                myDocInProgress.push(docId)
                            }
                        },
                        removeDocFromMyDocInProgress(docId)  {
                            const idx = myDocInProgress.findIndex(p=>p === docId);
                            if(idx !== -1) {
                                myDocInProgress.splice(idx, 1)
                            }
                        }
                    }

                    switch(id) {
                        case ESessionMessage.SESSMSG_FILEUPLOAD:
                            handleMyDocInProgress.addDocToMyDocInProgress(param);
                            break;
                        case ESessionMessage.SESSMSG_FILEINPROGRESS:
                            addSnackbarMessage(`${param} in progressing`, "info");
                            break;
                        case ESessionMessage.SESSMSG_OBJUPDATE:
                        //addSnackbarMessage(`${param} uploading/processing completed`, "success")
                            refreshINFO(["object"]);
                            break;
                        case ESessionMessage.SESSMSG_FILEUPLOADERROR:
                            addSnackbarMessage(`${param} uploading/processing Failed`, "error")
                            handleMyDocInProgress.removeDocFromMyDocInProgress(param)
                            console.log("message-", JSON.stringify(param))
                            break;
                        case ESessionMessage.SESSMSG_FILEUPLOADCOMPLETE:
                            handleMyDocInProgress.removeDocFromMyDocInProgress(param);
                            if(msg.uid === sess.uid) {
                                addSnackbarMessage(`${param} uploaded successfully`,"success");
                            }
                            refreshINFO(["object"]);
                            break;
                        default:
                            break;
                    }
                    // in any certain cases, need to update data
                })
            }
        })
        .catch((err)=>{
            console.log(err)
            console.log("Session expired...")
            setSessionExpired(true)
        })
    }
    const updateSessionTimeout = ()=>{
        const newCount = timerRef.current + 1
        timerRef.current = newCount
        setSessTimeoutCount(newCount)
        if(newCount > SESSION_TIMEOUT){
            if(!sessionExpired)
                logout()
        } else if((newCount + SESSION_WARNING_COUNT) >= (SESSION_TIMEOUT)){
            setOpenSessionTimeoutWarning(true)
        } else{
            setOpenSessionTimeoutWarning(false)
        }
    }
    const resetSessionTimeout = ()=>{
        //console.log("reset session timeout ", timerRef.current)
        timerRef.current = 0
        setOpenSessionTimeoutWarning(false)        
    }
    const moveToPageDoc=(page)=>{ setCurrPAGE(page) }
    const moveToNextDateDoc=()=>{
        getObjectInfoJustAfter(proxy, 
            {
                uid:currOBJECT.author,
                fid:currFOLDER.id,
                currObjId:currOBJECT.id,
                filter:searchFILTER,
                archive:archiveFlag
            },
            (res)=>{
                if(res.result === "OK" && res.data){
                    const obj = res.data
                    setCurrOBJECT(obj)
                    props.history.push({pathname:'/document', search:`?gid=${currGROUP.id}&docid=${obj.id}`})
                }
            }
        );
    }
    const moveToPrevDateDoc=()=>{
        getObjectInfoJustBefore(proxy, 
            {
                uid:currOBJECT.author,
                fid:currFOLDER.id,
                currObjId:currOBJECT.id,
                filter:searchFILTER,
                archive:archiveFlag
            },
            (res)=>{
                if(res.result === "OK" && res.data){
                    const obj = res.data
                    setCurrOBJECT(obj)
                    props.history.push({pathname:'/document', search:`?gid=${currGROUP.id}&docid=${obj.id}`})
                }
            }
        );
    }
    const setCurrObjectByID=(oid)=>{
        getObjectInfo(proxy, {oid}, (res) =>{
            if(res.result === "OK" && res.data){
                setCurrOBJECT(res.data[0])
            }
        })
    }
    const getCurrObjectById = (oid) => {
        return new Promise((resolve, reject) => {
            getObjectInfo(proxy, {oid}, (res) =>{
                if(res.result === "OK" && res.data){
                    // setCurrOBJECT(res.data[0]);
                    resolve(res.data[0])
                } else {
                    reject()
                }
            })
        })
    }
    const moveToPrevPersonDoc=()=>{
        let currAuthor = currOBJECT.author
        let index = usersInFolderINFO.findIndex((u, index, arr)=> {
            return (u.id === currAuthor)
        })
        if(index > 0 ){
            currAuthor = usersInFolderINFO[index-1].id
        }
        getObjectInfoCloseTime(proxy, 
            {
                uid: currAuthor, //props.currObject.author,
                fid: currFOLDER.id,
                currObjId: currOBJECT.id,
                filter:(filterENABLE)? searchFILTER : null
            },
            (res)=>{
                if(res.result === "OK" && res.data){
                    const obj = res.data
                    setCurrOBJECT(obj)
                    const user = userINFO.find(u=>u.id === obj.author)
                    if(user)
                        setCurrAUTHOR(user)
                    props.history.push({pathname:'/document', search:`?gid=${currGROUP.id}&docid=${obj.id}`})
                }
            }
        );
    }
    const moveToNextPersonDoc=()=>{
        let currAuthor = currOBJECT.author
        let index = usersInFolderINFO.findIndex((u, index, arr)=> {
            return (u.id === currAuthor)
        })
        if(index < usersInFolderINFO.length-1 ){
            currAuthor =usersInFolderINFO[index+1].id
        }
        //let currAuthor:string = props.currObject.author
        getObjectInfoCloseTime(proxy, 
            {
                uid:currAuthor, // props.currObject.author,
                fid:currFOLDER.id,
                currObjId:currOBJECT.id,
                filter:(filterENABLE)? searchFILTER : null
            },
            (res)=>{
                if(res.result === "OK" && res.data){
                    const obj = res.data
                    setCurrOBJECT(obj)
                    const user = userINFO.find(u => u.id === obj.author)
                    if(user)
                        setCurrAUTHOR(user)
                    props.history.push({pathname:'/document', search:`?gid=${currGROUP.id}&docid=${obj.id}`})

                }
            }
        );
    }
    const moveToLatestDoc= ()=>{
        getObjectInfoOldest(proxy, 
            {
                uid:currOBJECT.author,
                fid:currFOLDER.id,
                currObjId:currOBJECT.id,
                filter:searchFILTER
            },
            (res)=>{
                if(res.result === "OK" && res.data){
                    const obj = res.data
                    //props.setCurrObject(obj)
                    setCurrOBJECT(obj)
                    props.history.push({pathname:'/document', search:`?gid=${currGROUP.id}&docid=${obj.id}`})

                }
            }
        );
    }
    const moveToOldestDoc = ()=>{
        getObjectInfoLatest(proxy, 
            {
                uid:currOBJECT.author,
                fid:currFOLDER.id,
                currObjId:currOBJECT.id,
                filter:searchFILTER
            },
            (res)=>{
                if(res.result === "OK" && res.data){
                    const obj = res.data
                    setCurrOBJECT(obj)
                    props.history.push({pathname:'/document', search:`?gid=${currGROUP.id}&docid=${obj.id}`})

                }
            }
        );
    }
    const logout = ()=>{
        setProgressOpen(true)
        const sess = cookies.sess
        signOut(proxy, {sess}, res=>{  
            setProgressOpen(false)
            setCookie('sess', "", {path:'/'})  
            setSessionExpired(true)
            exitFullscreen()
        })
    }
    const openFullscreen = ()=>{ document.documentElement.webkitRequestFullscreen(); }
    const exitFullscreen = ()=>{ document.webkitExitFullscreen(); }
    const onPaperClick = ()=>{ 
        //openFullscreen()
        //setAppbarOpen(!appbarOpen)
        if(appbarInTrigger){
            setAppbarInTrigger(false)
        }
    }

    const onMouseOutOfAppbar = ()=>{
        // if there is no activated menu popup, 
        if(!appbarMenuOn) {
            setAppbarInTrigger(false)
            mouseOutOfAppbarTimer = undefined
        }
    }

    const onMouseMove = (evt)=>{ 
        resetSessionTimeout()
        //console.log("position:", evt.clientX, evt.clientY)

        if(!appbarInTrigger){
            if(evt.pageY < 10 && !appbarInTrigger){
                setAppbarInTrigger(true)
            }
        }
        else{
            if(evt.pageY > appbarHeight){
                if(!mouseOutOfAppbarTimer && !appbarMenuOn)
                    mouseOutOfAppbarTimer = setTimeout(onMouseOutOfAppbar, APPBAR_TIMEOUT)
            }
            else {
                if(mouseOutOfAppbarTimer){
                    clearTimeout(mouseOutOfAppbarTimer)
                    mouseOutOfAppbarTimer = undefined
                }
            }
        }
        //else if(evt.pageY >= 80 && appbarInTrigger){
        //    setAppbarInTrigger(false)
        //}
    }

    const onAppBarMenu = (isOn) =>{
        setAppbarMenuOn(isOn)
        if(isOn && mouseOutOfAppbarTimer){
            clearTimeout(mouseOutOfAppbarTimer)
            mouseOutOfAppbarTimer = undefined
        }
    }

    const onMouseDown = (evt)=>{ resetSessionTimeout() }
    const groupTreeSelectUser = (uid)=>{
        // if current view is document goto the closest date's document of the user
        if(uid){
            const user = userINFO.find(u=>u.id === uid)
            //if(user)
            // find time closest document and goto the document 
            // on for document view 
            if(user){                
                getObjectInfoCloseTime(proxy, 
                    {
                        uid:user.id, // props.currObject.author,
                        fid:currFOLDER.id,
                        currObjId:currOBJECT.id,
                        filter:(filterENABLE)? searchFILTER : null
                    },
                    (res)=>{
                        if(res.result === "OK" && res.data){
                            const obj = res.data
                            setCurrOBJECT(obj)
                            const user = userINFO.find(u=> u.id === uid)                            
                            setCurrAUTHOR(user)
                            props.history.push({pathname:'/document', search:`?gid=${obj.gid}&docid=${obj.id}`})
                        }
                    }
                );
            }
        }
    }
    //AppbarCompsite, AppbarDcouemntView
    const groupTreeSelectGroup = (gid, uid, changeUrl=false)=>{
        if(gid){
            const group = groupINFO.find(g=>g.id === gid)
            if(group) {
                setCurrGROUP(group)
                const folder = folderINFO.find(f=>f.id === group.fid)
                if(folder)
                    setCurrFOLDER(folder)
                if(uid) {
                    const user = userINFO.find(u=>u.id === uid)
                    if(user){         
                        const currObjId = currOBJECT ? currOBJECT.id : new URLSearchParams(window.location.search).get('docid')        
                        getObjectInfoCloseTime(proxy, 
                            {
                                uid:user.id, // props.currObject.author,
                                fid:folder.id,
                                currObjId:currObjId,
                                filter:(filterENABLE)? searchFILTER : null
                            },
                            (res)=>{
                                if(res.result === "OK" && res.data){
                                    debugger;
                                    const obj = res.data
                                    setCurrOBJECT(obj)
                                    setCurrAUTHOR(user)
                                    if(changeUrl){
                                        props.history.push({pathname:'/document', search:`?gid=${group.id}&docid=${obj.id}`})
                                    }
                                }
                            }
                        );
                    }
                }
            }
        }
    }
    const onTreeItemClick = (id)=>{
        //if(id && id[0] === 'G'){
            // group selected => change current group
       // } else if(id && id[0] === 'U'){
            // user selected => change current object as closed time rule
       // }
    }
    // gid, uid 의 current Document 와 가장 가까운 날짜의 document 로 이동
    const gotoUserDocument = (group, user)=>{
        const folder = folderINFO.find(f=> f.id === group.fid)
        if(folder){
            setCurrGROUP(group)
            setCurrFOLDER(folder)
            setCurrAUTHOR(user.id)

            //let currAuthor:string = props.currObject.author
            getObjectInfoCloseTime(proxy, 
                {
                    uid:user.id, // props.currObject.author,
                    fid:currFOLDER.id,
                    currObjId:currOBJECT.id,
                    filter:(filterENABLE)? searchFILTER : null
                },
                (res)=>{
                    if(res.result === "OK" && res.data){
                        const obj = res.data
                        setCurrOBJECT(obj)
                        const user = userINFO.find(obj.author)
                        if(user)
                            setCurrAUTHOR(user)
                    }
                }
            );
        }
    }
    const onDocumentSelect = (doc, fullscreen=true)=>{
        setCurrOBJECT(doc)
        const user = userINFO.find(u=>u.id === doc.author)
        if(user)
            setCurrAUTHOR(user)
         
        props.history.push({pathname:'/document', search:`?gid=${currGROUP.id}&docid=${doc.id}`})

        if(fullscreen)
            openFullscreen()
    }
    const refreshINFO = (fields)=>{
        //const [dirtyUserINFO, setDirtyUserINFO] = useState(false)
        //const [dirtyFolderINFO, setDirtyFolderINFO] = useState(false)
        //const [dirtyGroupINFO, setDirtyGroupINFO] = useState(false)
        //const [dirtyMyPermissionINFO, setDirtyMyPermissionINFO] = useState(false)
        //const [dirtyPermissionINFO, setDirtyPermissionINFO] = useState(false)
        //const [dirtyUsersInFolderINFO, setDirtyUsersInFolderINFO] = useState(false)
        //const [dirtyUserObjInCurrentFolderINFO, setDirtyUserObjInCurrentFolderINFO] = useState(false)    
        fields.forEach((field)=>{
            if(field === "user") setDirtyUserINFO(dirtyUserINFO+1)
            else if(field === "group") setDirtyGroupINFO(dirtyGroupINFO+1)
            else if(field === "folder") setDirtyFolderINFO(dirtyFolderINFO+1)
            else if(field === "permission") setDirtyPermissionINFO(dirtyPermissionINFO+1)
            else if(field === "userinfolder") setDirtyUsersInFolderINFO(dirtyUsersInFolderINFO+1)
            else if(field === "object")  //updateUserAndObjectData()
            {
                setDirtyUserObjInCurrentFolderINFO((new Date()).getTime())
            }
        })
    }
    
    const handleDocToArchive = (obj)=>{        
        obj.archive = !obj.archive
        modifyObjectInfo(proxy, obj, (res)=>{
            // update data
            refreshINFO(["object"])
        })
    }
    const topRef = useRef()
    const gotoFolderRef = useRef()
    const gotoDocumentRef = useRef()
    const gotoMyProfileRef = useRef()
    const gotoMyDocumentsRef = useRef()
    const gotoPermissionAdminRef = useRef()
    const gotoGroupAdminRef = useRef()
    const gotoDocumentAdminRef = useRef()
    const gotoSignIdAdminRef = useRef()

    const gotoLink = (viewName)=>{         
        //return document.querySelector(`#${(viewName==="root")?"folder":viewName}`)?.click()    
        if(viewName === "document"){

            
        } else if(viewName === "folder")
            gotoFolderRef.current.click()
    }

    const setInitMyDocuments = () => {
        const myFID = myUserINFO?.fid
        const myFolder = folderINFO.find(f => f.id === myFID)
        if (myFolder) {
            setCurrFOLDER(myFolder)
        }
    }

    //SET STATES WHEN refreshing document view
    const setInitDocumentViewState = (gid, documentObj) => {
        if (!gid || !documentObj || !Object.keys(documentObj).length) {
            return;
        } else {
            //setCurrOBJECT(documentObj)
            const uid = documentObj.author;
            groupTreeSelectGroup(gid, uid, false);
        }
    }

    if(sessionExpired){
        return <Redirect to={{pathname:'/', state:""}} />;
    } else {
        return (
            <div id="topelement" ref={topRef} onMouseMove={onMouseMove} onMouseDown={onMouseDown}>
                {redirectState ? <Redirect to={{pathname:'/', state:""}} /> : null}
                <CssBaseline />
                <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackBarClose}>
                    <Alert onClose={handleSnackBarClose} severity={`${alertVariant}`}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
                <Backdrop className={classes.backdrop} open={progressOpen} onClick={()=>setProgressOpen(false)} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Backdrop className={classes.backdrop} open={openSessionTimeoutWarning} 
                        onClick={()=>setOpenSessionTimeoutWarning(false)}>
                    <Typography variant="h3">Sedonds to logout : {SESSION_TIMEOUT-sessTimeoutCount} </Typography>
                </Backdrop>
                <div className={classes.hide}>
                    <Link ref={gotoFolderRef} to="/folder"></Link>
                    <Link ref={gotoDocumentRef} to="/document"></Link>
                    <Link ref={gotoMyProfileRef} to="/myprofile"></Link>
                    <Link ref={gotoMyDocumentsRef}  to="/mydocuments"></Link>
                    <Link ref={gotoPermissionAdminRef}  to="/permissionadmin"></Link>
                    <Link ref={gotoGroupAdminRef} to="/groupadmin"></Link>                
                    <Link ref={gotoDocumentAdminRef} to="/documentadmin"></Link>                
                    <Link ref={gotoSignIdAdminRef} to="/"></Link>
                </div>
                <div id="navButtonSet" className={classes.hide}>
                    <button onClick={()=>gotoFolderRef.current.click()}>Goto Folder</button>
                    <button onClick={()=>gotoDocumentRef.current.click()}>Goto Document</button>
                    <button onClick={()=>gotoMyProfileRef.current.click()}>Goto MyProfile</button>
                    <button onClick={()=>gotoMyDocumentsRef.current.click()}>Goto MyDocuments</button>
                    <button onClick={()=>gotoPermissionAdminRef.current.click()}>Goto PermissionAdmin</button>
                    <button onClick={()=>gotoGroupAdminRef.current.click()}>Goto GroupAdmin</button>
                    <button onClick={()=>gotoDocumentAdminRef.current.click()}>Goto DocumentAdmin</button>
                    <button onClick={()=>setDirtyData(dirtyData+1)}>All Data Fetch</button>                
                    <button onClick={logout}>Log Out</button>
                </div>
                <Paper style={{marginTop:(viewName === "mydocuments")?70:0}}
                    className={clsx([(appbarOpen)? (classes.showBlock, classes.appBar): classes.hide])} >
                    <Switch>
                        <Route path={`/folder`}
                            render={
                                ()=><AppBarComposite
                                        proxy={proxy}
                                        //ref={appbarRef}
                                        userINFO={userINFO}
                                        groupINFO={groupINFO}
                                        currGROUP={currGROUP}
                                        currFOLDER={currFOLDER}
                                        currOBJECT={currOBJECT}
                                        myUserINFO={myUserINFO}
                                        myGroupINFO={myGroupINFO}
                                        permissionINFO={readPermissionGROUP}
                                        isLimitPermit={true}
                                        currPAGE={currPAGE}
                                        currROWPERPAGE={currROWPERPAGE}
                                        setCurrPAGE={setCurrPAGE}
                                        position={"static"}
                                        drawerOpen={drawerOpen}
                                        setDrawerOpen={setDrawerOpen}
                                        onTreeItemClick={onTreeItemClick}
                                        archiveFlag={archiveFlag}
                                        setArchiveFlag={setArchiveFlag}
                                        previewMode={previewMode}
                                        setPreviewMode={setPreviewMode}
                                        title={currGROUP?.path}
                                        groupTreeSelectUser={groupTreeSelectUser}
                                        groupTreeSelectGroup={groupTreeSelectGroup}
                                                                        
                                        gotoFolderButton={ (viewName === "document")?true:false }
                                        showObjTitle={ (viewName === "document")?true:false }
                                        showViewModeToggle={ (viewName === "folder")?true:false }
                                        showArchiveToggle={ (viewName === "folder" && myUserINFO?.user_level === "admin"
                                            && supervisorMode)?true:false }
                                        showPagination={ (viewName === "folder")?true:false }
                                        searchFilter={searchFILTER}
                                        setSearchFilter={setSearchFILTER}
                                        handleSignOut={logout}
                                        maxItemCount={maxUserObjectCount}
                                        drawerOpenButton = {true}
                                        searchDisable={false}
                                        currView = {viewName}
                                        treeUserNodeEnable = {false}
                                        groupUserOnlyTree={true}
                                        refreshINFO={refreshINFO}
                                        supervisorMode={supervisorMode}
                                        setSupervisorMode={setSupervisorMode}
                                    />
                            }>
                        </Route>
                        <Route path={`/document`} 
                            render={
                                ()=>                                
                                <Collapse in={appbarInTrigger}>
                                    <AppBarDocumentView
                                        proxy={proxy}
                                        userINFO={userINFO}
                                        groupINFO={groupINFO}   
                                        currOBJECT={currOBJECT}
                                        currGROUP={currGROUP}
                                        currFOLDER={currFOLDER}
                                        currAUTHOR={currAUTHOR}
                                        currPAGE={currPAGE}
                                        currROWPERPAGE={currROWPERPAGE}
                                        gotoUserDocument={gotoUserDocument}
                                        myUserINFO={myUserINFO}       
                                        usersInAllFolderINFO={usersInAllFolderINFO}       
                                        currGROUP={currGROUP}
                                        permissionINFO={readPermissionGROUP}
                                        refreshINFO={refreshINFO}
                                        appbarInTrigger={appbarInTrigger}

                                        setCurrPAGE={setCurrPAGE}
                                        userObjectCounts={userObjectCounts}
                                        userObjInCurrFolderINFO={userObjInCurrFolderINFO}
                                        currUserOBJECT={currUserOBJECT}
                                        setCurrObjectByID={setCurrObjectByID}

                                        position={"absolute"}
                                        drawerOpen={drawerOpen}
                                        title={currFOLDER && currFOLDER.is_virtual ?  currGROUP?.path : myGroupINFO?.path}
                                        groupTreeSelectUser={groupTreeSelectUser}
                                        groupTreeSelectGroup={groupTreeSelectGroup}
                                        gotoFolderButton={ (viewName === "document")?true:false }
                                        showObjTitle={ (viewName === "document")?true:false }
                                        currView = {viewName}
                                        usersInFolder={usersInFolderINFO}
                                        searchFILTER={searchFILTER}
                                        handleSignOut={logout}
                                        supervisorMode={supervisorMode}
                                        setSupervisorMode={setSupervisorMode}
                                        onAppBarMenu={onAppBarMenu}
                                    />
                                </Collapse>}
                            >
                        </Route>
                        <Route path={`/mydocuments`} 
                            render={ 
                                ()=>
                                <AppBarMyDocument
                                    proxy={proxy}
                                    //ref={appbarRef}
                                    userINFO={userINFO}
                                    groupINFO={groupINFO}
                                    currGROUP={currGROUP}
                                    currFOLDER={currFOLDER}
                                    currOBJECT={currOBJECT}
                                    myUserINFO={myUserINFO}
                                    myGroupINFO={myGroupINFO}
                                    title={"Simple"}
                                    path={"grouppath/user/doctitle"}
                                    viewFlag={{groupTree:true, groupTreeUser:true}}
                                    groupTreeSelectUser={groupTreeSelectUser}
                                    groupTreeSelectGroup={groupTreeSelectGroup}
                                    position={"static"}
                                    drawerOpen={drawerOpen}
                                    setDrawerOpen={setDrawerOpen}
                                    maxItemCount={maxUserObjectCount}
                                    currPAGE={currPAGE}
                                    setCurrPAGE={setCurrPAGE}
                                    currROWPERPAGE={currROWPERPAGE}
                                    handleSignOut={logout}
                                    supervisorMode={supervisorMode}
                                />
                            }>
                        </Route>
                        <Route path={`/myprofile`}
                            render={
                                ()=><AppBarSimple
                                        title={`${myUserINFO?.name} User Profile`}
                                        myUserINFO={myUserINFO}
                                        handleSignOut={logout}
                                        supervisorMode={supervisorMode}
                                    />
                            }>
                        </Route>
                        <Route path={`/userinfoadmin`}
                            render={
                                ()=><AppBarSimple
                                        title={"User Management"}
                                        myUserINFO={myUserINFO}
                                        handleSignOut={logout}
                                        supervisorMode={supervisorMode}
                                    />
                            }>
                        </Route>
                        <Route path={`/documentadmin`}
                            render={
                                ()=><AppBarSimple
                                        title={"Document Management"}
                                        myUserINFO={myUserINFO}
                                        handleSignOut={logout}
                                        supervisorMode={supervisorMode}
                                    />
                            }>
                        </Route>
                        <Route path={`/groupadmin`}
                            render={
                                ()=><AppBarSimple
                                        title={"Group Management"}
                                        myUserINFO={myUserINFO}
                                        handleSignOut={logout}
                                        supervisorMode={supervisorMode}
                                    />
                            }>
                        </Route>
                        <Route path={`/permissionadmin`}
                            render={
                                ()=><AppBarSimple
                                        title={"Permission Management"}
                                        myUserINFO={myUserINFO}
                                        handleSignOut={logout}
                                        supervisorMode={supervisorMode}
                                    />
                            }>
                        </Route>
                    </Switch>
                </Paper>
                        
                <Paper 
                    onClick={onPaperClick}
                    className={
                        clsx(classes.rootPaper, 
                            [(drawerOpen)? classes.withDrawer:classes.withoutDrawer,
                             (appbarOpen && viewName !== "document")? classes.withAppBar:classes.withoutAppBar])
                    }>
                    <Switch>
                        <Route path={`/document`}
                            render={
                                ()=><DocumentView
                                        proxy={proxy}
                                        userObjInCurrFolderINFO={userObjInCurrFolderINFO}
                                        currGROUP={currGROUP}
                                        currFOLDER={currFOLDER}
                                        currOBJECT={currOBJECT}
                                        userINFO={userINFO}
                                        groupINFO={groupINFO}
                                        currPAGE={currPAGE}
                                        marginLeft={0}
                                        getCurrObjectById={getCurrObjectById}
                                        setInitDocumentViewState={setInitDocumentViewState}
                                        moveToLatestDoc={moveToLatestDoc}
                                        moveToOldestDoc={moveToOldestDoc}
                                        moveToPageDoc={moveToPageDoc}
                                        moveToNextDateDoc={moveToNextDateDoc}
                                        moveToPrevDateDoc={moveToPrevDateDoc}
                                        moveToNextPersonDoc={moveToNextPersonDoc}
                                        moveToPrevPersonDoc={moveToPrevPersonDoc}
                                    />
                            }
                        />
                        <Route path={`/folder`}
                            render={
                                ()=><FolderView
                                        proxy={proxy}
                                        userINFO={userINFO}
                                        myUserINFO={myUserINFO}
                                        userObjInCurrFolderINFO={userObjInCurrFolderINFO}
                                        currFOLDER={currFOLDER}
                                        currGROUP={currGROUP}
                                        currOBJECT={currOBJECT}
                                        currPAGE={currPAGE}
                                        onDocumentSelect={onDocumentSelect}
                                        previewMode={previewMode}
                                        supervisorMode={supervisorMode}
                                        handleDocToArchive={handleDocToArchive}
                                        refreshINFO={refreshINFO}
                                        groupINFO={groupINFO}
                                        writePermissionGROUP={writePermissionGROUP}
                                    />}
                        />
                        <Route path={`/myprofile`}
                            render={
                                ()=><MyProfile
                                        proxy={proxy}
                                        groupINFO={groupINFO}
                                        myUserINFO={myUserINFO}
                                        currGROUP={currGROUP}
                                        refreshINFO={refreshINFO}
                                    />}
                        />
                        <Route path={`/mydocuments`} 
                            render={
                                ()=><MyDocuments
                                        proxy={proxy}
                                        userObjInCurrFolderINFO={userObjInCurrFolderINFO}
                                        groupINFO={groupINFO}
                                        userINFO={userINFO}
                                        myUserINFO={myUserINFO}
                                        currFOLDER={currFOLDER}
                                        maxUserObjectCount={maxUserObjectCount}
                                        writePermissionGROUP={writePermissionGROUP}
                                        refreshINFO={refreshINFO}
                                        onDocumentSelect={onDocumentSelect}
                                        myDocInProgress={myDocInProgress}
                                        setInitMyDocuments={setInitMyDocuments}

                                        />}
                        />
                        <Route path={`/permissionadmin`}
                            render={()=><PermissionAdmin/>}/>
                        <Route path={`/groupadmin`}
                            render={
                                ()=><GroupAdmin
                                    proxy={proxy}
                                    groupINFO={groupINFO}
                                    userINFO={userINFO}
                                    folderINFO={folderINFO}
                                    userNodeEnable={false}
                                    modifyGroupInfo={modifyGroupInfo}                                    
                                    refreshINFO={refreshINFO}
                                />}
                        />
                        <Route path={`/documentadmin`}
                            render={()=><DocumentAdmin/>}/>
                        <Route path={`/userinfoadmin`}
                            render={
                                ()=><UserInfoAdmin
                                        proxy={proxy}
                                        userData={userINFO}
                                        groupTreeData={groupINFO}
                                        marginTop={0}
                                        marginLeft={0}
                                        drawerOpen={false}
                                        setUserInfo={undefined}
                                        refreshINFO={refreshINFO}
                                    />
                                }/>
                    </Switch>
                </Paper>
            </div>
        )
    }
}

export default withCookies(Root);
