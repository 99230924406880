import React, { useEffect, useState, useRef} from 'react';
import { ListItemText, makeStyles } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import { IconButton } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'

import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import GridOnIcon from '@material-ui/icons/GridOn';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { MenuList, MenuItem } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import TablePagination from '@material-ui/core/TablePagination';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';

import {  BrowserRouter as Router, Route,
          useRouteMatch, Switch, Link, useHistory, useLocation}  from 'react-router-dom'


const useStyles = makeStyles((theme)=>({    
    appBar: {
        height:"inherit",
        //backgroundColor: "grey",
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width:"100%",
        position:"relative",
        zIndex: 100,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display:"none"
    },
    groupButton: {
        color:"white",
        margin: 3,
        maxWidth: 350,
        width:350
    },
    supervisorMode:{
        backgroundColor: "gray"
    }
}))
        
export default function AppBarMyDocument(props){
    const classes = useStyles()
    const {    
        userINFO,
        groupINFO,
        currGROUP,
        currFOLDER,
        currOBJECT,
        myUserINFO,
        myGroupINFO,
        title,
        path,
        viewFlag,
        groupTreeSelectUser,
        groupTreeSelectGroup,
        position,
        drawerOpen,
        setDrawerOpen,
        handleSignOut,
        maxItemCount,
        currPAGE,
        setCurrPAGE,
        currROWPERPAGE,
        supervisorMode,
    } = props

    const [userMenuOpen, setUserMenuOpen] = useState(false)


    const handleGroupTreeOpen=()=>{}
    const handleCloseUserMenu = ()=>setUserMenuOpen(false)
    const handleUserMenuToggle = ()=>setUserMenuOpen(!userMenuOpen)
    
    const anchorUserMenuRef = useRef()
    
    return (
        <div>

            <AppBar color="primary" >
            <Toolbar color="primary" className={ clsx(classes.appBar, {[classes.supervisorMode]:Boolean(supervisorMode)})} >
                <IconButton color="inherit" aria-label="goto folder" 
                        component={Link} to="/folder" 
                        edge="start"
                        className={clsx(classes.menuButton)} >
                    <GridOnIcon />
                </IconButton>

                <PersonIcon /> 
                <Typography noWrap={true} style={{color:"white"}} variant="subtitle1">
                    {myGroupINFO?.path}{'>'}{myUserINFO?.name}
                </Typography>
         
                {
                    <TablePagination
                        style={{color:"white"}}
                        component="div"
                        labelRowsPerPage=""
                        rowsPerPageOptions={[]}
                        count={maxItemCount}
                        page={currPAGE}
                        onChangePage={(e, newPage)=>setCurrPAGE(newPage)}
                        rowsPerPage={currROWPERPAGE}
                    />
                }                
                <Button
                    ref={anchorUserMenuRef}
                    style={{position:"absolute", right:50, backgroundColor:"gray", color:"white"}}
                    aria-controls={userMenuOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleUserMenuToggle}
                    endIcon={<AccountCircleIcon></AccountCircleIcon>}
                >
                    {myUserINFO?.name}
                </Button>
                <Popper open={userMenuOpen} anchorEl={anchorUserMenuRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper style={{zIndex:100}}>
                                <ClickAwayListener onClickAway={handleCloseUserMenu}>
                                    <MenuList autoFocusItem={userMenuOpen} id="menu-list-grow">
                                        <MenuItem component={Link} to= "/folder">
                                            Documents Home
                                        </MenuItem>
                                        <MenuItem component={Link} to= "/myprofile">
                                            My Profile
                                        </MenuItem>
                                        <MenuItem component={Link} to= "/mydocuments">
                                            <ListItemText primary="My Documents" secondary="(문서업로드)" />
                                        </MenuItem>
                                        <MenuItem onClick={()=>handleSignOut()}>Logout</MenuItem>
                                        {
                                            ( myUserINFO?.user_level === "admin" ) ?
                                            (
                                                <div>
                                                    <Divider />
                                                    <MenuItem component={Link} to= "/groupadmin">
                                                        Admin Group
                                                    </MenuItem>                                                    {/*<MenuItem onClick={()=>onMenuClick(gotoPermissionAdminRef)}>Admin Permission</MenuItem>*/}
                                                    <MenuItem component={Link} to= "/userinfoadmin">
                                                        Admin User
                                                    </MenuItem>                                                    {/*<MenuItem onClick={()=>onMenuClick(gotoDocumentsAdminRef)}>Admin Documents</MenuItem>*/}
                                                </div>
                                            )
                                            :null
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Toolbar>
            </AppBar>
        </div>
    )
}
