import React, { useEffect, useState } from 'react';
//import ReactDOM from 'react-dom';
import {  BrowserRouter as Router, Route,
          useRouteMatch, Switch, Link, useHistory, useLocation}  from 'react-router-dom'

import { addNewGroup } from "./api/proxyAddNewGroup"

import { makeStyles } from '@material-ui/core/styles';
import { useTheme, Theme } from '@material-ui/core/styles';
import GroupTreeView from './GroupTree2'
import { modifyUserInfo } from './api/proxyModifyUserInfo';
import { modifyGroupInfo } from "./api/proxyModifyGroupInfo"
import { deleteGroup } from "./api/proxyDeleteGroup"
import SaveIcon from '@material-ui/icons/Save'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'

import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    profileToolbar:{

    },
    userProfileEdit:{
        //display:"block",
        height:400,
        overflow:"auto"
    },
    groupProfileGroupUsers:{
        height:500,
        overflow:"auto"
    }
}))
export function GroupProfileUsers(props)
{
    const classes = makeStyles()
    const {
        proxy,
        groupData,   // current selected group data
        groupINFO,
        userINFO,
        refreshINFO
    } = props

    const [groupTitle, setGroupTitle] = useState("")
    const [groupName, setGroupName] = useState("")
    const [currentGroupUsers, setCurrentGroupUsers] = useState([])
    const [currentNonGroupUsers, setNonCurrentGroupUsers] = useState([])
    const [update, setUpdate]=useState(false)

    useEffect(()=>{
        setGroupTitle(groupData?.title)
        setGroupName(groupData?.name)
    }, [groupData])

    useEffect(()=>{
        if(userINFO && groupData) {
            const users = []
            const nUsers = []
            userINFO.forEach(u=>{
                if(u.gid === groupData.id)
                    users.push(u);
                else
                    nUsers.push(u)
            })
            if(users.length > 0){
                // sort by user order in the group information
            }
            const gu = groupData.users
            if(gu.length > 0){
                users.sort((a, b)=>(
                    gu.indexOf(a.id) - gu.indexOf(b.id)
                ))
            }
            nUsers.sort((a,b)=> a.name > b.name? 1 : -1)
            setCurrentGroupUsers(users);
            setNonCurrentGroupUsers(nUsers);
        }
    }, [userINFO, groupINFO, groupData])

    const onGroupTitleChange = (value)=>{ setGroupTitle(value) }

    useEffect(()=>{
    },[currentGroupUsers])

    const handleAddUserToGroup = (user)=>{ 
        const index = currentNonGroupUsers.findIndex((c)=> c.id === user.id)
        if(index !== -1){
            user.gid = groupData.id
            // update user data
            modifyUserInfo(proxy, user, (result)=>{
                refreshINFO(["user"])
            })

            let users = []
            currentGroupUsers.push(user)
            if(currentGroupUsers.length > 0)
                users = currentGroupUsers.map(u=>u.id)

            groupData.users = users.toString()
            modifyGroupInfo(proxy, groupData, (result=>{
                refreshINFO(["group"])
            }))

            currentNonGroupUsers.splice(index, 1)            
            setCurrentGroupUsers(currentGroupUsers)
            setNonCurrentGroupUsers(currentNonGroupUsers)
            setUpdate(!update);
        }
    }
    const handleRemoveUserFromGroup = (user)=> { 
        const index = currentGroupUsers.findIndex((c)=> c.id === user.id)
        if(index !== -1){
            user.gid = ""
            // update user data
            modifyUserInfo(proxy, user, (result)=>{
                refreshINFO(["user"])
            })
            

            let index = currentGroupUsers.findIndex(cu=> cu.id === user.id)
            if(index !== -1)
                currentGroupUsers.splice(index, 1)

            let users = []
            users = currentGroupUsers.map(u=>u.id)
            groupData.users = users.toString()
            modifyGroupInfo(proxy, groupData, (result=>{
                refreshINFO(["group"])
            }))

            currentNonGroupUsers.push(user)
            setCurrentGroupUsers(currentGroupUsers)
            setNonCurrentGroupUsers(currentNonGroupUsers)
            setUpdate(!update);
        }
    }

    const onUserItemDragStart= (e, row)=>{
        console.log("onDragStart:", row.id, e.clientY);
        e.dataTransfer.setData("src",row.id);
    }
    const onUserItemDrag= (e, row)=>{}
    const onUserItemDragEnd=(e, row)=>{}
    const onUserItemDragEnter=(e, row)=>{}
    const onUserItemDragOver=(e, row)=>{
        //console.log("onDragOver:", uid, evt.pageX - evt.currentTarget.getClientRects()[0].x,
        //    evt.pageY - evt.currentTarget.getClientRects()[0].y)        
        e.stopPropagation()
        e.preventDefault()
    }
    const onUserItemDragLeave=(e, row)=>{}
    const onUserItemDrop=(e, row)=>{        
        const src = e.dataTransfer?.getData("src")
        const targetIndex = currentGroupUsers.findIndex(d => d.id ===row.id)
        const sourceIndex = currentGroupUsers.findIndex(d => d.id ===src)
        currentGroupUsers.splice(targetIndex, 0, currentGroupUsers.splice(sourceIndex, 1)[0])
        
        setCurrentGroupUsers(Array.from(currentGroupUsers))

        let users = ""
        if(currentGroupUsers.length > 0)
            users = currentGroupUsers.map(u=>u.id)
        groupData.users = users.toString()
        modifyGroupInfo(proxy, groupData, (result=>{
            refreshINFO(["group"])
        }))
    }
    return(
            <Grid container >
                <Typography variant="h6" style={{display:"none"}}>update{(update)?"true":"false"}</Typography>
                <Grid item xs={12}>
                    <Typography variant="h6" >Group Users</Typography>
                </Grid>
                <Grid item xs={6}>
                    <div 
                        style={{
                            height:`calc(100vh - 400px)`,
                            overflow:"auto"
                        }}
                    >
                    <Table size="small" className={classes.userProfileEdit} >
                        <TableBody className={classes.userTableBodyProfileEdit}>                    
                        {(currentGroupUsers).map((row) => (
                            <TableRow
                                key={row?.id}
                                draggable
                                onDragStart = {(e)=>onUserItemDragStart(e, row)}
                                onDrag = {(e)=>onUserItemDrag(e, row)}
                                onDragEnd = {(e)=>onUserItemDragEnd(e, row)}

                                onDragEnter = {(e)=>onUserItemDragEnter(e, row)}
                                onDragOver = {(e)=>onUserItemDragOver(e, row)}
                                onDragLeave = {(e)=>onUserItemDragLeave(e, row)}
                                onDrop = {(e)=>onUserItemDrop(e, row)}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography variant="subtitle1">{`${row.name}(${row.login_id})`}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <IconButton size="small" aria-label="edit group information" 
                                        onClick={()=>handleRemoveUserFromGroup(row)}
                                    >
                                        <Tooltip className={classes.profileToolbar}
                                            title={<Typography variant="h6">Remove user from group</Typography>}>
                                                <ArrowForwardIcon />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))} 

                        <TableRow key={"head"}
                            draggable
                            onDragEnter = {(e)=>onUserItemDragEnter(e, "head")}
                            onDragOver = {(e)=>onUserItemDragOver(e, "head")}
                            onDragLeave = {(e)=>onUserItemDragLeave(e, "head")}
                            onDrop = {(e)=>onUserItemDrop(e, "head")}
                        >     
                            <TableCell component="th" scope="row">
                            </TableCell>
                            <TableCell component="th" scope="row">
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
                    </div>
                </Grid>      
                <Grid item xs={6} >
                    
                    <div 
                        style={{
                            height:`calc(100vh - 400px)`,
                            overflow:"auto"
                        }}
                    >
                        <Table size="small" >
                            <TableBody  style={{height:500, overflowY: "auto"}} >
                            {(currentNonGroupUsers).map((row) => (
                                <TableRow key={row.id} >
                                    <TableCell component="th" scope="row">
                                        <IconButton size="small" aria-label="edit group information" 
                                                onClick={()=>handleAddUserToGroup(row) }
                                            >
                                            <Tooltip className={classes.profileToolbar}
                                                title={<Typography variant="h6">Add user to group</Typography>}>
                                                    <ArrowBackIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </TableCell>

                                    <TableCell component="th" scope="row" >
                                            <Tooltip className={classes.profileToolbar}
                                                title={
                                                    <Typography variant="h6">
                                                    {(groupINFO && row.gid)? groupINFO.find(g=>g.id === row.gid)?.path : null}
                                                    </Typography>}>
                                        <Typography variant="subtitle1" style={{color:(!row.gid || row.gid ==="")?"black":"gray"}}>
                                            {`${row.name}(${row.login_id})`}
                                        </Typography>
                                            </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
                    
                </Grid>
            </Grid>
    )
}

export function GroupProfile(props)
{
    const classes = makeStyles()
    const {
        proxy,
        groupData,   // current selected group data
        groupINFO,
        userINFO, 
        refreshINFO,
        setSelectedGroup //for updating after delete
    } = props

    const [groupTitle, setGroupTitle] = useState("")
    const [groupTitleCopy, setGroupTitleCopy] = useState("")
    const [groupName, setGroupName] = useState("")
    const [groupNameCopy, setGroupNameCopy] = useState("")
    const [groupManager, setGroupManager] = useState("")
    const [groupManagerInput, setGroupManagerInput] = useState("")
    const [openAlert, setOpenAlert] = useState(false)
    useEffect(()=>{
        setGroupTitle(groupData?.title)
        setGroupTitleCopy(groupData?.title)
        setGroupName(groupData?.name)
        setGroupNameCopy(groupData?.name)
    }, [groupData, groupINFO])

    useEffect(()=>{
        if(userINFO && groupData) {
            const manager = userINFO.find(u=> u.id === groupData.manager)
            if(manager) {
                setGroupManager(manager)
                setGroupManagerInput(`${manager.name}(${manager.login_id})`)
            } else {
                setGroupManager(undefined)
                setGroupManagerInput("")
            }
        }
    },[userINFO, groupData])

    const handleSaveProfile = ()=>{
        groupData.name = groupNameCopy
        groupData.title = groupTitleCopy
        groupData.manager = (groupManager)? groupManager.id : null
        modifyGroupInfo(proxy, groupData, (result=>{
            refreshINFO(["group"])
        }))
    }
    const handleAddNewGroup = ()=>{
        addNewGroup(proxy, {name:`newgroup${(new Date()).getSeconds()}.${(new Date()).getMilliseconds()}`, title:"NonameGroup"}, (info)=>{
            refreshINFO(["group"])
        })
    }

    const onGroupNameChange = (value)=>{
        setGroupNameCopy(value)
    }
    const onGroupTitleChange = (value)=>{
        setGroupTitleCopy(value)
    }

    const onGroupManagerSelect=(evt, value)=>{
        // console.log("manager :", value?.id)
        setGroupManager(userINFO.find(u=>u.id === value?.id))
    }

    const onGroupManagerInputChange = (e, value) => {
        setGroupManagerInput(value)
    } 

    const handleCloseAlert = () => {
        setOpenAlert(false)
    }

    const getGroupMangerOptions = () => {
        const groupMangerOptions = [];
        for (let u of userINFO) {
            if(u.disabled) {
                continue;
            }
            groupMangerOptions.push({name:`${u.name}(${u.login_id})`, id:u.id})
        }
        console.log(getGroupMangerOptions)
        return groupMangerOptions;
    }

    //TODO: show user the result of deleting group 
    const handleDeleteGroup = () => {
        try {
            const gid = groupData.id
            deleteGroup(proxy, { gid }, (response => {
                if(response.result === "OK") {
                    refreshINFO(["group"])
                    refreshINFO(["user"])
                    setSelectedGroup()
                }
            }))
            handleCloseAlert()
            
        } catch (err) {
            console.log(err)
        }
    }

    const options  = getGroupMangerOptions()
    return(
        <React.Fragment>
        <div style={{margin:16}}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="h6" >Group Profile</Typography>
                </Grid>
                <Grid item xs={6}>
                    <IconButton disabled={ groupTitle === groupTitleCopy && 
                            (groupManager?.id === groupData?.manager || (!groupManager&&!groupData?.manager)) 
                            && (groupName === groupNameCopy)}
                        aria-label="edit group information" onClick={()=>handleSaveProfile()}>
                        <Tooltip className={classes.profileToolbar}
                            title={<Typography variant="h5">Save Change</Typography>}>
                                <SaveIcon />
                        </Tooltip>
                    </IconButton>                    
                    <IconButton disabled={ groupTitle !== groupTitleCopy } aria-label="edit group information" onClick={()=>handleAddNewGroup()}>
                        <Tooltip className={classes.profileToolbar}
                            title={<Typography variant="h5">Add New User Group</Typography>}>
                                <GroupAddIcon />
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        aria-label="edit group information"
                        onClick={()=>setOpenAlert(true)}
                    >
                        <Tooltip className={classes.profileToolbar}
                            title={<Typography variant="h5">Delete the Group</Typography>}>
                                <DeleteIcon />
                        </Tooltip>
                    </IconButton>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="groupname"
                        name="name"
                        onChange={e=>onGroupNameChange(e.target.value)}
                        value={ groupNameCopy }
                        label={ <Typography color={(groupNameCopy != groupName) ? "error":"primary"}
                                variant="subtitle1">Name</Typography> }
                        fullWidth
                    />                
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="grouptitle"
                        name="title"
                        onChange={e=>onGroupTitleChange(e.target.value)}
                        value={groupTitleCopy}
                        label={ <Typography color={(groupTitle !== groupTitleCopy)?"error":"primary"} 
                                variant="subtitle1">Title (Display Name)</Typography> }
                        fullWidth
                    />                
                </Grid>
                
                <Grid item xs={6}>
                    <Autocomplete
                        options={options}
                        getOptionLabel={(option) => option.name}
                        id="Group Manager"
                        disableCloseOnSelect
                        onChange={onGroupManagerSelect}
                        inputValue={groupManagerInput}
                        onInputChange={onGroupManagerInputChange}
                        renderInput={(params) => <TextField {...params} label="Group Manager" margin="normal" />}
                    />
                </Grid>
            </Grid>
        </div>
            <Dialog
                open={openAlert}
                onClose={handleCloseAlert}
            >

                <DialogTitle id="alert-dialog-title">
                    {"그룹을 삭제하시겠습니까?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        그룹을 삭제하는 경우, 해당 그룹에 공유한 문서의 기록 역시 삭제됩니다.
                        (원본은 삭제하지 않습니다.) 그룹을 삭제하시려면 OK 버튼을 클릭하시기 바랍니다.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseAlert}
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteGroup}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
