import { EResult } from "../common"

/*
export type FileUploadParamType= {
    data:FormData,
    onProgress:(event:string, param:any)=>void
}
    'author':author,
    'pfid':personalFId,
    'vfid':vertualFId,
    'title':title,
*/

/**
 * proxy api code to call backend api 
 * @param userid string
 * @param pw string
 * @param callback
 */

export function uploadFile(proxy, param, callback)
{
    const promiseUpload = new Promise((resolve, reject)=>{
        const req = new XMLHttpRequest();
        req.withCredentials = true;
        req.upload.addEventListener("progress", event => {
            if (event.lengthComputable) {
                param.onProgress("progress", (event.loaded / event.total) * 100);
            }
        });
        req.upload.addEventListener("load", event => {
            param.onProgress("load", null)
            // resolve(req.response);
        });      
        req.upload.addEventListener("error", event => {
            param.onProgress("error", null)
            //callback(false)
            reject(req.response);
        });
        req.upload.addEventListener("loadend", event=>{
            param.onProgress("loadend", null)
            //callback(true)
            resolve();
        });
        req.upload.addEventListener("abort", event=>{
            param.onProgress("abort", null)
            //callback(true)
            //resolve();
        });
        //const formData = new FormData();
        //formData.append("file", data.file, data.file.name);
        req.open("POST", proxy.apiServer+'/timsapi/uploadFile');
        req.send(param.data);
        console.log("after fileuploading request")
    }).then((success)=>{
        callback(true)
    }).catch(()=>{
        callback(false)
    })
}



///////////////////////////////////////////////////////////////////////////////////////////////
// test ui code
///////////////////////////////////////////////////////////////////////////////////////////////
/*
const useStyles = makeStyles((theme)=>({
    input:{
        width:"50%"
    },
    button: {
        margin: theme.spacing(1),
        padding:theme.spacing(1)
    },
}));
export function Test_uploadFile() {
    // const classes = useStyles();
    const [fileToUpload, setFileToUpload] = useState<string>("")
    const fileInputRef = useRef<HTMLInputElement>(null)
    const { enqueueSnackbar } = useSnackbar();
    // const [ formUpload, setFormUpload ] = useState<FormData|null>(null)
    const [ fileData, setFileData ] = useState<any>(null)
    const [ author, setAuthor ] = useState<string|null>(null)
    const [ personalFId, setPersonalFId] = useState<string|null>(null)
    const [ vertualFId, setVertualFId] = useState<string|null>(null)
    const [ title, setTitle] = useState<string|null>(null)
    const TestuploadFile = ()=>{
        enqueueSnackbar(fileToUpload)
        console.log(fileData)
        if(fileToUpload === null || fileToUpload === "")
        {
            return
        }        
        const data = new FormData();
        data.append('file', fileData)
        let file:string = fileToUpload;//inputRef.current?.files[0].name
        let ext = file.substring(file.lastIndexOf('.'));
        
        data.append("data",
            JSON.stringify({
                'author':author,
                'pfid':personalFId,
                'vfid':vertualFId,
                'title':title,
            })
        );
        const param:FileUploadParamType = {data, onProgress:(event:string, param:any)=>{
            console.log("file upload onProgress:", event)
        }}
        uploadFile(new Proxy("http://localhost:5000"), param, (result:boolean)=>{
            console.log("uploaded:", result)
        })
    }
    const handleUpload = ()=>{
        fileInputRef.current?.click()
        console.log("handleUpload")
    }
    const handleUploadChange=(event: any)=>{
        setFileData(event.target.files[0])        
        setFileToUpload(event.target.value)
    }
    const onChangeAuthor = (event:any)=> setAuthor(event.target.value)
    const onChangePersonalFolder = (event:any)=> setPersonalFId(event.target.value)
    const onChangeVirtualFolder = (event:any)=> setVertualFId(event.target.value)
    const onChangeTitle = (event:any)=> setTitle(event.target.value)
    const classes = useStyles()
    return (
        <div >
            <div >
                <TextField className={classes.input} required id="standard-required" onChange={onChangeAuthor} label="Author" />
            </div>
            <div>
                <TextField className={classes.input} required id="standard-required" onChange={onChangePersonalFolder} label="Personal Folder" />
            </div>
            <div>
                <TextField className={classes.input} required id="standard-required" onChange={onChangeVirtualFolder} label="Virtual Folder" />
            </div>
            <div >
                <TextField className={classes.input} required id="standard-required" onChange={onChangeTitle} label="Title" />
            </div>
            <Button variant="contained" onClick={TestuploadFile}>uploadFile</Button>         
            <input
                accept=".ppt,.pptx"
                style={{ display: 'none' }}
                id="fileSelect"
                ref={fileInputRef}
                type="file"
                onChange={handleUploadChange}
            />
            <Tooltip title={"select file to upload"}>
                <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<CloudUploadIcon />}
                    onClick={handleUpload}
                >
                    {fileToUpload}
                </Button>
            </Tooltip>
        </div>
    )
}
export function Test_uploadFile2() {
    return (
        <SnackbarProvider maxSnack={3}>
            <Test_uploadFile />
        </SnackbarProvider>
    );
}
*/
///////////////////////////////////////////////////////////////////////////////////////////////