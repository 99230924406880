import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import {  BrowserRouter as Router, Route,
          useRouteMatch, Switch, Link, useHistory, useLocation}  from 'react-router-dom'
import {SignIn} from "./SignIn"
import Root from "./Root"


ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
            <Router>
                <Route exact component={SignIn} path="/"/>
                <Route component={Root} path="/:viewname"/>
            </Router>
        </CookiesProvider>
    </React.StrictMode>,
  document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
