import React, { useEffect, useState } from 'react';
//import ReactDOM from 'react-dom';
import {  BrowserRouter as Router, Route,
          useRouteMatch, Switch, Link, useHistory, useLocation}  from 'react-router-dom'

import addNewGroup from "./api/proxyAddNewGroup"

import { makeStyles } from '@material-ui/core/styles';
import { useTheme, Theme } from '@material-ui/core/styles';
import GroupTreeView from './GroupTree2'
import { modifyUserInfo } from './api/proxyModifyUserInfo';
import { modifyGroupInfo } from "./api/proxyModifyGroupInfo"

import SaveIcon from '@material-ui/icons/Save'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'

import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {GroupProfile, GroupProfileUsers} from './GroupProfile'


const useStyles = makeStyles((theme) => ({
    root: {
        //display: 'flex',
        width:'flex',
        height:"inherit",
        width:"inherit",
        padding:4,
        overflow: "auto",
        backgroundColor:"grey",
    },
    groupTreePaper:{
        backgroundColor:"white",
        overflow:"auto",
        padding:4        
    },
    groupProfileRootPaper:{
        backgroundColor:"white",
        //overflow:"auto",
        padding:4,
        height:"calc(100vh - 100px)"
    },
    groupProfilePaper:{
        //backgroundColor:"yellow",
        padding:4,
        marginBottom:4,
        height : 250
    },
    groupUsersPaper:{
        backgroundColor:"#f0f0f0",
        padding:4,
        //height:"flex",
        overflow:"auto",
        height:"calc(100vh-500px)",
        maxHeight:"calc(100vh-500px)",
    },
    userTableBodyProfileEdit:{        
        display:"block",
        height:"flex",
        //rightMargin:"30px",
        overflow:"auto",
        maxHeight:"350px"
    }, 
    userListEdit:{
        maxHeight:"350px"
    },
    groupProfile:{        
        overflow:"auto",
        height:"calc(100vh - 100px)"
    },
    groupProfileGroupUser:{
        overflow:"auto",
        height:600,
    }
}))

export function GroupAdmin(props){
    const {
        proxy,
        groupINFO,
        permitGROUP,
        userINFO,
        folderINFO,
        userNodeEnable,
        handleNodeClick,
        modifyGroupInfo,
        refreshINFO
    } = props

    const theme = useTheme();
    const classes = useStyles();
    const [selectedGroup, setSelectedGroup] = useState(undefined)
    const [selectedUser, setSelectedUser] = useState(undefined)
    const [adminPermitGroup, setAdminPermitGroup] = useState([])

    useEffect(()=>{
        if(groupINFO){
            const groups = groupINFO.map(g=> g.id)
            setAdminPermitGroup(groups)
        }
        if(groupINFO && !selectedGroup){
            const root = groupINFO.find(g=>g.parent === null)
            setSelectedGroup(root)
        }
    }, [groupINFO])
    
    const handleNodeClickInner = (userid, groupid)=>{
        if(handleNodeClick)
            handleNodeClick(groupid)

        if(userid){
            // user node selected
            const userData = userINFO.find(u=>u.id === userid)
            if(userData){
                setSelectedUser(userData)
                const gid = userData.gid
                const groupData = groupINFO.find(g=>g.id ===groupid)
                if(groupData)
                    setSelectedGroup(groupData)
            }
        }else if(groupid){
            setSelectedUser(undefined)
            const groupData = groupINFO.find(g=>g.id ===groupid)
            if(groupData)
                setSelectedGroup(groupData)
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <Paper  className={classes.groupProfileRootPaper}>
                        {
                        <GroupTreeView 
                            proxy={proxy}
                            groupINFO={groupINFO}
                            selectedGroup={selectedGroup}
                            userINFO={userINFO}
                            permitGROUP={adminPermitGroup}
                            userNodeEnable={false}
                            handleNodeClick={handleNodeClickInner}
                            modifyGroupInfo={modifyGroupInfo}
                            modifyUserInfo={undefined}
                            collapseDefault={true}
                            refreshINFO={refreshINFO}
                            isDragDropSupport={true}
                            maxHeight={900}
                        />}
                    </Paper>
                </Grid>                
                <Grid item xs={7} >
                    <Paper className={classes.groupProfileRootPaper}>
                        {
                        <Grid container >
                            <Grid item xs={12}>
                                <Paper className={classes.groupProfilePaper}>
                                    <GroupProfile
                                        proxy={proxy}
                                        groupINFO={groupINFO}
                                        userINFO={userINFO}
                                        groupData={selectedGroup}
                                        refreshINFO={refreshINFO}
                                        setSelectedGroup={setSelectedGroup}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.groupUsersPaper}>
                                    <GroupProfileUsers
                                        proxy={proxy}
                                        groupINFO={groupINFO}
                                        userINFO={userINFO}
                                        groupData={selectedGroup}
                                        refreshINFO={refreshINFO}
                                    /> 
                                </Paper>
                            </Grid>
                        </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}
