import React, { 
                useState,
                useEffect,
                ReactNode,
                SyntheticEvent
            } from 'react';
//import './App.css';
//import {Proxy} from './api/proxy'
import { makeStyles } from '@material-ui/core/styles';

//import { IUserData, IGroupDataEx, IObjectData} from "./common";

import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    root: {
        //display: 'flex',
        width:'flex',
        '&:hover > $content': {
          backgroundColor: theme.palette.action.hover,
        },
        //height: "250px",
        overflow:"auto"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        marginTop: 0,
    },    
    contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        marginTop: 0,
    },
    docCardContainer:{
        display:"flex",
        flexDirection: "row",
        backgroundColor: "white",
        margin:0,
        padding:0,
        //width:`calc(100vw - ${250}px)`,
        //height:`calc(100vh - ${180}px)`,
        overflow:"scroll",
        //scrollbarWidth: "none"
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {
        backgroundColor: "blue"
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
    treeItem:{
        //padding: "2px",
        //borderTop:"solid 2px red"
    },
    treeItemDragOverHigh:{
        padding: "2px",
        borderTop:"solid 2px red",
        backgroundColor:"#76ff03"
    },
    treeItemDragOverMid:{
        padding: "2px",
        border:"solid 2px blue",
        backgroundColor:"#76ff03"
    },
    treeItemDragOverLow:{
        padding: "2px",
        borderBottom:"solid 2px red",
        backgroundColor:"#76ff03"
    },
    treeItemEnabled:{
        color: "blue"
    },
    treeItemDisabled:{
        color: "gray"
    }
}));

/*
interface TreeItemLabelH6Prop{
    label:string
}
function TreeItemLabelH6(props:TreeItemLabelH6Prop){
    return <Typography variant="h6">{props.label}</Typography>
}
*/
/*
interface GroupTreeViewProps{
    userNodeEnabled:boolean
    collapseDefault:boolean
    groupTreeData:IGroupDataEx[]
    userData:IUserData[]
    maxDepth:number
    currObject?:IObjectData|undefined
    currGroup:IGroupDataEx
    dragAndDrop:boolean
    handleDragEvent:((name:string, 
                    src:IGroupDataEx,
                    target:IGroupDataEx,
                    state:"high"|"mid"|"low",
                    coord:{x:number, y:number},
                    data:any)=>void) | null
    handleNodeClick:(uid:string|null, gid:string)=>void
    permitGroups:string[]|null
}*/

let g_dragSource = null
let g_dragTarget = null

export function GroupTreeView(props)
{
    const classes = useStyles();
    const [dragSource, setDragSource] = useState(null)
    const [dragTarget, setDragTarget] = useState(null)
    const [dragState, setDragState] = useState(null) //<"high"|"mid"|"low"|null>(null)
    const [update, setUpdate] = useState(0)

    let allGroupIds = [] // :string[] = [] as string[]
    // const roots:IGroupDataEx[] = props.groupTreeData?.filter((g:IGroupDataEx):boolean=> g.parent === null)
    const roots = props.groupTreeData?.filter((g)=> g.parent === null)
    if(!roots || roots.length === 0)
        return null
    
    //const root:IGroupDataEx = roots[0]
    const root = roots[0]
    if(props.groupTreeData)
        allGroupIds = props.groupTreeData.map(g=>(g.id)? g.id : "")
    
    // handle group label click
    //const handleLabelClick = (event:SyntheticEvent, uid:string|null, gid:string)=>{
    const handleLabelClick = (event, uid, gid)=>{
        if(props.permitGroups) {
            if(props.permitGroups.find(p=> p === gid)){
                event.preventDefault()
                props.handleNodeClick(uid, gid)
            }
        }
    }

    // handle user label click
    //const handleUserLabelClick = (event:SyntheticEvent, uid:string|null, gid:string)=>{
    const handleUserLabelClick = (event, uid, gid)=>{
        if(props.permitGroups) {
            if(props.permitGroups.find(p=> p === gid)){
                event.preventDefault()
                props.handleNodeClick(uid, gid)
            }
        }
    }

    //const handleIconClick = (event:SyntheticEvent)=>{
    const handleIconClick = (event)=>{
        if (!props.collapseDefault)
            event.preventDefault()
    }

    const currGroupId = root.id
    const currGroupInfo = props.groupTreeData.find((g)=>g.id === currGroupId);
    const currGroupUsers = (root)? root.users: ""
    const myDepth = 0
    let currentChildUsers = []

    if(currGroupInfo && currGroupUsers)
         currentChildUsers = props.userData.filter((u)=> currGroupUsers.indexOf(u.id) > 0)

    let currentDrag = ""
    const handleDragEvent = (name, evt, element, data, permitPass=false)=>{
        if(!permitPass && !props.permitGroups?.find(p=> p === element?.id))
            return

        if(props.handleDragEvent === null)
            return
        
        if(name === "ondragstart"){
            evt.dataTransfer.setData("src", data);

            if(g_dragSource === null) {
                setDragSource(element)
                g_dragSource = element
                console.log("onDragStart:", data)
            }
        } else if(name === "ondragover"){
            const gevt = evt
            const height = evt.target.clientHeight
            const interXPos = evt.pageX - evt.currentTarget.getClientRects()[0].x
            const interYPos = evt.pageY - evt.currentTarget.getClientRects()[0].y 
            
            evt.stopPropagation()
            evt.preventDefault()
            
            let pos = "mid"
            if(interYPos < (height /3)) {
                pos = "high"
            } else if(interYPos >= (height/3) && interYPos <= (height *2 /3)) {
                pos = "mid"
            } else if(interYPos >= (height*2/3)) {
                pos = "low"
            }

            if((element?.id === (dragSource).parent && pos === "mid")
                || ((element).parent === null && pos != "mid" ))
            {
                setDragTarget(null) // for display
                return
            }

            if(element !== g_dragSource){
                setDragTarget(element) // for display
                setDragState(pos)
                console.log(`drag Target:${element?.name}, state:${pos}`)
            }
            //console.log("onDragOver:", data as string, pos)
            //console.log("onDragOver:", data as string, interXPos, interYPos, pos)
        } else if(name === "ondrop") {
            const height = evt.target.clientHeight       
            const interXPos = evt.pageX - evt.currentTarget.getClientRects()[0].x
            const interYPos = evt.pageY - evt.currentTarget.getClientRects()[0].y 
            let pos="mid"
            if(interYPos < (height /3)) {
                pos = "high"
            } else if(interYPos >= (height/3) && interYPos <= (height *2 /3)) {
                pos = "mid"
            } else if(interYPos >= (height*2/3)) {
                pos = "low"
            }
            const target = element
            if (target.parent === null && pos != "mid")
                return
            if(element !== g_dragSource){
                setDragTarget(element) // for display
                setDragState(pos)
            }
            // const ddd:string = evt.dataTransfer?.getData("src")   
                // do nothing...
            if(g_dragSource?.id !== target.id && g_dragSource !== null){
                console.log("onDrop:(",interXPos,",",interYPos,"), target:",data, dragState, "src:",dragSource?.title+"("+dragSource?.name+")"
                                                                        , "target:",dragTarget?.title+"("+dragTarget?.name+")")
                //console.log("onDrop:", data as string, interXPos, interYPos, pos)     
                props.handleDragEvent("onDrop", 
                                    //((dragSource as IGroupDataEx).id[0] === "G")? 
                                    g_dragSource, // : dragSource as IUserData,
                                    //((dragTarget as IGroupDataEx).id[0] === "G")? 
                                    dragTarget, // : dragTarget as unknown as IUserData,
                                    pos,
                                    {x:interXPos, y:interYPos}, data);
                g_dragSource = null
                setUpdate(update+1)
            }
        } else if(name === "ondrag"){
            // do nothing...
            //console.log("onDrag:", data as string, interXPos, interYPos)
        } else if(name === "ondragend"){            
            //console.log("onDragEnd:", data as string, interXPos, interYPos)
            setDragSource(null)
            setDragTarget(null)
            setDragState(null)
            if(g_dragSource !== null){
                g_dragSource = null
                //console.log("onDragEnd:", data as string)
            }
        } else if(name === "ondragenter"){
            // do nothing...
            //console.log("onDragEnter:", data as string, interXPos, interYPos)
        } else if(name === "ondragleave"){
            // do nothing...
            //console.log("onDragLeave:", data as string, interXPos, interYPos)
        }
    }
    const itemRenderH6 = (content) => {
        return <Typography variant="subtitle1">{content}</Typography>
    }
    return(
        <TreeView
            style={{maxHeight:props.maxHeight}}
            className={classes.root}
            defaultExpanded={(props.collapseDefault)? [""]: allGroupIds}
            defaultCollapseIcon={<ArrowRightIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />} >
            <TreeItem
                id={root.id}
                key={root.id}
                className={ clsx(classes.treeItem, 
                            (props.permitGroups?.find(p=>p === root.id)? classes.treeItemEnabled : classes.treeItemDisabled),
                            { 
                                [classes.treeItemDragOverHigh]: (root.id === dragTarget?.id && dragState === "high"), 
                                [classes.treeItemDragOverLow]: (root.id === dragTarget?.id && dragState === "low"), 
                                [classes.treeItemDragOverMid]: (root.id === dragTarget?.id && dragState === "mid"), 
                            })
                        }
                draggable={props.dragAndDrop}
                nodeId={root.id}
                label={itemRenderH6(root.title+"("+root.name+")")}
                onIconClick={handleIconClick}
                onLabelClick={ (e)=> handleLabelClick(e, null, root.id)}
                onDragStart = {(e)=>handleDragEvent("ondragstart",e, root, root.title+"("+root.name+")")}
                onDrag = {(e)=>handleDragEvent("ondrag", e, root, root.title+"("+root.name+")")}
                onDragEnd = {(e)=>handleDragEvent("ondragend",e, root,  root.title+"("+root.name+")")}
                onDragEnter = {(e)=>handleDragEvent("ondragenter", e, root,  root.title+"("+root.name+")")}
                onDragOver = {(e)=>handleDragEvent("ondragover", e, root,  root.title+"("+root.name+")")}
                onDragLeave = {(e)=>handleDragEvent("ondragleave", e, root,  root.title+"("+root.name+")")}
                onDrop = {(e)=>handleDragEvent("ondrop", e, root,  root.title+"("+root.name+")")}
                >
                
                <GroupTreeItem
                    parent={root}
                    userNodeEnabled={props.userNodeEnabled}
                    groupTreeInfo={props.groupTreeData}
                    userData={props.userData}
                    currDepth={0}
                    maxDepth={props.maxDepth} 
                    onIconClick={handleIconClick}
                    onLabelClick={handleLabelClick}
                    onUserLabelClick={handleUserLabelClick}
                    currObject={props.currObject}
                    currGroup={props.currGroup}
                    dragAndDrop={props.dragAndDrop}
                    handleDragEvent={handleDragEvent}
                    itemRender={itemRenderH6}
                    dragTarget={dragTarget}
                    dragState={dragState}
                    groupUsers= { props.userData.filter((u)=> (root.users)?root.users.indexOf(u.id) > 0:false)}
                    permitGroups={ props.permitGroups }
                />
                {
                    (props.userNodeEnabled)?
                    (
                        (props.userData.filter((u)=> (root.users)?root.users.indexOf(u.id) > 0:false).length > 0)?
                        (
                            (props.userData.filter((u)=> (root.users)?(root.users.indexOf(u.id) > 0):false))
                            .map((userinfo)=>(                                            
                                <TreeItem
                                     className={ clsx(classes.treeItem,
                                            { 
                                                [classes.treeItemDragOverHigh]: (root.id === dragTarget?.id && dragState === "high"), 
                                                [classes.treeItemDragOverLow]: (root.id === dragTarget?.id && dragState === "low"), 
                                                [classes.treeItemDragOverMid]: (root.id === dragTarget?.id && dragState === "mid"), 
                                            })
                                        }
                                    key={userinfo.id}
                                    nodeId={userinfo.id}
                                    label={userinfo.name+"("+userinfo.login_id+")"}
                                    onIconClick={handleIconClick}
                                    onLabelClick={(e)=>handleUserLabelClick(e, userinfo.id, root.id)}                                
                                    draggable={props.dragAndDrop}
                                    onDragStart = {(e)=>handleDragEvent("ondragstart",e, userinfo,  "("+userinfo.login_id+")")}
                                    onDrag = {(e)=>handleDragEvent("ondrag", e, userinfo,  "("+userinfo.login_id+")")}
                                    onDragEnd = {(e)=>handleDragEvent("ondragend",e, userinfo,  "("+userinfo.login_id+")")}
                                    onDragEnter = {(e)=>handleDragEvent("ondragenter", e, userinfo,  "("+userinfo.login_id+")")}
                                    onDragOver = {(e)=>handleDragEvent("ondragover", e, userinfo,  "("+userinfo.login_id+")")}
                                    onDragLeave = {(e)=>handleDragEvent("ondragleave", e, userinfo,  "("+userinfo.login_id+")")}
                                    onDrop = {(e)=>handleDragEvent("ondrop", e, userinfo,  "("+userinfo.login_id+")")}
                                />
                            ))
                        )
                        : null
                    )
                    :null
                }
            </TreeItem>
        </TreeView>
    );
}

/*
type GroupTreeItemProp={
    parent:IGroupDataEx // current group id
    userNodeEnabled:boolean
    groupTreeInfo:IGroupDataEx[]
    userData:IUserData[]
    dragAndDrop:boolean
    currDepth:number
    maxDepth:number
    currObject:IObjectData|undefined
    currGroup:IGroupDataEx
    groupUsers:IUserData[]
    dragTarget:IGroupDataEx|IUserData|null
    dragState:string|null
    onIconClick:(event:SyntheticEvent)=>void
    onLabelClick:(event:SyntheticEvent, uid:string|null, gid:string)=>void
    onUserLabelClick:(event:SyntheticEvent, uid:string|null, gid:string)=>void
    handleDragEvent:(name:string, evt:any, element:IGroupDataEx|IUserData|null, data:any)=>void
    itemRender: (content:string)=>ReactNode
    permitGroups: string[]|null
}*/

function mapLinkOrder(groupList, parent)
{
    const ordered = []
    const siblings = groupList.filter((g)=> g.parent === parent.id)

    if(!siblings) return ordered
    const head = siblings?.find(s=>(s.prev_sibling === null && parent.first_child === s.id))
    if(head) {
        ordered.push(head)
    } else {
        return ordered
    }
    
    let curr = head
    while(curr){
        const next = siblings.find(s=> s.prev_sibling === curr?.id && (curr.next_sibling === s.id || curr.next_sibling === null))
        if(ordered.find(o=> o.id === next?.id))
            break;
        if(next)
            ordered.push(next)
        curr = next
    }    
    return ordered
}

function GroupTreeItem(props)
{
    const classes = useStyles();
    const [currentChildUsers, setCurrentChildUsers] = useState([])
    const [currGroupInfo, setCurrGroupInfo] = useState({})
    const [children, setChildren] = useState([])

    useEffect(()=>{
        if(props.parent && props.groupTreeInfo && props.parent.users){
            const users = props.userData.filter((u)=> props.parent.users.indexOf(u.id) > 0)
            setCurrentChildUsers(users)
        }        
        const c = mapLinkOrder(props.groupTreeInfo, props.parent)
        setChildren(c)
    },[]);
        
    return(
        <div>
        {
            (props.currDepth >= props.maxDepth || !props.parent)? null
            :children.map((child)=>(
                <TreeItem
                    id={child.id}
                    key={child.id}
                    nodeId={child.id} 
                    label={props.permitGroups?.find(p=>p === child.id)? props.itemRender(child.title+"("+child.name+")") : "invisible group"}
                    onIconClick={props.onIconClick}
                    draggable={props.dragAndDrop}
                    onLabelClick={(e)=>props.onLabelClick(e, null, child.id)}
                    
                    className={ clsx(classes.treeItem, 
                        (props.permitGroups?.find(p=>p === child.id)? classes.treeItemEnabled : classes.treeItemDisabled),
                        { 
                            [classes.treeItemDragOverHigh]: (child.id === props.dragTarget?.id && props.dragState === "high") , 
                            [classes.treeItemDragOverLow]: (child.id === props.dragTarget?.id && props.dragState === "low"), 
                            [classes.treeItemDragOverMid]: (child.id === props.dragTarget?.id && props.dragState === "mid"), 
                        })
                    }
                    onDragStart = {(e)=>props.handleDragEvent("ondragstart",e, child, child.title+"("+child.name+")")}
                    onDrag = {(e)=>props.handleDragEvent("ondrag", e, child, child.title+"("+child.name+")")}
                    onDragEnd = {(e)=>props.handleDragEvent("ondragend",e, child, child.title+"("+child.name+")")}
                    onDragEnter = {(e)=>props.handleDragEvent("ondragenter", e, child, child.title+"("+child.name+")")}
                    onDragOver = {(e)=>props.handleDragEvent("ondragover", e, child, child.title+"("+child.name+")")}
                    onDragLeave = {(e)=>props.handleDragEvent("ondragleave", e, child, child.title+"("+child.name+")")}
                    onDrop = {                        
                        (e)=>props.handleDragEvent("ondrop", e, child, child.title+"("+child.name+")")
                    }
                >
                    <GroupTreeItem 
                        parent={child}
                        userNodeEnabled={props.userNodeEnabled}
                        groupTreeInfo={props.groupTreeInfo}
                        userData={props.userData}
                        currDepth={props.currDepth+1}
                        maxDepth={props.maxDepth}
                        onIconClick={props.onIconClick}
                        onUserLabelClick={props.onUserLabelClick}
                        onLabelClick={props.onLabelClick}
                        currObject={props.currObject}
                        groupUsers= { props.userData.filter((u)=> (child.users)?child.users.indexOf(u.id) > 0 : false)}
                        dragState={props.dragState}
                        dragTarget={props.dragTarget}
               
                        currGroup={props.currGroup}
                        dragAndDrop={props.dragAndDrop}
                        handleDragEvent={props.handleDragEvent}
                        itemRender={props.itemRender}
                        permitGroups={props.permitGroups}
                    />
                    {
                        (props.userNodeEnabled && props.permitGroups?.find(p=>p === child.id))?
                        (
                            (props.userData.filter((u)=> (child.users)?child.users.indexOf(u.id) > 0:false).length > 0)?
                            (
                                (props.userData.filter((u)=> (child.users)?child.users.indexOf(u.id) > 0:false))
                                .map((userinfo)=>(                                            
                                    <TreeItem
                                        id={userinfo.id}
                                        key={userinfo.id}
                                        draggable={props.dragAndDrop}
                                        nodeId={userinfo.id}
                                        label={props.itemRender(userinfo.name+"("+userinfo.login_id+")")}
                                        onIconClick={props.onIconClick}
                                        onLabelClick={(e)=>props.onUserLabelClick(e, userinfo.id, child.id)}  
                                            
                                        className={ clsx(classes.treeItem, 
                                            { 
                                                [classes.treeItemDragOverHigh]: (child.id === props.dragTarget?.id && props.dragState === "high") , 
                                                [classes.treeItemDragOverLow]: (child.id === props.dragTarget?.id && props.dragState === "low"), 
                                                [classes.treeItemDragOverMid]: (child.id === props.dragTarget?.id && props.dragState === "mid"), 
                                            })
                                        }
                                        onDrop = { (e)=>props.handleDragEvent("ondrop", e, userinfo, "("+userinfo.login_id+")")}
                                        onDrag = {(e)=>props.handleDragEvent("ondrag", e, userinfo, "("+userinfo.login_id+")")}
                                        onDragEnd = {(e)=>props.handleDragEvent("ondragend",e, userinfo, "("+userinfo.login_id+")")}
                                        onDragStart = {(e)=>props.handleDragEvent("ondragstart",e, userinfo, "("+userinfo.login_id+")")}
                                        onDragEnter = {(e)=>props.handleDragEvent("ondragenter", e, userinfo, "("+userinfo.login_id+")")}
                                        onDragOver = {(e)=>props.handleDragEvent("ondragover", e, userinfo, "("+userinfo.login_id+")")}
                                        onDragLeave = {(e)=>props.handleDragEvent("ondragleave", e, userinfo, "("+userinfo.login_id+")")}
                                    />
                                ))
                            
                            )
                            : null
                        )
                        :null
                    }
                </TreeItem>
            ))

        }
        </div>
    );
}
