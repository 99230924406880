//type FetchFunctionType = (url:string, param:any)=>Promise<Response>
//export interface IProxyServer {
//    readonly apiServer:string
//    getFetch:FetchFunctionType
//    postFetch:FetchFunctionType
//}
//const DefaultProxy:string = "http://localhost:5000"
import {API_FETCHSERVER} from "../common"

export class Proxy{
    apiServer = API_FETCHSERVER   
    constructor(serverAddress){
        if(serverAddress)
            this.apiServer = serverAddress
    }

    getBaseURL(){
        return this.apiServer
    }

    async postFetch(url, param) {    
        const response = await fetch(this.apiServer+url, {
            method:'post',
            headers:{
                'Content-type':'application/json'
            },
            // need to encript data
            body: JSON.stringify(param),
            credentials:'include',
            //mode: 'cors'
        });
        return response;
    }

    async getFetch(url, param) {
        const response = await fetch( this.apiServer+url, {
            method:'get',
            headers:{
                'Content-type':'application/json'
            },
            // need to encript data
            body: JSON.stringify(param),
            credentials:'include',
            //mode: 'cors'
        });
        return response;    
    }
}
