//import { IProxyServer, Proxy} from "./proxy"
//import { SignOutParam, SignInResult, EResult} from "../common_front"
//import { makeStyles } from "@material-ui/core/styles";
//import React, { useState, useEffect, useRef, FormEvent } from 'react';
//import { Button, Dialog, DialogTitle, Typography, DialogContent, TextField, DialogActions } from "@material-ui/core";
import { EResult } from "../common"
/**
 * proxy api code to call backend api 
 * @param userid string
 * @param pw string
 * @param callback
 */
export function signOut(proxy, param, callback)
{
    proxy.postFetch("/timsapi/signout", param)
    .then(async (response)=>{
        if(response.ok)
        {
            const body = await response.text()
            let resData = JSON.parse(body)        
            callback(resData)
        }
        else{
            callback({result:EResult.RESULT_ERROR, reason:"api fetch failed - signin", sess:null});
        }
    });
}

/*
//=========================================================================
// test code
const useStyles = makeStyles({
});
type Test_SignOutProp={
    sess:string
}
export function Test_signOut(props:Test_SignOutProp) {
    
    const onClickSignOut = ()=>{
        // call signout api with props.sess which is the session id got from signin         
        console.log("TESTsignIn")
        signOut(new Proxy("http://localhost:5000"), {sess:""}, (result:SignInResult)=>{
            console.log("SignIn:", result.sess)
        })
    }

    return (
        <div>
            <Button onClick={onClickSignOut}>Sign Out</Button>
        </div>
    )
}
*/