import React, { useState, useEffect } from 'react';
import packageJson from '../package.json';
// globalThis.appVersion = packageJson.version

const withClearCache = (Component) => {
    const ClearCacheComponent = (props) => {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(true);
        useEffect(() => {
            setCacheBusterState();
        }, [])

        const buildDateGreaterThan = (latestDate, currentDate) => {
            const latestDateTime = new Date(latestDate).getTime();
            const currentDateTime = new Date(currentDate).getTime();

            return latestDateTime > currentDateTime ? true : false;
        };

        const setCacheBusterState = () => {
            fetch("/meta.json")
                .then(response => response.json())
                .then(meta => {
                    const latestVersionDate = meta.buildDate;
                    const currentVersionDate = packageJson.buildDate;

                    const shouldForceRefresh = buildDateGreaterThan(
                        latestVersionDate,
                        currentVersionDate
                    );

                    if (shouldForceRefresh) {
                        setIsLatestBuildDate(false);
                        refreshCacheAndReload();
                        console.log(`update to current version (${latestVersionDate} to ${currentVersionDate})`)
                    } else {
                        console.log(`latest version ${latestVersionDate} is the current version date`)
                        setIsLatestBuildDate(true);
                    }
                }).catch(err => console.log(err))
        }


        const refreshCacheAndReload = () => {
            if (caches) {
                caches.keys.then(names => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                })
            }

            window.location.reload();
        }

        return (
            <React.Fragment>
                {isLatestBuildDate ? <Component {...props} /> : null}
            </React.Fragment>
        )

    }
    return ClearCacheComponent;

}

export default withClearCache;