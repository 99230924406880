import React, { useEffect, useState, useRef} from 'react';
// import ReactDOM from 'react-dom';
import { Link }  from 'react-router-dom'
import { useCookies } from 'react-cookie';


import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import { Typography, Button, TextField, Box, Backdrop, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { Proxy } from "./api/proxy"
import { signIn } from "./api/proxySignIn"
import { getMySessionData } from './api/proxyGetMySessionData';
import { changePassword } from './api/proxyChangePassword';
import withClearCache from "./ClearCache";

const messages = {
    mustChangeExpiredPw : '비밀번호를 변경하지 않는 경우 로그인 할 수 없습니다.',
    required : '필수 입력 정보입니다.',
    length: '패스워드는 5글자 이상 입력하십시오.',
    unmatched: '입력한 패스워드와 일치하지 않습니다.',
    unchanged: '현재 사용 중인 패스워드와 다른 패스워드를 입력하십시오.',
    includeId: '패스워드에는 사용자 아이디가 포함될 수 없습니다.',
    failChangePassword: '패스워드 변경에 실패하였습니다. 다시 시도해주시기 바랍니다.',
    passwordChanged: '패스워드를 변경하였습니다.'
}

const ClearCacheComponent = withClearCache(SignInComponent);

export const SignIn = () => {
    return <ClearCacheComponent />
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
                <Link color="inherit" to="https://www.tescom.co.kr">
                    TESCOM
                </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Warning(props) {
    return (
        <Typography color="error" variant="h4" align="center">
            {props.message}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(12),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


function SignInComponent(){ 
    const classes = useStyles()
    const proxy = new Proxy()

    const [loginId, setLoginId] = useState("")
    const [password, setPassword] = useState("")
    const [progressOpen, setProgressOpen] = useState(false)
    const [cookies, setCookie] = useCookies(['sess'])
    const [warning, setWarning] = useState("")

    //states related to changing expired password
    const [daysFromPwdChanged, setDaysFromPwdChanged] = useState(null);
    const [openChangePwdDialog, setOpenChangePwdDialog] = useState(false);
    const [showPwdChangeForm, setShowPwdChangeForm] = useState(false);
    
    const [passField, setPassField] = useState({})
    const [passFieldError, setPassFieldError] = useState({})
    const [passChanged, setPassChanged] = useState(false)
    const [passFormMsg, setPassFormMsg] = useState(null)
 
    //const [redirect, setRedirect] = useState(false)

    const gotoRootRef = useRef()

    const handleCloseProgress = ()=>{
        setProgressOpen(false)
    }
    const onSigninIdChanged = (id)=>{
        setLoginId(id)
    }
    const onPasswordChanged = (pwd)=>{
        setPassword(pwd)
    }
    const onSubmit = (event)=>{
        setWarning("")
        console.log(loginId, password)
        handleSignIn()
        event.preventDefault();
    }
    const handleSignIn = async () => {
        setProgressOpen(true)
        signIn(proxy, { loginid: loginId, password }, (res) => {
            const { daysFromPwdChanged, result,  sess }  = res;
            if (result === "OK") {
                // 비밀번호 변경 후 30일 이상 지났을 경우, 화면에 패스워드 변경 창 표시 
                if (daysFromPwdChanged) {
                    setDaysFromPwdChanged(daysFromPwdChanged);
                    setOpenChangePwdDialog(true);
                    setShowPwdChangeForm(false);
                    setProgressOpen(false);

                 //패스워드 변경 후 60일을 초과 하지 않았을 시, 변경을 연기할 수 있다.
                 //사용자가 변경을 하지 않는 경우, 바로 로그인할 수 있도록 backend에서 session을 설정하며 api에서 반환한 session id를 cookie에 설정한다.
                    if(daysFromPwdChanged < 60) {
                        setCookie('sess', sess, { path: '/' })
                    } 
                 //패스워드 변경 후 60일 초과 시에는 패스워드를 변경하지 않으면 로그인 불가 
                 //:session 정보를 api에서 반환하지 않으므로 쿠키에 세션 id를 설정하지 말 것.
                    return;
                }
                // goto the next view
                setCookie('sess', sess, { path: '/' })
                document.querySelector("#enterIntoTIMS").click()
            } else {
                //console.log(res.reason)
                setWarning("Error!!! login failed")
            }
            setProgressOpen(false)
        })
    }
    const verifySession = ()=>{
        return new Promise((resolve, reject) =>{
            getMySessionData(proxy, (res)=>{
                return (res.result === "OK" && res.data)? resolve(res.data) : reject("Error: Invalid session")
            })
        })
    }

    //onChange New-Password & confirm-password
    const handleChangePw = (e) => {
        setPassField({
            ... passField,
            [e.target.id] : e.target.value
        })
    }

    //client-side validation
    const validateChangePwForm = (e) => {
            try {
                const { id, value } = e.target;
                let msg;
                if (!value) {
                    msg = messages.required;
                } else if (id === 'npw' && value.length < 5) {
                    msg = messages.length;
                } else if (id === 'cpw' && passField.npw !== passField.cpw) {
                   msg = messages.unmatched;
                } else {
                   msg = null
                }
                setPassFieldError({ ...passFieldError, [id] : msg })
                
            } catch (e) {
                console.error(e)
            }
        }
    

    //when cursor is focused in change-password-form text field and press Enter ->
    //handle changePass
    const handleChangePassKeyPress = event => {
        if (event.key === 'Enter') {
            handleClickChangePw();
        }
    }

    const handleClickToChangePwForm = e => {
        setShowPwdChangeForm(true);
    }

    const handleCloseDialog =  shouldRedirect => { 
        //document.querySelector("#enterIntoTIMS").click()
        if(shouldRedirect) {
            document.querySelector("#enterIntoTIMS").click()
        } else {
            setOpenChangePwdDialog(false);
            setWarning(messages.mustChangeExpiredPw);
            setPassField({});
        }
    }

    const handleClickChangePw = () => {
        try {
            changePassword(proxy, { loginId, password, passField, daysFromPwdChanged })
            .then(result => {
                const { error, msg, passFieldError, sess } = result;
                if(!error) {
                    // setPassFormMsg(messages.passwordChanged);
                    setPassChanged(true)
                    setTimeout(() => {
                       if(daysFromPwdChanged > 30) {
                            setCookie('sess', sess, { path: '/' })
                       }
                       document.querySelector("#enterIntoTIMS").click()
                    }, 1000)
                    return;
                } 

                if(msg === 'passFieldError') {
                    const fieldErrorMsg = {};
                    Object.entries(passFieldError).forEach(([field, error]) => {
                        fieldErrorMsg[field] = !error || !error.length
                            ? undefined
                            : (<React.Fragment>
                                {error.map(e => <p>{messages[e]}</p>)}
                            </React.Fragment>)
                    });
                    setPassFieldError(fieldErrorMsg);
                    setPassFormMsg(null)
                } else if(msg === 'failChangePassword') {
                    setPassFormMsg(messages.failChangePassword)
                } else {
                    setPassFormMsg(null);
                    setOpenChangePwdDialog(false);
                    setWarning(messages.failChangePassword)
                }
            }).catch(err => {
                console.log(err)
            })

        } catch (e) {
            console.log(e)
        }
    }

    useEffect(()=>{
        setProgressOpen(true)
        const promise = verifySession()
        .then((sess)=>{
            if(sess.id){
                setCookie('sess', sess.id, {path:'/'})
                if(gotoRootRef)
                    gotoRootRef.current.click()
                else
                    document.querySelector("#enterIntoTIMS").click()
            }
        })
        .finally(()=>{
            setProgressOpen(false)
        })
    }, [])
    
    return (
        <React.Fragment>

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Backdrop className={classes.backdrop} open={progressOpen} onClick={handleCloseProgress}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Link ref={gotoRootRef} id="enterIntoTIMS" to="/folder" style={{display:"none"}}></Link>
            <div className = {classes.paper}>
                <Typography component="h1" variant="h2"> TIMS </Typography>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate onSubmit={onSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="signinid"
                        label="Sign-In Id"
                        name="signinid"
                        autoComplete="signin"
                        onChange={(e)=>onSigninIdChanged(e.target.value)}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e)=>onPasswordChanged(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        <Typography variant="h6">Sign In</Typography>
                    </Button>
                </form>
                <Box mt={1}>
                    <Warning message={warning}/>
                </Box>
                <Box mt={3}>
                    <Copyright />
                </Box>
            </div>
        </Container>
        <Dialog open={openChangePwdDialog}> 
        {!showPwdChangeForm ?
                        (
                            <React.Fragment>
                                <DialogTitle>패스워드 변경이 필요합니다.</DialogTitle>
                                <DialogContent className={classes.passChangeRequest}>
                                    <Typography variant="subtitle" >
                                        {`패스워드를 변경한 지 ${daysFromPwdChanged}일이 지났습니다.`}
                                        <br></br>
                                        {`패스워드를 변경하여 주시기 바랍니다.`}
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        key="to-change-pw"
                                        onClick={handleClickToChangePwForm}
                                    >
                                        OK
                                    </Button>
                                    {daysFromPwdChanged === 30 ?
                                        <Button
                                            variant="outlined"
                                            key="remind-me-later"
                                            onClick={e => handleCloseDialog(true)}
                                        >
                                            Remind me later
                                        </Button> :
                                        <Button
                                            variant="outlined"
                                            key="cancel"
                                            onClick={e => handleCloseDialog(false)}
                                        >
                                            cancel
                                        </Button>
                                    }
                                </DialogActions>
                            </React.Fragment>
                        ) :
                        (
                            !passChanged ?
                                <React.Fragment>
                                    <DialogTitle>패스워드 변경</DialogTitle>
                                    <DialogContent>
                                        {/* show error msg if update db failed */}
                                        {passFormMsg ?
                                            <Typography variant="subtitle2" color="error">
                                                {passFormMsg}
                                            </Typography>  : undefined}
                                        <TextField
                                            fullWidth
                                            required
                                            autofocus
                                            key="npw"
                                            id="npw"
                                            label="패스워드"
                                            type="password"
                                            className={classes.pwdInput}
                                            onChange={handleChangePw}
                                            onBlur={validateChangePwForm}
                                            onKeyPress={handleChangePassKeyPress}
                                            error={Boolean(passFieldError && passFieldError.npw)}
                                            helperText={passFieldError && passFieldError.npw ? passFieldError.npw : null}
                                        />
                                        <TextField
                                            fullWidth
                                            required
                                            key="pwConfirm"
                                            id="cpw"
                                            label="패스워드 확인"
                                            type="password"
                                            className={classes.pwdInput}
                                            onChange={handleChangePw}
                                            onBlur={validateChangePwForm}
                                            onKeyPress={handleChangePassKeyPress}
                                            error={Boolean(passFieldError && passFieldError.cpw)}
                                            helperText={passFieldError && passFieldError.cpw ? passFieldError.cpw : null}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant="outlined"
                                            key="cancel-change-pw"
                                            onClick={e => handleCloseDialog(daysFromPwdChanged > 30 ? false : true)}
                                        >
                                            {daysFromPwdChanged > 30 ? 'cancel' : 'change later'}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            key="save"
                                            onClick={handleClickChangePw}
                                        >
                                            save
                                        </Button>
                                    </DialogActions>
                                </React.Fragment>
                                : <DialogTitle>
                                    <span>✔</span> 패스워드 변경에 성공했습니다.
                                  </DialogTitle>
                        )
                    }
        </Dialog>
        </React.Fragment>

    )
}
