import React, { useEffect, useState, createRef, useRef } from 'react';
import { makeStyles, withStyles, DialogTitle, ListItemText } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import TablePagination from '@material-ui/core/TablePagination';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { MenuList, MenuItem } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import MenuIcon from '@material-ui/icons/Menu';
import GridOnIcon from '@material-ui/icons/GridOn';
import GroupIcon from '@material-ui/icons/Group';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Popper from '@material-ui/core/Popper';
import Switch from '@material-ui/core/Switch';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import GroupTreeView from "./GroupTree2"
import clsx from 'clsx';

import {  BrowserRouter as Router, Route,
          useRouteMatch, Link, useHistory, useLocation}  from 'react-router-dom'

const useStyles = makeStyles((theme)=>({    
    appBar: {
        height:"inherit",
        color:"primary",
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        position:'relative'
    },
    appBarHidable:{
        position:'absolute'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display:"none"
    },
    groupButton: {
        color:"white",
        margin: 3,
        maxWidth: 350,
        width:350
    }, 
    componentInAppBar:{
    },
    supervisorMode:{
        backgroundColor: "gray"
    }
}))

const ViewModeSwitch = withStyles({
    switchBase: {
        color: red[300],
        '&$checked': {
            color: red[500],
        },
        '&$checked + $track': {
            backgroundColor: red[500],
        },
    },
    checked: {},
    track: {},
})(Switch);

export default function AppBarComposite(props){
    const classes = useStyles()
    const {
        proxy,
        userINFO,
        groupINFO,
        currGROUP,
        currFOLDER,
        currOBJECT,
        myUserINFO,
        myGroupINFO,
        isLimitPermit,
        permissionINFO,
        currPAGE,
        currROWPERPAGE,
        setCurrPAGE,
        position,
        drawerOpen,
        setDrawerOpen,
        //onTreeItemClick,
        archiveFlag,
        setArchiveFlag,
        previewMode,
        setPreviewMode,
        title,
        groupTreeSelectUser,    // user selected
        groupTreeSelectGroup,   // group selected
        gotoFolderButton,
        showObjTitle,
        showViewModeToggle,
        showArchiveToggle,
        showPagination,
        searchFilter,
        setSearchFilter,
        handleSignOut,
        maxItemCount,
        drawerOpenButton,        
        searchDisable, 
        currView,
        treeUserNodeEnable,
        refreshINFO,        
        //isAdminModeOn,
        //setIsAdminModeOn
        supervisorMode,
        setSupervisorMode,
    } = props

    const groupTreeData = groupINFO
    const usersInfo = userINFO
    const author = userINFO.find(u=>u.id === currOBJECT?.author)

    const anchorUserMenuRef = useRef(null)

    const [userMenuOpen, setUserMenuOpen] = useState(false)
    const [groupPopoverElement, setGroupPopoverElement] = useState()
    const [searchFilterCopy, setSearchFilterCopy]=useState(searchFilter)
    const [defaultExpandGroup, setDefaultExpandGroup] = useState(null )

    const handleCloseUserMenu = ()=>setUserMenuOpen(false)
    const handleUserMenuToggle = ()=>setUserMenuOpen(!userMenuOpen)
    const handleCloseGroupPopover = ()=> setGroupPopoverElement(null)
    const handleGroupTreeOpen = (event)=> setGroupPopoverElement(event.currentTarget)
    
    //const handleMyProfile = ()=>{ handleCloseUserMenu()
    //const handleMyDocument = ()=>{ handleCloseUserMenu() }
    //const handleLogout = ()=>{ handleCloseUserMenu(); handleSignOut(); }

    useEffect(()=>{
        if(currView === "document")
            setSearchFilterCopy(searchFilter)
    })

    useEffect(() => {
        setDefaultExpandGroup(getCurrentGroupPath())

    },[props.currGROUP])

    const getCurrentGroupPath = () => {
        if(!currGROUP?.id) {
            return null;
        }
        
        let path = [currGROUP.id];
        let parent = currGROUP.parent;
        while(parent) {
            let item = groupINFO.find(g => g.id === parent)
            path.push(item.id);
            parent = item.parent;
        }
        return path;
    }

    const onMenuClick = (target)=>{
        handleCloseUserMenu()
        if(target)
            target.current.click()
    }
    const handleSearchFilterChange = (searchFilter)=>{
        setSearchFilterCopy(searchFilter)
    }
    const handleClickSearchRefresh = ()=>{
        setSearchFilter(searchFilterCopy)
    }

    const onTreeItemClick = (uid, gid)=>{
        if(gid || uid){
            if(permissionINFO.find(p=>p === gid)){
                groupTreeSelectGroup(gid) //setCurrGROUP & currFOLDER by selected gid

                handleCloseGroupPopover()
            }
        }
    }
    const updateArchiveFlag=()=>
    {
        setArchiveFlag(!archiveFlag)
    }


    const handleNodeToggle = (groupIds) => {
        setDefaultExpandGroup(groupIds)
    }

    return (        
        <AppBar color={"primary"} className={ clsx(classes.appBar, 
                {[classes.appBarShift]: drawerOpen,[classes.appBarHidable]: (position==="absolute"), [classes.supervisorMode]: (supervisorMode)})}
            >
            <Toolbar className={ clsx(classes.appBar)} >                
                {
                    (myUserINFO && myUserINFO.user_level === "admin") ?
                    <IconButton color="inherit" aria-label="open drawer" 
                            onClick={()=>setSupervisorMode(!supervisorMode)} edge="start"
                            className={clsx(classes.menuButton, drawerOpen && classes.hide)} >
                        <SupervisedUserCircleIcon />
                    </IconButton>
                    :null
                }
                {/* {
                    (gotoFolderButton)?
                    <IconButton color="inherit" aria-label="goto folder" 
                            component={Link} to= "/folder" edge="start"
                            className={clsx(classes.menuButton)} >
                        <GridOnIcon />
                    </IconButton>
                    : null
                } */}
                
                <Tooltip title={<Typography variant="h6">{title}</Typography>}placement="bottom">
                    <Button variant="outlined"  color="inherit" onClick={handleGroupTreeOpen}>
                        <GroupIcon  color="inherit"/> <Typography noWrap={true} color="inherit" variant="subtitle1">{title}</Typography>
                    </Button>
                </Tooltip>

                <Popover
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                    transformOrigin={{ vertical: 'top',horizontal: 'left', }}
                    id={"grouppopover"}
                    open={Boolean(groupPopoverElement)}
                    anchorEl={groupPopoverElement}
                    onClose={handleCloseGroupPopover}
                >
                    <GroupTreeView
                        proxy={proxy}
                        userNodeEnabled={false}
                        collapseDefault={false}
                        groupINFO={groupINFO}
                        userINFO={ userINFO }
                        maxDepth={5}
                        handleNodeClick={onTreeItemClick}
                        selected={currGROUP}
                        isLimitPermit={true}
                        permitGROUP={permissionINFO}
                        refreshINFO={refreshINFO}
                        defaultExpandGroup={defaultExpandGroup} //array of mygroup and my group's parent
                        handleNodeToggle={handleNodeToggle}
                    />
                </Popover>
                {
                    (showObjTitle)?
                    <Typography style={{ marginLeft:4, marginRight:16 }} variant="subtitle1">{`>${author?.name}>${currOBJECT?.title}`}</Typography>
                        : null
                }
                {
                    (showViewModeToggle)?
                    <Tooltip title={<Typography variant="h6">Toggle view mode</Typography>}placement="bottom">
                        <FormControlLabel
                            style={{width:130, margin:5}}
                            control={<Switch checked={previewMode} onChange={()=>setPreviewMode(!previewMode)} />}
                            label={(previewMode)? "Preview":"List"}
                        />
                    </Tooltip>
                    :null
                }
                {                
                    (showArchiveToggle)?
                    <Tooltip title={<Typography variant="h6">Toggle Show Archives</Typography>} placement="bottom">
                        
                        <FormControlLabel
                            style={{width:130, margin:5}}
                            control={<Switch checked={archiveFlag} onChange={ updateArchiveFlag} />}
                            label={"Archive"}
                        />

                    </Tooltip>
                    :null
                }            
                <FormControl >
                    <Input
                        style = {{color:"white"}}
                        onChange={(e)=>handleSearchFilterChange(e.target.value)}
                        placeholder="Search…"                            
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchFilterCopy}
                        readOnly = {searchDisable}
                        endAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle search enable"
                                    onClick={(e)=>handleClickSearchRefresh()}
                                    onMouseDown={(e)=>e.preventDefault()}
                                >
                                    <AutorenewIcon  color={(props.searchFilter === searchFilterCopy)? "primary":"secondary"}/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {
                    (showPagination)?
                    <TablePagination
                        style={{color:"white"}}
                        component="div"
                        labelRowsPerPage=""
                        rowsPerPageOptions={[]}
                        count={maxItemCount}
                        page={currPAGE}
                        onChangePage={(e, newPage)=>setCurrPAGE(newPage)}
                        rowsPerPage={currROWPERPAGE}                    
                    />
                    :null
                }
                <Button
                    ref={anchorUserMenuRef}
                    style={{position:"absolute", right:50, backgroundColor:"gray", color:"white"}}
                    aria-controls={userMenuOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleUserMenuToggle}
                    endIcon={<AccountCircleIcon></AccountCircleIcon>}
                >
                    {myUserINFO?.name}
                </Button>
                <Popper open={userMenuOpen} anchorEl={anchorUserMenuRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper style={{zIndex:100}}>
                                <ClickAwayListener onClickAway={handleCloseUserMenu}>
                                    <MenuList autoFocusItem={userMenuOpen} id="menu-list-grow">
                                        <MenuItem component={Link} to= "/folder">
                                            Documents Home
                                        </MenuItem>
                                        <MenuItem component={Link} to= "/myprofile">
                                            My Profile
                                        </MenuItem>
                                        <MenuItem component={Link} to= "/mydocuments">
                                            <ListItemText primary="My Documents" secondary="(문서업로드)" />
                                        </MenuItem>
                                        <MenuItem onClick={()=>handleSignOut()}>Logout</MenuItem>
                                        {
                                            ( myUserINFO?.user_level === "admin") ?
                                            (
                                                <div>
                                                    <Divider />
                                                    <MenuItem component={Link} to= "/groupadmin">
                                                        Admin Group
                                                    </MenuItem>
                                                    <MenuItem component={Link} to= "/userinfoadmin">
                                                        Admin User
                                                    </MenuItem>
                                                    {/*<MenuItem onClick={()=>onMenuClick(gotoPermissionAdminRef)}>Admin Permission</MenuItem>*/}
                                                    {/*<MenuItem onClick={()=>onMenuClick(gotoDocumentsAdminRef)}>Admin Documents</MenuItem>*/}
                                                </div>
                                            )
                                            :null
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Toolbar>
        </AppBar>
    )
}

