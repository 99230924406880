import { EResult } from "../common"
/**
 * proxy api code to call backend api 
 * @param param[GetUserInfoParamType]
 * @param callback
 */
export function getMySessionData(proxy, callback)
{
    proxy.postFetch("/timsapi/getmysessiondata", {})
    .then(async (response)=>{
        if(response.ok)
        {
            const body = await response.text()
            let resData = JSON.parse(body)        
            callback(resData)
        }
        else{
            callback({result:EResult.RESULT_ERROR, reason:"api fetch failed - getmysessiondata", data:null});
        }
    });
}

