import React, { useState, useEffect, SyntheticEvent} from 'react';
import './App.css';
import {Proxy} from './api/proxy'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, Theme } from '@material-ui/core/styles';
import {
        IconButton,
        Toolbar,
        Button,
        Popover,
        Tooltip,
        FormControlLabel,
        Checkbox, TablePagination, 
        TextField,
        Table,
        TableBody,
        TableRow,
        TableCell,
        TableHead,
        Dialog,
        DialogTitle,
        DialogContent,
        DialogActions, 
        lighten,
        TableSortLabel,
        TableContainer,
        Typography,
        FormGroup,
        Switch,
    } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import GroupTreeView from './GroupTree2';
import { GroupTreeView as GroupTreeViewOld } from './GroupTree';
import { getUserInfo } from './api/proxyGetUserInfo';
import { modifyUserInfo } from "./api/proxyModifyUserInfo"
import { addNewUser } from "./api/proxyAddNewUser"

import { modifyPermission as proxyModifyPermission } from "./api/proxyModifyPermission"
import { removePermission as proxyRemovePermission } from "./api/proxyRemovePermission"
import { addPermission as proxyAddPermission } from "./api/proxyAddPermission"

import PermissionGroupTree from "./PermissionGroupTree"


import { getUserPermissions } from './api/proxyGetUserPermissions'

const messages = {
    required: '필수 입력 정보입니다.',
    length: '패스워드는 5글자 이상 입력하십시오.',
    unmatched: '입력한 패스워드와 일치하지 않습니다.',
    unchanged: '현재 사용 중인 패스워드와 다른 패스워드를 입력하십시오.',
    includeId: '패스워드에는 사용자 아이디가 포함될 수 없습니다.',
}

const drawerWidth = 200

const useStyles = makeStyles((theme) => ({
    root: {
        //display: 'flex',
        width:'flex', 
        backgroundColor:"white",
    },
    appBar: {
        backgroundColor: "grey",
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },    
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        overflow:"auto",
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        marginTop: 0,
    },    
    contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        marginTop: 0,
    },
    docCardContainer:{
        display:"flex",
        flexDirection: "row",
        backgroundColor: "white",
        margin:0,
        padding:0,
        //width:`calc(100vw - ${250}px)`,
        //height:`calc(100vh - ${180}px)`,
        overflow:"scroll",
        //scrollbarWidth: "none"
    },
    profileToolbar:{
        //marginLeft: theme.spacing(2),
        spacing: theme.spacing(3),
    },
    userEditDialog:{
        display:"block",
        height:"flex",
        //rightMargin:"30px",
        overflow:"auto",
        width:"800px",
        maxHeight:"500px"
    },
    toolBarRoot:{
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    toolBarTitle:{
        flex: '1 1 100%',
    }
}));

/*
type UserTableHeadCellType={
    id:string
    numeric:boolean
    disablePadding:boolean
    label:string
}
*/
const userTableHeadCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'login_id', numeric: false, disablePadding: false, label: 'Login Id' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'disabled', numeric: false, disablePadding: false, label: 'Disabled' },
    { id: 'group', numeric: false, disablePadding: false, label: 'Group' },
  ];

function stableSort(array, comparator){
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] > b[1] ? 1 : -1;
    });
    return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

/*
type UserInfoEditorViewProp={    
    userData:IUserData[]
    groupTreeData:IGroupDataEx[]
    marginTop:number
    marginLeft:number
    drawerOpen:boolean
    updateUserData:()=>void
    handleAppbarOpen:()=>void
    setUserInfo: (newUser:boolean, dataToSave:IUserData, callback:(result:string)=>void)=>void
    handleUpdateData:(category:string)=>void
    alertMessage:(msg:string, variant:"default"|"error"|"success"|"warning"|"info")=>void
    permitGroup:string[]
    uiUpdateCounter:number
}
*/

//FIXME: GIVE USERS TO HAVE A CHANCE TO SEE THE RESULT. 
const handleSetUserInfo = (proxy, newUser, userInfoToSave, callback)=>{
    if(newUser){
        addNewUser(proxy, userInfoToSave, (res)=>{
            callback(res)
        })
    }else{
        modifyUserInfo(proxy, userInfoToSave, (res)=>{
            callback(res)
        })
    }
}

const allGroupDescendants = (top, tree, descendents)=>{
    // const descendants = []
    const children = []
    tree.forEach(node=> {
        if (node.parent === top.id) {
            children.push(node)
            descendents.push(node.id)
        }
    })

    children.forEach(child=>{
        allGroupDescendants(child, tree, descendents)
    })
}

const updateMyPermission=(uid, gid, usersInfo, groupDATA, allPermission, givenPermission)=>{
    if(uid && groupDATA && allPermission){
        const myGroup = groupDATA.find(g=>g.id === gid)
        const myUserData = usersInfo?.find(u=>u.id === uid)
        let isAdmin = false
        if(myUserData)
            isAdmin = (myUserData.user_level === "admin")
        
        const descendantsR = [] // read permission given by group position
        const descendantsW = [] // write permission given by group position
        const readGroups= []
        const writeGroups = []
        if(givenPermission)
        {
            ///////////////////////////////////////////////////
            ///////////////////////////////////
            // giving read permission
            if(isAdmin){
                groupDATA.forEach(g=> readGroups.push(g.id))
            }
            else if(myGroup){
                // permission by group position
                readGroups.push(myGroup.id) // my group itself
                if(myGroup.parent) readGroups.push(myGroup.parent)  // my just parent group
                
                allGroupDescendants(myGroup, groupDATA, descendantsR)  // and all descendants
                descendantsR.forEach(d => readGroups.push(d))
            }
            //////////////////////////////////
            // giving write permission
            if(isAdmin){
                groupDATA.forEach(g=> writeGroups.push(g.id))
            }
            else if(myGroup){
                writeGroups.push(myGroup.id) // my group itself
                descendantsW.push(myGroup.id)
                if(myGroup.parent){
                    writeGroups.push(myGroup.parent)  // my just parent group
                    descendantsW.push(myGroup.parent)
                }
            }
            /////////////////////////////////////////////////
        }
        

        //////////////////////////////////
        // giving specified permission
        // to the dedicated group-user by permission table
        if(allPermission){
            allPermission.forEach(p=>{
                if(p.uid === uid){
                    const theGroup = groupDATA.find(g=> g.fid === p.fid)
                    if(theGroup){
                        if(p.permission === "read") readGroups.push(theGroup.id)
                        if(p.permission === "write") writeGroups.push(theGroup.id)
                        if(p.permission === "readWrite") {
                            readGroups.push(theGroup.id)
                            writeGroups.push(theGroup.id)
                        }
                    }
                }
            })
        }
        /////////////////////////////////////
        return {readGroups, writeGroups, readDesc:descendantsR, writeDesc:descendantsW}
    }
}

export function UserInfoAdmin(props){
    const {
        proxy,
        userData,
        groupTreeData,
        marginTop,
        marginLeft,
        drawerOpen,
        refreshINFO,
    } = props

    const [orderBy, setOrderBy] = useState("name")
    const [order, setOrder] = useState("asc")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [userEditDialogOpen, setUserEditDialogOpen] = useState(false) 
    const [userDeleteConfirmDialogOpen, setUserDeleteConfirmDialogOpen] = useState(false)
    const [dialogName, setDialogName] = useState("")
    const [dialogParam, setDialogParam] = useState("")
    const [isNewUser, setIsNewUser] = useState(false)

    const [currentSelected, setCurrentSelected] = useState(undefined)

    const [readPermitGroups, setReadPermitGroups] = useState([])
    const [writePermitGroups, setWritePermitGroups] = useState([])
    const [readDesc, setReadDesc] = useState([])
    const [writeDesc, setWriteDesc] = useState([])

    const [permissionUpdateFlag, setPermissionUpdateFlag] = useState(0)
    
    const [selectPermission, setSelectPermission] = useState([]) // current selected user's all permission 
    const [allPermission, setAllPermission] = useState(undefined)
    const [onProgressOpen, setOnProgressOpen] = useState(false)
    const [updateCount, setUpdateCount] = useState(0)
    const [failEditUser, setFailEditUser] = useState(false);
    const [passFieldError, setPassFieldError] = useState(undefined)

    useEffect(()=>{
        // setup permission data ...
        if(currentSelected && userData && groupTreeData && allPermission){
            const permitGroups = updateMyPermission(currentSelected.id, currentSelected.gid, userData, groupTreeData, allPermission, false)
            setReadPermitGroups(permitGroups.readGroups)
            setWritePermitGroups(permitGroups.writeGroups)
            setReadDesc(permitGroups.readDesc)
            setWriteDesc(permitGroups.writeDesc)

            const selectedPermission = []
            allPermission.forEach(p=> {
                if(p.uid === currentSelected.id)
                    selectedPermission.push(p)
            })
            setSelectPermission(selectPermission)
            setUpdateCount(updateCount+1)
        }
    }, [currentSelected, allPermission])

    useEffect(()=>{
        /////////////////////////////////////////
        // get all permission information
        getUserPermissions(proxy, null, (res)=>{
            setAllPermission(res.data)
        })
        ////////////////////////////////////////
    },[permissionUpdateFlag])
    
    const refreshPermissionINFO = ()=>{ setPermissionUpdateFlag(permissionUpdateFlag + 1) }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }
    const handleRowClick = (event, user) =>{ setCurrentSelected(user) }
    const handleCloseUserEditDialog= ()=>setUserEditDialogOpen(false)
    const handleUserEdit = (uid) =>{
        const userInfo = userData.find(u=>u.id === uid)
        if(!userInfo){
            // what supposed to do?
            return
        }
        // do confirm dialog..        
        setIsNewUser(false)
        setDialogParam(uid)
        setDialogName("user edit")
        setUserEditDialogOpen(true)
    }
    const handleUserDelete= (uid)=>{
        const userInfo = userData.find(u=>u.id === uid)
        if(!userInfo){
            // what supposed to do?
            return
        }
        // do confirm dialog..        
        setDialogParam(uid)
        setDialogName("user delete confirm")
        setUserDeleteConfirmDialogOpen(true)
    }
    const handleChangePage= (newPage)=>setPage(newPage)
    const handleCloseUserDeleteConfirmDialog=()=> setUserDeleteConfirmDialogOpen(false)
    const saveAndCloseDialog= (isNew, userDataToSave)=>{
        //FIXME: GIVE USER MORE SPECIFIC INFORMATION OF RESULT
         handleSetUserInfo(proxy, isNew, userDataToSave, (res)=>{
            try {
                if(res.result == "OK") {
                    setUserEditDialogOpen(false)
                    refreshINFO(["user"]);
                    setFailEditUser(false);
                } else {
                    setUserEditDialogOpen(true)
                    setFailEditUser(true);
                    if (res.result === "WARN" && res.data.passFieldError) {
                        let {npw, cpw} = res.data.passFieldError;
                        setPassFieldError({
                            npw : npw?.length ? npw.map(n => messages[n]) : null,
                            cpw : cpw?.length ? cpw.map(c => messages[c]) : null
                        })
                    }
                }
            } catch (err){
                console.log(err)
            }
        })
    }

    const handleChangeRowsPerPage = (value) => {
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }

    const deleteUser= (uid) =>{
        // delete user account
    }

    const handleAddNewUser = ()=>{
        setIsNewUser(true)
        setUserEditDialogOpen(true)
    }

    //EDIT PERMISSION
    const handlePermissionCheckClick = (targetGroup, permitType) => {
        if (!currentSelected) return
        //gid: groupID, uid: userID, fid : folderID
        const gid = targetGroup.id, uid = currentSelected.id, fid = targetGroup.fid;
        if (!gid || !uid || !fid) {
            return;
        }

        //FIXME: PERMISSION CHECK SHOULD BE IMPLEMENTED ON SEVER-SIDE
        const currentPermission = {
            read: readPermitGroups.includes(gid),
            write: writePermitGroups.includes(gid)
        };

        // toggle checked permission
        currentPermission[permitType] = !currentPermission[permitType];

        const { read, write } = currentPermission;
        let modifiedPermission;
        if (read && write) {
            modifiedPermission = "readWrite";
        } else if (read) {
            modifiedPermission = "read";
        } else if (write) {
            modifiedPermission = "write";
        } else {
            modifiedPermission = null;
        }

        modifiedPermission ?
            proxyModifyPermission(proxy, { fid, uid, permission: modifiedPermission }, (result) => {
                refreshINFO(["permission"])
                setPermissionUpdateFlag(permissionUpdateFlag + 1)
            }) :
            proxyRemovePermission(proxy, { fid, uid }, (result) => {
                refreshINFO(["permission"])
                setPermissionUpdateFlag(permissionUpdateFlag + 1)
            })
        return;
    }

    const theme = useTheme();
    const classes = useStyles(props);    
    return (
        <div className={classes.root} >
            <Typography variant="h6" style={{display:"none"}}>{updateCount}</Typography>
            {
                <UserInfoDialog
                    proxy={proxy}
                    open={userEditDialogOpen}
                    userData={userData}
                    uid={dialogParam}
                    newUser={isNewUser}
                    groupTreeData={groupTreeData}
                    onCloseUserDlg={handleCloseUserEditDialog}
                    onSaveUserInfo={saveAndCloseDialog}
                    permitGroup={groupTreeData.map(g=>g.id)}
                    failEditUser={failEditUser}
                    passFieldError={passFieldError}
                />
            }
            {
                <UserDeleteConfirmContent 
                    open={userDeleteConfirmDialogOpen}
                    uid={dialogParam}
                    userData={userData}
                    deleteUser={deleteUser}
                    closeDialog={handleCloseUserDeleteConfirmDialog}
                />
            }
            <div 
                style={{
                        marginTop:marginTop, 
                        width:`calc(100vw - ${(drawerOpen)?(marginLeft+16) : 16}px)`, 
                        height:`calc(100vh - ${marginTop+16}px)`
                    }}
                className={
                    clsx(classes.content, {[classes.contentShift]:drawerOpen})
                }
            >                
                <Grid 
                    container
                    spacing={2}
                >
                    <Grid item xs={8}>
                        <Grid container>
                            <Grid item xs={6}>                        
                                <Button variant="contained" onClick={handleAddNewUser} style={{marginLeft:"20px", transform:"translateY(-50%)", top:"50%"}}>
                                    <AddIcon></AddIcon>
                                    New User
                                </Button>
                            </Grid>                        
                            <Grid item xs={6}>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 30]}
                                component="div"
                                count={userData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={(e, value)=>handleChangePage(value)}
                                onChangeRowsPerPage={(e)=>handleChangeRowsPerPage(e.target.value)}>
                            </TablePagination>
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead 
                                    classes
                                    headCells={userTableHeadCells}
                                    orderBy={orderBy}
                                    order={order}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                {stableSort(userData, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = false;
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        const group = groupTreeData.find(g=> g.id === row.gid)
                                        return(
                                            <TableRow
                                                hover
                                                onClick={(event)=> handleRowClick(event, row)}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                                style={{
                                                    backgroundColor:(row.id === currentSelected?.id)? "green":"white",
                                                    foregroundColor:(row.id === currentSelected?.id)? "white":"black",
                                                }}
                                                selected = {isItemSelected}
                                            >
                                                <TableCell component="th" id={labelId} scope="row" padding="default" style={{paddingTop:3, paddingBottom:3}}>
                                                    <IconButton size="small" color="default" style={{marginRight:5}} aria-label="add"
                                                        onClick={()=>handleUserDelete(row.id)}>
                                                        <Tooltip 
                                                            title={<Typography variant="h6">Delete "{row.name}" User Account</Typography>}>
                                                            <DeleteIcon fontSize="small"></DeleteIcon>
                                                        </Tooltip>                                                
                                                    </IconButton>
                                                    <IconButton size="small" color="default" style={{marginLeft:5}} aria-label="add"
                                                        onClick={()=>handleUserEdit(row.id)}>
                                                        <Tooltip 
                                                            title={<Typography variant="h6">Edit "{row.name}" User Profile</Typography>}>
                                                            <EditIcon fontSize="small"></EditIcon>
                                                        </Tooltip>
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="default" style={{paddingTop:3, paddingBottom:3}}>
                                                    {row.name}
                                                </TableCell>                                
                                                <TableCell component="th" id={labelId} scope="row" padding="default" style={{paddingTop:3, paddingBottom:3}}>
                                                    {row.login_id}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="default" style={{paddingTop:3, paddingBottom:3}}>
                                                    {row.email}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="default" style={{paddingTop:3, paddingBottom:3}}>
                                                    {row.disabled? "disabled":"enabled"}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="default" style={{paddingTop:3, paddingBottom:3}}>
                                                    {group?.title}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                </TableBody>
                            </Table>                            
                        </TableContainer>
                    </Grid>
                    <Grid item xs={4}>
                        <div key="permissionGroup-container">
                            <Typography variant="h5">
                             📚{(currentSelected) ? currentSelected.name+"'s" : ""} Permission List
                            </Typography>
                            <PermissionGroupTree
                                groupINFO={groupTreeData}
                                userINFO={userData}
                                readPermitGroups={readPermitGroups}
                                writePermitGroups={writePermitGroups}
                                readDesc={readDesc}
                                writeDesc={writeDesc}
                                handleCheckClick={handlePermissionCheckClick}
                                currentUser={currentSelected}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}


/*
type EnhancedTableHeadProp={
    classes:any
    headCells:UserTableHeadCellType[]
    orderBy:string
    order:"asc"|"desc"
    onRequestSort: (e:SyntheticEvent, property:string)=>void
}
*/
export function EnhancedTableHead(props){
    const { classes, order, orderBy, headCells, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return(
        <TableHead style={{marginTop:"30px"}}>
            <TableRow >
                <TableCell padding={"default"}>
                    Todo
                </TableCell>
                {headCells.map((headCell)=>(
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={headCell.disablePadding ? "none":"default"}
                        sortDirection={orderBy===headCell.id? order:false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order: "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

/*
type UserDeleteConfirmContentProp = {
    open:boolean
    uid:string
    userData:IUserData[]
    deleteUser:(uid:string)=>void
    closeDialog:()=>void
}
*/
function UserDeleteConfirmContent(props)
{
    const {open, uid, deleteUser, userData, closeDialog} = props
    const userToDelete = userData.find(u=>u.id === uid)
    if(userToDelete)
    {
        return(
            <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="alert-dialog-title">
                    {`Delete ${userToDelete.name+"("+userToDelete.login_id+")"} User Account?`}
                </DialogTitle>
                <DialogActions>
                <Button onClick={()=>closeDialog()} color="primary">
                    Cancel
                </Button>
                <Button onClick={()=>deleteUser(uid)} color="primary" autoFocus>
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
        )
    }
    else{
        return(
            <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="alert-dialog-title">
                    {`Invalid user information`}</DialogTitle>
                <DialogActions>
                    <Button onClick={()=>closeDialog()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
} 

/*
type UserInfoDialogProp = {
    open:boolean
    userData:IUserData[]
    groupTreeData:IGroupData[]
    uid:string
    newUser:boolean
    onCloseUserDlg:()=>void
    onSaveUserInfo:(isNew:boolean, data:IUserData)=>void
    permitGroup:string[]
}*/
const userInfoDialogStyle = makeStyles(theme => ({
    textField: {      
    },
})
);
function UserInfoDialog(props){    
    // const [editUserValue, setEditUserValue] = React.useState<IUserData|null>(null);// props.userinfo;
    const {
        open,
        userData,
        groupTreeData,
        uid,
        newUser,
        onCloseUserDlg,
        onSaveUserInfo,
        permitGroup,
        proxy,
        failEditUser,
    } = props

    const [newUserIDVerify, setNewUserIdVerify] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const [userId, setUserId] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [userEmail, setUserEmail] = React.useState('');
    const [userLevel, setUserLevel] = React.useState("normal");
    const [disabled, setDisabled] = React.useState(false);
    const [currUserInfo, setCurrUserInfo] = useState(undefined)
    const [groupPopoverElement, setGroupPopoverElement] = useState(null)
    const [gid, setGID] = useState("")
    const [currGroup, setCurrGroup] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [passwordConfirm, setPasswordConfirm] = useState(undefined)
    const classes = userInfoDialogStyle();
    useEffect(()=>{
        setIsNew(newUser)
        if(newUser){
            setUserId("")
            setUserName("");
            setPassword("")
            setPasswordConfirm("")
            setUserEmail("");
            setGID("");
            setUserLevel("normal");
            setDisabled(false);
            setNewUserIdVerify(false);
            setCurrUserInfo(undefined)
        }
    },[newUser])
    useEffect(()=>{
        //const ui:IUserData|undefined = props.userData.find(u=>u.id === props.uid)        
        new Promise((resolve, reject)=>{
            getUserInfo(proxy, {uid:uid, loginid:null}, (res)=>{
                if(res.result === "OK"){
                    resolve(res.data)
                }else{
                    reject("Error: failed to fetch user data")
                }
            })
        })
        .then((ui)=>{
            if(ui && !newUser){
                setUserId(ui.login_id)
                setUserName(ui.name);
                //[2021-04-02] LET PASSWORD-INFORMATION STAY IN SERVER
                setPassword('');
                setPasswordConfirm('');
                setUserEmail(ui.email);
                setGID(ui.gid);
                setUserLevel(ui.user_level);
                setDisabled(ui.disabled);
                setNewUserIdVerify(false);
                setIsNew(newUser);
                setCurrUserInfo(ui)

                const group = groupTreeData.find(g=> g.id === ui.gid)
                setCurrGroup(group)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    },[uid, userData, groupTreeData])

    const handleChangeName = (name)=>setUserName(name)
    const handleChangeEmail = (email)=> setUserEmail(email)
    const handleChangeId = (id)=>setUserId(id)
    const handleChangeIsAdmin = (isAdmin)=>setUserLevel(isAdmin? "admin":"normal")
    const handleChangeDisabled = (disabled)=>setDisabled(disabled)

    // save edited information and close dialog
    const handleSaveAndClose = ()=>
    {        
        onSaveUserInfo( isNew, 
                            {  id : uid,
                                login_id: userId,
                                password:(password)? password :"",
                                fid:(currUserInfo)?currUserInfo.fid:"",
                                gid:gid,
                                name: userName,
                                email:userEmail,
                                user_level:userLevel,
                                disabled});
       
        setCurrUserInfo(undefined);
    }

    
    const handleClose = ()=>
    {
        onCloseUserDlg();
        setCurrUserInfo(undefined);
    }
    const handleVerifyUserId = (newid)=>
    {
        // verify userid
        userData.forEach(u => {
            if(u.login_id === newid){
                return false
            }
        })
        return true
    }
    const handleCloseGroupPopover = ()=>{
        setGroupPopoverElement(null)
    }
    const handleGroupTreeOpen = (event)=>{
        setGroupPopoverElement(event.currentTarget)
    }
    const handleGroupSelect = (uid, gid)=>{
        const group = groupTreeData.find(g=> g.id === gid)
        setCurrGroup(group)
        setGID(gid)
        handleCloseGroupPopover()
    }
    const handleRemoveReadOnly = e => e.target.removeAttribute('readonly')

    const isUserInfoChanged = (currUserInfo)? (
                                (currUserInfo.gid !== gid)||
                                (currUserInfo.login_id !== userId) ||
                                (currUserInfo.password !== password) ||
                                (currUserInfo.name !== userName) || 
                                (currUserInfo.email !== userEmail) || 
                                (currUserInfo.user_level !== userLevel) || 
                                (currUserInfo.disabled !== disabled)) : false;

    const isNecessaryFullfilled = (userId && userId.length > 0) && (gid) && (password) && (userName) && (userEmail);
    return(
        <div>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{(isNew)? "New": "Edit"} User Information</DialogTitle>
            <DialogContent>
                {/* FIXME: GIVE USER MORE SPECIFIC INFORMATION OF THE RESULT */}
                {!failEditUser ? 
                    null :
                    <Typography variant="subtitle2" color="error" style={{marginBottom:10}}>
                        {`사용자 ${newUser? '신규 생성' : '수정'}에 실패했습니다.`}
                    </Typography>
                }
                <TextField
                    autoFocus
                    id="id"
                    label="Login Id"
                    placeholder="ID"
                    type="text"
                    fullWidth
                    variant="outlined"
                    disabled = {!isNew}
                    value = {userId}
                    onChange={(e)=>handleChangeId(e.target.value)}
                />
                <Grid style = {{marginTop:10}} container alignItems="center" >
                    <Grid item xs={6}>
                        <TextField
                            id="password"
                            label="Password"
                            placeholder="password"
                            fullWidth
                            variant="outlined"
                            value = {(password)? password : ""}
                            type="password"
                            onChange={(e)=>setPassword(e.target.value)}
                            error={Boolean(props.passFieldError?.npw)}
                            helperText={props.passFieldError?.npw ?? null}
                            inputProps={{ readOnly: true}}
                            onFocus={handleRemoveReadOnly}
                        />
                    </Grid>                    
                    <Grid item xs={6}>
                        <TextField
                            id="passwordconfirm"
                            label="Password Confirm"
                            color={(password === passwordConfirm)? "primary": "secondary"}
                            placeholder="password confirm"
                            fullWidth
                            variant="filled"
                            type="password"
                            value = {passwordConfirm? passwordConfirm: ""}
                            onChange={(e)=>setPasswordConfirm(e.target.value)}
                            error={Boolean(props.passFieldError?.npw)}
                            helperText={props.passFieldError?.npw ?? null}
                            inputProps={{ readOnly: true }}
                            onFocus={handleRemoveReadOnly}
                        />
                    </Grid>
                </Grid>
                <TextField
                    id="name"
                    name="name"
                    label="User Name"
                    placeholder="User Name"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    value = {userName}
                    onChange={(e)=>handleChangeName(e.target.value)}
                />
                <TextField
                    id="group"
                    name="group"
                    label="Group (click to change)"
                    placeholder="Group (click to change)"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    value = {currGroup?.name+"("+currGroup?.title+")"}
                    onClick= {handleGroupTreeOpen}
                />
                <TextField
                    id="email"
                    name="email"
                    label="User Email"
                    placeholder="User Email"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    type="email"
                    value = {userEmail}
                    onChange={(e)=>handleChangeEmail(e.target.value)}
                />
            <Popover
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                transformOrigin={{ vertical: 'top',horizontal: 'left', }}
                id={"grouppopover"}
                open={groupPopoverElement!==null}
                anchorEl={groupPopoverElement}
                onClose={handleCloseGroupPopover}
            >
                <GroupTreeView
                    userNodeEnabled={false}
                    collapseDefault={true}
                    groupINFO={groupTreeData}
                    userINFO={userData}
                    maxDepth={5}
                    handleNodeClick={handleGroupSelect}
                    selectedGroup={currGroup}
                    currObject={undefined}
                    dragAndDrop={false}
                    handleDragEvent={null}
                    permitGROUP={permitGroup}
                />
            </Popover>
                <FormControlLabel
                    control={
                        <Checkbox checked={userLevel==="admin"} 
                                  onChange={(e)=>handleChangeIsAdmin(e.target.checked)} 
                                  value={userLevel === "admin"}
                        />
                    }
                    label="Is admin user?"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={disabled} 
                            onChange={(e)=>handleChangeDisabled(e.target.checked)} 
                            value={disabled}
                        />
                    }
                    label="Disable user account"
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSaveAndClose} color="primary" 
                    disabled={(isNew)? !isNecessaryFullfilled || (!password && password !== passwordConfirm) 
                            : !isUserInfoChanged || (!password && password !== passwordConfirm)}>
                    Save
                </Button>
            </DialogActions>
          </Dialog>
        </div>
    );
}
