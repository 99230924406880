import { EResult } from "../common"
/**
 * proxy api code to call backend api 
 * @param userid string
 * @param pw string
 * @param callback
 */
export function signIn(proxy, param, callback)
{
    proxy.postFetch("/timsapi/signin", param)
    .then(async (response)=>{
        if(response.ok)
        {
            const body = await response.text()
            let resData = JSON.parse(body)        
            callback(resData)
        }
        else{
            callback({result:EResult.RESULT_ERROR, reason:"api fetch failed - signin", sess:null});
        }
    });
}

/*

////////////////////////////////////////////////////////////////////////////////////////
// test code
const useStyles = makeStyles({
});
export function Test_signIn() {
    const [showSignIn, SetShowSignIn] = useState<boolean>(false)
    const [loginId, SetLoginId] = useState<string>("")
    const [password, SetPassword] = useState<string>("")
    const [sessionid, SetSessionId] = useState<string>("")

    const onClickSignIn = ()=>{
        SetShowSignIn(true)
    }
    const onCancel=()=>{
        SetShowSignIn(false)
    }
    const onSignIn = () =>{
        // call sign in api        
        console.log("TESTsignIn")
        signIn(new Proxy("http://localhost:5000"), {loginid:loginId, password}, (result:SignInResult)=>{
            console.log("SignIn:", result.sess)
        })
    }
    const onClickSignOut = ()=>{
        if(sessionid){
            console.log("TESTsignOut")
        }
    }
    const onLoginIdChange = (id:string)=>{
        console.log("id change:",id)
        SetLoginId(id)
    }
    const onPasswordChange = (pwd:string)=>{
        console.log("pwd change:", pwd)
        SetPassword(pwd)
    }
    return (
        <div>
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                <Button onClick={onClickSignIn}>SignIn</Button>
                <Button onClick={onClickSignOut}>SignOut</Button>
            </ButtonGroup>
            <SignInDialog 
                    open={showSignIn} 
                    onSignIn={onSignIn}
                    onCancel={onCancel}
                    onLoginIdChange={onLoginIdChange}
                    onPasswordChange={onPasswordChange}
            />
        </div>
    )
}

type SignInDialogProp={
    open:boolean,
    onSignIn:()=>void,
    onCancel:()=>void,
    onLoginIdChange:(id:string)=>void,
    onPasswordChange:(pwd:string)=>void
}

function SignInDialog(props:SignInDialogProp){
    // const [id, SetId] = useState<string>("")
    // const [password, SetPassword] = useState<string>("")
    return(        
        <Dialog open={props.open} 
                // onClose={()=>this.setState({signinDialogOpen:false})}
                aria-labelledby='form-dialog-title'
                style={{transform:"scale(1.2)"}}>
            <DialogTitle id = 'signin-dialog-title' >
                <Typography >
                    Sign In
                </Typography>
            </DialogTitle>
            <DialogContent >
                <TextField id='userid' 
                            onChange={(e)=>props.onLoginIdChange(e.target.value)}
                            autoFocus
                            margin='dense'
                            label='User id'
                            type=''
                            fullWidth
                            InputLabelProps=
                            {{ shrink: true }} />
                <TextField  id='password'
                            onChange={(e)=>props.onPasswordChange(e.target.value)}
                            margin='dense'
                            label='Password'
                            type='password'
                            fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onSignIn} 
                    id='dialogSignIn'
                    color='primary' >
                        Sign In
                </Button>
                <Button onClick={props.onCancel} color='default'>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
*/