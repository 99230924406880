// import { IProxyServer} from "./proxy"
// import { APIResult, EResult} from "../common_front"
import { EResult } from "../common"
/**
 * proxy api code to call backend api 
 * @param param[GetUserInfoParamType]
 * @param callback
 */
export function getAllFolderInfo(proxy, callback)
{
    proxy.postFetch("/timsapi/getallfolderinfo", {offset:0,count:0,orderColumn:"",order:"ASC"})
    .then(async (response)=>{
        if(response.ok)
        {
            const body = await response.text()
            let resData = JSON.parse(body)
            callback(resData)
        }
        else {
            callback({result:EResult.RESULT_ERROR, reason:"api fetch failed - getallfolderinfo", data:null});
        }
    });
}
