import { EResult } from "../common"
/**
 * TIMS
 * get group tree information
 * @param proxy 
 * @param callback 
 */
export function getGroupTree(proxy, callback)
{
    proxy.postFetch("/timsapi/getgrouptree", {})
    .then(async (response)=>{
        if(response.ok)
        {
            const body = await response.text()
            let resData = JSON.parse(body)
            callback(resData)
        }
        else{
            callback({result:EResult.RESULT_ERROR, reason:"api fetch failed - getgrouptree", data:null});
        }
    });
}

/*
const useStyles = makeStyles({
});
export function Test_getGroupTree() {
    const classes = useStyles();
    const [treeInfo, setTreeInfo] = useState<GroupTreeInfo[]|null>(null)

    const TESTgetGroupTree = ()=>{
        console.log("TESTgetGroupTree")
        getGroupTree(new Proxy("http://localhost:5000"), (result:APIResult)=>{
            setTreeInfo(result.data)
        })
    }
    const GroupTree = (treeInfo:GroupTreeInfo[])=>{

    }
    return (
        <div>
            <Button variant="contained" onClick={TESTgetGroupTree}>getGroupTree</Button>
            {GroupTree}
        </div>
    )
}
*/